import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthErrorComponent } from './modules/auth-error/auth-error.component';


const routes: Routes = [
   { path: 'login/callback', pathMatch: 'full', redirectTo: initialRedirection() },
   { path: 'error', component: AuthErrorComponent },
    {
        path: 'projects',
        loadChildren: () => import('src/app/modules/projects/projects.module').then(m =>
            m.ProjectModule)
    },
    {
      path: 'components',
      loadChildren: () => import('src/app/modules/components/components.module').then(m =>
          m.ComponentsModule)
    },
    {
      path: 'admin',
      loadChildren: () => import('src/app/modules/admin/admin.module').then(m =>
          m.AdminModule)
    },
];

export function initialRedirection() {
  console.log('inside redirection file');
  return '';
}

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
