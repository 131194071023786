import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {AppConfigService} from './../../app-config/app-config.service';

@Injectable({
    providedIn: 'root'
})
export class AdminService {
    constructor(private httpClient: HttpClient,
        private appConfigService: AppConfigService) { }

    public getExternalGroupID() {
        return this.httpClient.get(this.appConfigService.getConfig().EIAM_API_SERVER + this.appConfigService.getConfig().ADMIN_GET_GROUPS_API +  '"'+ this.appConfigService.getConfig().EXTERNAL_USER_KEY +'"');
    }

    public getInternalGroupID() {
        return this.httpClient.get(this.appConfigService.getConfig().EIAM_API_SERVER + this.appConfigService.getConfig().ADMIN_GET_GROUPS_API +  '"'+ this.appConfigService.getConfig().INTERNAL_USER_KEY +'"');
    }

    public getInactiveInternalGroupID() {
        return this.httpClient.get(this.appConfigService.getConfig().EIAM_API_SERVER + this.appConfigService.getConfig().ADMIN_GET_GROUPS_API +  '"'+ this.appConfigService.getConfig().INACTIVE_INTERNAL_USER_KEY +'"');
    }

    public getInactiveExternalGroupID() {
        return this.httpClient.get(this.appConfigService.getConfig().EIAM_API_SERVER + this.appConfigService.getConfig().ADMIN_GET_GROUPS_API +  '"'+ this.appConfigService.getConfig().INACTIVE_EXTERNAL_USER_KEY +'"');
    }

    public getLBMUserList(id) {
        return this.httpClient.get(this.appConfigService.getConfig().EIAM_API_SERVER + this.appConfigService.getConfig().ADMIN_LIST_USERS_API + id + "/users" );
    }

    public getUserGroup(id) {
        return this.httpClient.get(this.appConfigService.getConfig().EIAM_API_SERVER + this.appConfigService.getConfig().GET_USER_GROUP_API + id + "/groups" );
    }

    public createUser(user){
        return this.httpClient.post(this.appConfigService.getConfig().EIAM_API_SERVER + this.appConfigService.getConfig().CREATE_USER_API ,user)
    }

    public findUser(email){
        return this.httpClient.get(this.appConfigService.getConfig().EIAM_API_SERVER + this.appConfigService.getConfig().FIND_USER_API +email+'&limit=1')
    }

    public addAccessToUser(groupId,userId){
        return this.httpClient.put(this.appConfigService.getConfig().EIAM_API_SERVER + this.appConfigService.getConfig().ADD_USER_GROUP_API + groupId + '/users/' + userId,'')
    }

    public removeAccessFromUser(groupId,userId){
        return this.httpClient.delete(this.appConfigService.getConfig().EIAM_API_SERVER + this.appConfigService.getConfig().ADD_USER_GROUP_API + groupId + '/users/' + userId)
    }
}
