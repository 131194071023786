import { Pipe, PipeTransform } from '@angular/core';
import {formatTwoDigitString, formatMonthString} from './../helpers/helpers'

@Pipe({
  name: 'getinitials',
  pure : false
})
export class GetInitialPipe implements PipeTransform {

  transform(text): string {
    if(text) {
      let value = (fullname=>fullname.map((n, i)=>(i==0||i==fullname.length-1)&&n[0]).filter(n=>n).join(""))
      (text.split(" "));
      return value;
    } else {
      return '';
    }
   }
}