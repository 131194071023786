import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatNativeDateModule} from '@angular/material/core';
// import { ThemeLibModule, BhHeaderLinks, NavService, BhModalService} from 'bh-theme';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS  } from '@angular/common/http';
import { ApiInterceptor } from './../../shared/services/api-interceptor';
import {MatSidenavModule} from '@angular/material/sidenav';

import {ProjectsRoutingModule} from './projects-routing.module';
import { ProjectsComponent } from './../projects/projects.component';
import {ProjectsListComponent} from './list-project/projects-list.component'
import { UsersComponent } from './users/users.component';
import { UserListModalComponent } from './user-list-modal/user-list-modal.component';
import {CreateProjectComponent} from './create-project/create-project.component';
import { EditProjectComponent } from './edit-project/edit-project.component';
import {ProjectDetailsComponent} from './project-details/project-details.component';
import { SelectProjectsComponent } from './project-details/select-projects/select-projects.component';
import { FilterProjectComponent } from './filter-project/filter-project.component';
import { DeleteProjectComponent } from './delete-project/delete-project.component';
import { BlendTableComponent } from './project-details/blend-table/blend-table.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ReportComponent } from './project-details/report-table/report.component';
import { BinaryGraphComponent } from './project-details/report-table/binary-graph/binary-graph.component';
import { BinaryGraphModalComponent } from './project-details/binaryconfig-modal/binarygraph-modal.component';
import { DeleteBlendsComponent } from './project-details/delete-blends/delete-blends.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ProjectsRoutingModule,
    // ThemeLibModule,
    MatCardModule,
    MatTabsModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule
  ],
  declarations: [
    ProjectsComponent,
    ProjectsListComponent,
    UsersComponent,
    UserListModalComponent,
    CreateProjectComponent,
    EditProjectComponent,
    ProjectDetailsComponent,
    SelectProjectsComponent,
    FilterProjectComponent,
    DeleteProjectComponent,
    BlendTableComponent,
    ConfirmModalComponent,
    ReportComponent,
    BinaryGraphComponent,
    BinaryGraphModalComponent,
    DeleteBlendsComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor,multi: true},
  ],
  exports:[]
})
export class ProjectModule { }
