import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
//import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
//import { AuthConfigService } from 'src/app/modules/auth/auth-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
   // private authConfigService: AuthConfigService,
    private router: Router,
     ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // if (this.authConfigService.hasValidAccessToken()) {
    //   return true;
    // } else {
    //   this.router.navigate(['']).then(() => {
    //     window.location.reload();
    //   });
    //   return false;
    // }
    return true;
  }
}
