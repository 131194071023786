import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IllustrationData, IllustrationNames, IllustrationActionButtonTypes, BhAlertService } from 'bh-theme';
import { MatDialog } from "@angular/material/dialog";
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AuthConfigService } from '../../auth/auth-config.service';
import { CONSTANTMESSAGE } from 'src/app/shared/constants/constants-messages';
import { AdminService } from 'src/app/services/admin/admin.service';
import { ThrowStmt } from '@angular/compiler';
import { FilterAdminComponent } from '../filter-admin/filter-admin.component';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import {forkJoin} from "rxjs";
import {tap} from "rxjs/operators";
import { AddUserComponent } from './../add-user/add-user.component';
import { DeleteUserComponent } from '../delete-user/delete-user.component';
import {AppConfigService} from 'src/app/app-config/app-config.service';
import { UserService } from 'src/app/services/userservice/user.service';




@Component({
  selector: 'lbm-admin-list',
  templateUrl: './list-admin.component.html',
  styleUrls: ['./list-admin.component.scss'],
})
export class AdminListComponent implements OnInit, AfterViewInit {
  //dataSource = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataModel = [];
  dataDeleted = false;
  isSMERole = false;
  displayedColumns: string[] = ['name', 'org','email', 'internalUser',  'externalUser', 'SME', 'admin', 'activeInactive','final'];
  searchText = '';
  localComponents;
  emptyState: IllustrationData = {
    illustrationName: IllustrationNames.EmptyBox,
    title: 'No Users',
    desc: 'User List Empty'
  }
  dataSource = new MatTableDataSource<any>([]);
  externalUsers;
  internalUsers;
  inactiveInternalUsers;
  inactiveExternalUsers;
  inactiveSMEUsers;
  inactiveAdminUsers;
  activeSpinner: boolean;
  loadSpinner = true;
  userData:  any;
  roleArray = ['MyID_1278117_Dev_OIC Lifespan Blend_Admins', 'MyID_1278117_Dev_OIC Lifespan Blend_InternalUsers','MyID_1278117_Dev_OIC Lifespan Blend_Admins','MyID_1278117_Dev_OIC Lifespan Blend_SME','B2B_1278117_Dev_OIC Lifespan Blend_ExternalUsers'];
  externalAccessGroupId;
  externalRoleGroupId;
  internalAccessGroupId;
  internalRoleGroupId;
  SMERoleGroupId;
  adminRoleGroupId;
  groupsToBeAdded = [];
  inactivegroupsToBeAdded = [];
  inactiveExternalRoleGroupId;
  inactiveInternalRoleGroupId;
  inactiveSMERoleGroupId;
  inactiveAdminRoleGroupId;
  
  constructor(private dialog: MatDialog, private userService: UserService, private adminService: AdminService,
    private authConfigService: AuthConfigService, private _bhAlertService: BhAlertService, private spinnerService : SpinnerService,
    private appConfigService: AppConfigService ) {
      this.spinnerService.spinnerActive.subscribe(active => 
        this.toggleSpinner(active)); 
  }

  ngOnInit(): void {
    this.getLBMUsers();
    
  }

  ngAfterViewInit () : void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      return  data[sortHeaderId].toString().trim().toLocaleLowerCase();
    };
  }

  toggleSpinner(active){
    this.activeSpinner = active;    
  }
  
  getLBMUsers() {
    this.spinnerService.activate();
    this.userService.getLBMUsers().subscribe(res => {      
      this.dataModel = res['data'];
      this.showDataTable();
    });
  }

  searchTable(event) {
    this.dataSource.filter = event.target.value;
  }

  filter(event) {
    let dialogRef = this.dialog.open(FilterAdminComponent, {
      width: '300px',
      data: {
        targetX: event.target.getBoundingClientRect().x - 10,
        targetY: event.target.getBoundingClientRect().y + event.target.getBoundingClientRect().height
      },
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      console.log(res);
      console.log(this.dataSource.data);
      console.log(this.dataModel);
      if (res) {
        if (res['option'] == 'internal') {
          this.dataSource.data = this.dataModel.filter((elem) => elem.role === 'INTERNAL');
        }

        if (res['option'] == 'external') {
          this.dataSource.data = this.dataModel.filter((elem) => elem.role === 'EXTERNAL');
        }
        if (res['option'] == 'sme') {
          this.dataSource.data = this.dataModel.filter((elem) => elem.role === 'SME');
        }
        if (res['option'] == 'admin') {
          this.dataSource.data = this.dataModel.filter((elem) => elem.role === 'ADMIN');

        }
        if (res['option'] == 'active') {
          this.dataSource.data = this.dataModel.filter((elem) => elem.active);
        }
        if (res['option'] == 'inactive') {
          this.dataSource.data = this.dataModel.filter((elem) => !elem.active);
        }
        if (res['option'] == 'clear') {
          this.dataSource.data = this.dataModel;

        }
      }
    });
  }

  showDataTable () {
    this.dataSource.data = this.dataModel;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      return  data[sortHeaderId].toString().trim().toLocaleLowerCase();
    };
    this.spinnerService.deactivate();
  }

  addUser() {
     let dialogRef1 = this.dialog.open(AddUserComponent, {
       width: '300px',
       disableClose: true,
     });

     dialogRef1.afterClosed().subscribe((res) => {
       if(res !== null){
        this.spinnerService.activate();
        this.userService.createUser(res.value).subscribe((
          output: any[]) => {               
            this.showSuccessMsg(CONSTANTMESSAGE.ADD_USER);   
            this.getLBMUsers();  
          },
          error => {
          console.log("Error", error);          
          this.spinnerService.deactivate();
          this.showErrorMsg(CONSTANTMESSAGE.ADD_USER_FAIL);
          });        
         
       }else{
         console.log('cancel');
       }    

     });
  }
  showSuccessMsg(msg){
    this._bhAlertService.openSnackBar({
      type: "success",
      message: msg,
      subtitle: "",
      autodismiss: true,
      duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
    });
  }
  showErrorMsg(msg){
    this._bhAlertService.openSnackBar({
      type: "error",
      message: msg,
      subtitle: "",
      autodismiss: true,
      duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
    });
  }

  editDetails(event){
    console.log(event);
    let dialogRef1 = this.dialog.open(AddUserComponent, {
      width: '300px',
      data: event,
      disableClose: true,
      });  
     
    dialogRef1.afterClosed().subscribe((res) => {
      if(res !== null){
        this.spinnerService.activate();
        this.userService.updateUser(res.value.id, res.value.role, 'role').subscribe(res => {            
          this.showSuccessMsg(CONSTANTMESSAGE.EDIT_USER); 
          this.getLBMUsers();          
          
        },
        error => {
        console.log("Error", error);          
        this.spinnerService.deactivate();
        this.showErrorMsg(CONSTANTMESSAGE.EDIT_USER_FAIL);
        })
      }else{
        console.log('cancel');
      }    
 
    });
  }


  deleteUser(item){
    let dialogRef = this.dialog.open(DeleteUserComponent, {
      width: "530px",
      data: {
        delete: false
      
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
     
      this.spinnerService.activate();
      if (result['delete']) {
        this.userService.deleteUser(item.id).subscribe(res => 
          {
            this.showSuccessMsg(CONSTANTMESSAGE.DELETE_USER); 
            this.getLBMUsers();
          },
          
        error => {
        console.log("Error", error);          
        this.spinnerService.deactivate();
        this.showErrorMsg(CONSTANTMESSAGE.DELETE_USER_FAIL);
        });
        
      } else {
        this.spinnerService.deactivate();
      }
    });
  }


  showMsg(msg, type){
    this._bhAlertService.openSnackBar({
      type: type,
      message: msg,
      subtitle: "",
      autodismiss: true,
      duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
    });
  }

  activeInactive($event, element) {
    if ($event) {
      this.spinnerService.activate();
            
      if($event.checked){
        this.userService.updateUser(element.id, true, 'activity').subscribe(res => 
          {
            this.showSuccessMsg(CONSTANTMESSAGE.ACTIVE_USER_SUCCESS); 
            this.getLBMUsers();
          },error => {
            console.log("Error", error);          
            this.spinnerService.deactivate();
            this.showErrorMsg(CONSTANTMESSAGE.ACTIVE_USER_FAIL);
            });
      }else{
        this.userService.updateUser(element.id, false, 'activity').subscribe(res => 
          {
            this.showSuccessMsg(CONSTANTMESSAGE.INACTIVE_USER_SUCCESS); 
            this.getLBMUsers();
          },error => {
            console.log("Error", error);          
            this.spinnerService.deactivate();
            this.showErrorMsg(CONSTANTMESSAGE.INACTIVE_USER_FAIL);
            });
      }
    }
  }
  
}
