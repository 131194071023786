import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup, MatTab, MatTabHeader } from '@angular/material/tabs';
import { ActivatedRoute, ChildActivationStart, Router } from '@angular/router';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SelectProjectsComponent } from './select-projects/select-projects.component';
import { ProjectService } from "src/app/services/project/project.service";
import { IllustrationData, IllustrationNames, IllustrationActionButtonTypes, BhModalService, BhAlertService } from 'bh-theme';
import { BlendTableComponent } from './blend-table/blend-table.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { AppConfigService } from 'src/app/app-config/app-config.service';
import { AuthConfigService } from '../../auth/auth-config.service';
import {CONSTANTMESSAGE} from './../../../shared/constants/constants-messages';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { SharedModalComponent } from 'src/app/components/shared-modal/shared-modal.component';

@Component({
  selector: 'lbm-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],

})


export class ProjectDetailsComponent implements OnInit {
  projectId: number;
  project: any;
  nfitVal: string = null;
  lsciVal: string = null;
  fuelOilFactorMuliplier: string = null;
  today = new Date();
  getBlendData = [];
  blendsData = [];
  blendsHeaders = []
  areCalculationsOutOfDate: boolean = true;
  projectName: string = null;
  projectCustomerName: string = null;
  nfitError: boolean = false;
  fuelOilFactorError: boolean = false;
  isFuelOilCompPresent: boolean = false;
  isFuelOilCompModified: boolean = false;
  isBasevaluesSaved: boolean = false;
  isBasevaluesModified: boolean = false;
  baseValuesexisted: boolean = false;
  updatedDate;
  isLoggedUserasCreatedUser: boolean = true;
  loggedUserId;
  componentsChanged: boolean;
  

  errorMsg: string = '';
  successBlend: string = '';
  emptyState: IllustrationData = {
    illustrationName: IllustrationNames.EmptyBox,
    title: 'No Components Selected',
    desc: 'Select a minimum of 2 components and maximum of 30 components'
  }
  areBlendsDirty = false;
  selectedTabIndex = 0;

  @ViewChild('tabGroup', { static: true }) tabs: MatTabGroup;
  @ViewChild('blendsTable', { static: false }) blendsTable: BlendTableComponent;

  displayedColumns: string[] = ['tag','componentName', 'createdOn',  'dataType', 'ASI', 'asphaltenePercentage', 'componentType'];
  dataSource = new MatTableDataSource<any>([]);
  isSMERole = false;
  activeSpinner: boolean = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  baseConditionAction: boolean = false;
  fuelOilFactorAction: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router, public dialog: MatDialog,
    private bhModalService: BhModalService,
    private projectService: ProjectService,
    private appConfigService: AppConfigService,
    private authConfigService: AuthConfigService,
    private _bhAlertService: BhAlertService,
    private spinnerService : SpinnerService) {
      this.isSMERole  = this.authConfigService.checkIfSME();
      if (this.isSMERole) {
        this.displayedColumns = ['tag', 'componentName', 'createdOn', 'dataType', 'ASI', 'asphaltenePercentage', 'componentType','nFactor', 'nTemp', 'fuelOilLSCIFactor','notes', 'addedByUser'];
      }
      this.spinnerService.spinnerActive.subscribe(active => 
        this.toggleSpinner(active)); 
     }

  ngOnInit(): void {
    
    this.loggedUserId = sessionStorage.getItem('loggedUserId');
    this.projectId = Number(this.route.snapshot.paramMap.get('id'));
    this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    this.spinnerService.activate();
    this.getProjectDetails();
    this.getBlends();
    this.addTabClickHandler();
    if(this.blendsTable?.fromProjectCompSelection) {
      this.blendsTable.fromProjectCompSelection = false;
    }
  }

  toggleSpinner(active){
    this.activeSpinner = active
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  addTabClickHandler() {
    if (!this.tabs) {
      throw Error('ViewChild(MatTabGroup) tabs; is not defined.');
    }

    // 1. Save the click handler for later
    const handleTabClick = this.tabs._handleClick;

    // 2. Replace the click handler with our custom function
    this.tabs._handleClick = (tab: MatTab, header: MatTabHeader, index: number) => {

      if(!this.isBasevaluesSaved){
        this.isBasevaluesSaved = true;
        this.lsciVal = null;
        this.nfitVal = null;
        this.updatedDate = undefined;
      }
      if(index === 1 && this.blendsTable && this.blendsTable.componentHeaders && this.blendsTable.componentHeaders.length ==2){
        this._bhAlertService.openSnackBar({
          type: "success",
          message: CONSTANTMESSAGE.BINARY_GRAPH_AVAILABLE_MESSAGE,
          subtitle: "",
          autodismiss: true,
          duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
        });
      }

      // 3. Implement your conditional logic
      if (this.areBlendsDirty && index === 2) {
        const title = 'Leave Blends';
        const message = 'Any unsaved changes will be lost.';
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
          disableClose: true,
          data: {
            title: title,
            content: message,
            showSaveCancel: true
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result === 'agree'){
            this.saveBlends(tab, header, index);
          } else if(result === 'continueWithoutSave'){
            this.blendsTable.ngOnInit();
          }
          if(result !== 'close' && result !== 'agree') {
            this.areBlendsDirty = false;
            // 4. If the tab *should* be changed, call the 'old' click handler
            handleTabClick.apply(this.tabs, [tab, header, index]);
            this.selectedTabIndex = index;
          }
         
          
        });
      }
      else {
        // 4. If the tab *should* be changed, call the 'old' click handler        
        handleTabClick.apply(this.tabs, [tab, header, index]);
        this.selectedTabIndex = index;
      }
    };
  }

  canDeactivateBlends() {
    return new Promise<boolean>((resolve, reject) => {
      if (this.areBlendsDirty) {
        let title = 'Leave Blends';
        let message = 'Any unsaved changes will be lost.';
        if(this.baseConditionAction || this.fuelOilFactorAction){
          title = null;
          message = 'Any unsaved blends changes will be lost.';
        }
        
      
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
          data: {
            title: title,
            content: message,
            showSaveCancel: true
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result === 'agree'){
            this.saveBlends();
            this.areBlendsDirty = false;
            if(this.baseConditionAction ){  
              this.baseConditionAction = false;            
              this.applyBaseCondition();           
              
            }else if(this.fuelOilFactorAction){
              this.fuelOilFactorAction = false;
              this.applyFuelOilFactorMutiplierAction();
            }
            resolve(true);
          }  else if(result === 'continueWithoutSave'){
            this.blendsTable.ngOnInit();
            this.areBlendsDirty = false;
            if(this.baseConditionAction ){ 
              this.baseConditionAction = false;              
              this.applyBaseCondition();                           
            }else if(this.fuelOilFactorAction){
              this.fuelOilFactorAction = false;
              this.applyFuelOilFactorMutiplierAction();
            }
            resolve(true);
          }
          else if(result === 'close'){
            
            if(this.baseConditionAction){                         
              this.baseConditionAction = false;
              this.fuelOilFactorAction = false;
              
            }
            
          }
        });
      }
      else {
        resolve(true);
      }
    });
  }

  saveBlends(tab?, header?, index?) {
    this.blendsTable.saveBlends(undefined, this.tabs, tab, header, index);
  }

  getBlends(){
    this.spinnerService.activate();
    this.projectService.getBlendsForProject(this.projectId).subscribe((result: any) => {
      this.getBlendData = result; 
      this.spinnerService.deactivate();
    });
  }

  getProjectDetails() {
    this.projectService.getProjectById(this.projectId).subscribe((data: any) => {
      if (data) {
        if(data.createdBy === this.loggedUserId) {
          this.isLoggedUserasCreatedUser = true;
        } else {
          this.isLoggedUserasCreatedUser = false;
        }
        this.project = data;
        this.projectName = this.project.name;
        this.projectCustomerName = this.project.customer;
        console.log('project......', this.project)
        this.nfitVal = this.project.baseConditions.NFIT ? this.project.baseConditions.NFIT : null;
        this.lsciVal = this.project.baseConditions.LSCI ? this.project.baseConditions.LSCI : null;
        this.fuelOilFactorMuliplier = this.project.fuelOil_LSCI_Factor_Multiplier != null? this.project.fuelOil_LSCI_Factor_Multiplier : this.appConfigService.getConfig().FUELOIL_LSCI_FACTOR_MULTITPLIER;
        this.updatedDate = this.project.baseConditions.updatedDate ? new Date(this.project.baseConditions.updatedDate) : undefined;
        if(this.nfitVal !== null && this.lsciVal !== null) {
          this.baseValuesexisted = true;
          this.isBasevaluesSaved = true;
        } else {
          this.baseValuesexisted = false;
        }        
        this.projectService.getComponentsForProject(this.projectId).subscribe((componentData: any) => {
          if (componentData && componentData.components) {
            this.dataSource.data = componentData.components;
            this.dataSource.paginator = this.paginator;
            // this.dataSource.sort = this.sort;
            // this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
              if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
              }
              return data[sortHeaderId];
            };
            this.checkFuelOilComponent();
            this.spinnerService.deactivate();
          }
        });
      }
    });
  }

  openDialog() {
    //const componentIds = this.project?.componentIds || [];
    this.blendsTable.isOutOfCalcPopUpRequired = false;
    this.blendsTable.fromProjectCompSelection = true;
    const componentIds = this.dataSource && this.dataSource.data ? this.dataSource.data.map(rs => rs.id) : [];
    this.componentsChanged = false;
    const dialogRef = this.dialog.open(SelectProjectsComponent, {
      disableClose: true,
      width: "80vw",
      height: 'auto',      
      data: {
        projectId: this.projectId,
        selectedIds: componentIds,
        selectedComponentList: this.dataSource.data             
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result:`, result);
      this.blendsTable.isOutOfCalcPopUpRequired = true;
      this.blendsTable.fromProjectCompSelection = false;
      this.blendsTable.binaryCompChanged = true;
      this.blendsTable.enableNgOnChange = false;
      if (result) {
        this.projectService.getComponentsForProject(this.projectId).subscribe((componentData: any) => {
          if (componentData && componentData.components) {
            this.dataSource.data = componentData.components;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
              if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
              }
              return data[sortHeaderId];
            };
            this.spinnerService.deactivate();
          }
          this.checkFuelOilComponent();
        });
        this.componentsChanged = true;
        this._bhAlertService.openSnackBar({
          type: "success",
          message: CONSTANTMESSAGE.ADD_COMPONENT_PROJECT,
          subtitle: "",
          autodismiss: true,
          duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
        });
      }
    });
  }

  validateNFITNumber(e: any) {
    this.isBasevaluesSaved = false;
    this.isBasevaluesModified = true;
    var t = e.target.value;
    this.nfitVal = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : this.nfitVal;
  }
  checkVal(e){
    this.isBasevaluesSaved = false;
    // this.baseValuesexisted = false;
    if((e.target.value) < -5 || (e.target.value) > 5){
      this.nfitError = true;
      this.isBasevaluesModified = false
    } else {
      this.nfitError = false;
      this.isBasevaluesModified = true;
    }
  }

  validateISCINumber(e: any) {
    var t = e.target.value;
    this.lsciVal = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : this.lsciVal;
  }

  orgValueChange(){
    this.isBasevaluesSaved = false;
    this.isBasevaluesModified = true;
  }

  checkDisable(){
    // if both values are null when values before not inserted in db - disable
    // enable if both values null if previous inseted values in db
    // both values mandatory
    // enable when edit    
    if(this.baseValuesexisted){
      if(this.isBasevaluesModified){
        if(this.lsciVal && this.nfitVal){
          return false
        }
        if(this.lsciVal === this.nfitVal){
          return false;
        } else if((this.nfitVal === null && this.lsciVal != null) || (this.lsciVal === null && this.nfitVal != null)){
          return true;
        }
      } else {
        return true;
      }
    }else if(!this.baseValuesexisted) {
      if(this.nfitError || (this.lsciVal === null && this.nfitVal === null) ||
        (this.nfitVal === null && this.lsciVal != null) || (this.lsciVal === null && this.nfitVal != null)){
        return true;
      }
    }
  }
  apply(){
    this.blendsTable.isOutOfCalcPopUpRequired = false;
    if(this.areBlendsDirty){
      this.baseConditionAction = true;
      this.canDeactivateBlends();
    }else{
      this.applyBaseCondition();
    }

  }
  applyBaseCondition(){
    
    const upDate = this.updatedDate ? this.formatDate(this.updatedDate) : null;
    let patchArr = [
      { "op": "replace", "path": "/NFIT", "value": this.nfitVal ? this.nfitVal : '' },
      { "op": "replace", "path": "/LSCI", "value": this.lsciVal ? this.lsciVal : ''},      
      { "updatedDate": upDate }
    ]
    this.projectService.updateBaseComponent(this.projectId, patchArr).subscribe((result: any) => {
        this._bhAlertService.openSnackBar({
          type: "success",
          message: CONSTANTMESSAGE.BASE_CONDITION_APPLIED,
          subtitle: "",
          autodismiss: true,
          duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
        });
      this.getProjectDetails(); 
    });
    this.isBasevaluesSaved = true;
    this.isBasevaluesModified = false;
    
  }
  
  formatDate(date) {
    if(!date){ date = new Date()}
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [month, day, year].join('-');
  }

  validate(item) {
    if (item.success) {
      this._bhAlertService.openSnackBar({
        type: "success",
        message:  item.message,
        subtitle: "",
        autodismiss: true,
        duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
      });
    } else {
      this._bhAlertService.openSnackBar({
        type: "error",
        message:  item.message,
        subtitle: "",
        autodismiss: true,
        duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
      });
    }


  }

  blendsChanged(event) {
    this.areBlendsDirty = event.isDirty;
    this.blendsData = event.data;
    this.blendsHeaders= event.headers;
    this.areCalculationsOutOfDate = !event.areCalculationsOutOfDate
  }

  onBlendsSaved(){
    this.getBlends();
  }

  validateFuelOilFactor(e: any) {
    var t = e.target.value;
    this.fuelOilFactorMuliplier = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : this.fuelOilFactorMuliplier;
    
  }

  checkFactorVal(e){
    this.isFuelOilCompModified = false;
    
    if((e.target.value) < 0 || (e.target.value) > 2){
      this.fuelOilFactorError = true;
      this.isFuelOilCompModified = false
    } else {
      this.fuelOilFactorError = false;
      this.isFuelOilCompModified = true;
    }
  }

  checkFactorDisable(){    
    if(this.isFuelOilCompModified){
      if(this.fuelOilFactorMuliplier != null){        
        return false;
      }else{        
        return true;
      }
    }else{      
      return true;
    }

  }

  applyFuelOilFactorMutiplier(){
    this.blendsTable.isOutOfCalcPopUpRequired = false;
    if(this.areBlendsDirty){
      this.fuelOilFactorAction = true;
      this.canDeactivateBlends();
    }else{
      this.applyFuelOilFactorMutiplierAction();
    }
  }

  applyFuelOilFactorMutiplierAction(){

    const title = '';
    const message = 'Already configured FO LSCI Factor Multiplier will be changed. Do you want to continue?';
    const dialogRef = this.dialog.open(SharedModalComponent, {
      disableClose: true,
      data: {
        title: title,
        content: message,
        cancel: 'Cancel',
        save: 'Continue',
        showSaveCancel: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'agree') {
        let patchArr = [
      
          { "op": "replace", "path": "/fuelOil_LSCI_Factor_Multiplier", "value": this.fuelOilFactorMuliplier },
          
        ]
        this.projectService.updateBaseComponent(this.projectId, patchArr).subscribe((result: any) => {
            this._bhAlertService.openSnackBar({
              type: "success",
              message: CONSTANTMESSAGE.FUEL_OIL_FACTOR_SUCCESS,
              subtitle: "",
              autodismiss: true,
              duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
            });
          this.getProjectDetails(); 
        });    
        this.isFuelOilCompModified = false;
      }
    });
    
    
  }

  checkFuelOilComponent(){
  
  let componentTypes = this.dataSource && this.dataSource.data ? this.dataSource.data.map(rs => rs.componentType) : [];
  
  if(componentTypes.includes('Y')){
    this.isFuelOilCompPresent = true;
  }else{
    this.isFuelOilCompPresent = false;
  }
  
  
  }

}
