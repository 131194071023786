import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { ConfirmAwayGuard } from 'src/app/shared/guards/confirm-away.guard';

import {AdminComponent} from './admin.component';

const mainRoutes: Routes = [
  {
    path: "",
    component: AdminComponent,
    children: [
      { path: "", component: AdminComponent, canActivate : [AuthGuard]  }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(mainRoutes)],
  exports: [RouterModule],
})
export class ComponentsRoutingModule {}
