<div class="dialog-container">
    <div class="title">
        <h4 mat-dialog-title>{{title}}</h4>
    </div>

    <div *ngIf="initiateForm">
        <form [formGroup]="componentForm" novalidate>
            <div class="dialog-contents" mat-dialog-content>
            <div class="two-column-div">
                <div class="component-item">
                    <mat-form-field class="fullwidth" appearance="fill">
                        <mat-label>Component Name</mat-label>
                        <input matInput name="componentName" formControlName="componentName" required>
                    </mat-form-field>
                    <div *ngIf="getControl.componentName.touched && getControl.componentName.invalid" class="text-danger">
                        <div *ngIf="getControl.componentName.errors.required">Component Name is required.</div>
                        <div *ngIf="getControl.componentName.errors.emptySpace">Empty space is not allowed.</div>
                        <div *ngIf="getControl.componentName.errors?.maxlength">Component Name can be maximum 150 characters long</div>
                    </div>
                </div>
                <div class="component-item">
                    <mat-form-field class="fullwidth" appearance="fill">
                        <mat-label>Component Type</mat-label>
                        <mat-select formControlName="componentType" name="componentType" required>
                            <mat-option value="N">Crude Oil</mat-option>
                            <mat-option value="D">Diluent</mat-option>
                            <mat-option value="Y">Fuel Oil</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="getControl.componentType.touched && getControl.componentType.status === 'INVALID'"
                        class="text-danger">
                        <div *ngIf="getControl.componentType.errors.required">Component Type is required</div>
                    </div>
                </div>
            </div>

            <div class="two-column-div">
                <div class="component-item">
                    <mat-form-field class="fullwidth" appearance="fill">
                        <mat-label>ASI</mat-label>
                        <input matInput name="ASI" formControlName="ASI"  type="number">
                    </mat-form-field>
                    <div *ngIf="getControl.ASI.touched && getControl.ASI.status === 'INVALID'" class="text-danger">
                        <div *ngIf="!getControl.ASI.errors.required && getControl.ASI.status === 'INVALID'">
                            ASI should be in range -20 to 600.
                        </div>

                    </div>
                </div>
                <div class="component-item">
                    <mat-form-field class="fullwidth" appearance="fill">
                        <mat-label>Asphaltene %</mat-label>
                        <input matInput name="asphaltenePercentage" formControlName="asphaltenePercentage" required
                            type="number">
                    </mat-form-field>
                    <div *ngIf="getControl.asphaltenePercentage.touched && getControl.asphaltenePercentage.status === 'INVALID'"
                        class="text-danger">
                        <div *ngIf="getControl.asphaltenePercentage.errors.required">Asphaltene % is required</div>
                        <div
                            *ngIf="!getControl.asphaltenePercentage.errors.required && getControl.asphaltenePercentage.status === 'INVALID'">
                            Asphaltene % should be in range 0 to 25.
                        </div>
                    </div>
                </div>

            </div>

            <div class="two-column-div">
                <div class="component-item">
                    <mat-form-field class="fullwidth" appearance="fill">
                        <mat-label>Source</mat-label> 
                        <mat-select formControlName="dataSource" name="dataSource" required>
                            <mat-option *ngFor="let dataSourceItem of dataSourceArray" [value]="dataSourceItem">
                                {{dataSourceItem}}
                            </mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="componentForm.controls['dataSource'].value === 'Other'" class="fullwidth" appearance="fill">
                        <mat-label>Other Source</mat-label> 
                        <input  matInput name="dataSourceOther" formControlName="dataSourceOther" required />
                    </mat-form-field>
                     <div *ngIf="getControl.dataSource.touched && getControl.dataSource.status === 'INVALID'"
                        class="text-danger">
                        <div *ngIf="getControl.dataSource.errors.required">Source is required</div>
                    </div>
                    <div *ngIf="componentForm.controls['dataSource'].value === 'Other' && getControl.dataSourceOther.touched && getControl.dataSourceOther.status === 'INVALID'"
                    class="text-danger">
                    <div *ngIf="getControl.dataSourceOther.errors.required">Other Source is required</div>
                </div>
                </div>
                <div class="component-item">
                    <mat-form-field class="fullwidth" appearance="fill">
                        <mat-label>n-Factor</mat-label>
                        <input matInput name="nFactor" formControlName="nFactor" required type="number">
                    </mat-form-field>
                    <div *ngIf="getControl.nFactor.touched && getControl.nFactor.status === 'INVALID'"
                        class="text-danger">
                        <div *ngIf="getControl.nFactor.errors.required">n-Factor is required</div>
                        <div *ngIf="!getControl.nFactor.errors.required && getControl.nFactor.status === 'INVALID'">
                            n-Factor should be in range 1.385 to 1.65.
                        </div>
                    </div>
                </div>
            </div>

            <div class="two-column-div">
                <div class="component-item">
                    <mat-form-field class="fullwidth" appearance="fill">
                        <mat-label>Origin</mat-label>
                        <mat-select formControlName="origin" name="origin" >
                            <mat-option *ngFor="let originItem of originArray" [value]="originItem">
                                {{originItem}}
                            </mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="fullwidth" appearance="fill" *ngIf="componentForm.controls['origin'].value === 'Other'" >
                        <mat-label>Other Origin</mat-label> 
                        <input matInput name="originOther" formControlName="originOther" />
                    </mat-form-field>
                </div>
                <div class="component-item">
                    <mat-form-field class="fullwidth" appearance="fill">
                        <mat-label>n-Temp</mat-label>
                        <input matInput name="nTemp" formControlName="nTemp" required type="number">
                    </mat-form-field>
                    <div *ngIf="getControl.nTemp.touched && getControl.nTemp.status === 'INVALID'" class="text-danger">
                        <div *ngIf="getControl.nTemp.errors.required">n-Temp is required</div>
                        <div *ngIf="!getControl.nTemp.errors.required && getControl.nTemp.status === 'INVALID'">
                            n-Temp should be in range 10 to 40.
                        </div>
                    </div>
                </div>
            </div>

            <div class="two-column-div">
                <div class="component-item">
                    <mat-form-field class="fullwidth" appearance="fill">
                        <mat-label>Crude Type</mat-label>
                        <mat-select formControlName="crudeType" name="crudeType" >
                            <mat-option *ngFor="let crudeTypeItem of crudeTypeArray" [value]="crudeTypeItem">
                                {{crudeTypeItem}}
                            </mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="fullwidth" appearance="fill" *ngIf="componentForm.controls['crudeType'].value === 'Other'" >
                        <mat-label>Other Crude Type</mat-label> 
                        <input matInput name="crudeTypeOther" formControlName="crudeTypeOther" />
                    </mat-form-field>
                </div>
                <div class="component-item">
                    <mat-form-field class="fullwidth" appearance="fill">
                        <mat-label>ASI Method</mat-label>
                        <mat-select formControlName="ASI_Method" name="ASI_Method" >
                            <mat-option *ngFor="let ASI_MethodItem of ASIMethodArray" [value]="ASI_MethodItem">
                                {{ASI_MethodItem}}
                            </mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="fullwidth" appearance="fill" *ngIf="componentForm.controls['ASI_Method'].value === 'Other'" >
                        <mat-label>Other ASI Method</mat-label> 
                        <input matInput name="ASI_MethodOther" formControlName="ASI_MethodOther" />
                    </mat-form-field>
                </div>
            </div>

            <div class="two-column-div">
                <div class="component-item">
                    <mat-form-field class="fullwidth" appearance="fill">
                        <mat-label>Class</mat-label>
                        <mat-select formControlName="class" name="class" >
                            <mat-option *ngFor="let classItem of classArray" [value]="classItem">
                                {{classItem}}
                            </mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="fullwidth" appearance="fill" *ngIf="componentForm.controls['class'].value === 'Other'" >
                        <mat-label>Other Class</mat-label> 
                        <input matInput name="classOther" formControlName="classOther" />
                    </mat-form-field>
                </div>
                <div class="component-item">
                    <mat-form-field class="fullwidth" appearance="fill">
                        <mat-label>Total Sulphur Content</mat-label>
                        <input matInput name="totalSulphurContent" formControlName="totalSulphurContent" type="number" />
                    </mat-form-field>
                    <div *ngIf="getControl.totalSulphurContent.touched && getControl.totalSulphurContent.status === 'INVALID'" class="text-danger">
                        <div *ngIf="!getControl.totalSulphurContent.errors.required && getControl.totalSulphurContent.status === 'INVALID'">
                            Total Sulphur Content should be in range 0 to 10.
                        </div>
                    </div>
                </div>
            </div>
            <div class="two-column-div">
                <div class="component-item">
                    <mat-form-field class="fullwidth-notes" appearance="fill">
                        <mat-label>Notes</mat-label>
                        <textarea matInput class = "notes-style" formControlName="notes" name="notes"></textarea>
                    </mat-form-field>
                </div>
                <div class="component-item" *ngIf="aggregateReq">                                    
                    <mat-checkbox   color="primary" class="aggregate-check"  formControlName="aggregate" [checked]="componentForm.controls['aggregate'].value" >
                        <mat-label class="aggregate-style">Aggregate</mat-label> 
                    </mat-checkbox>                
                </div>
            </div>
            <div>
                <div mat-dialog-actions align="end">
                    <button mat-button class="mat-button-sm cancel_btn" [mat-dialog-close]=null>Cancel</button>
                    <button mat-button class="mat-button-primary-sm" [disabled]="componentForm.status === 'INVALID'"
                        (click)="onSubmit()">Save</button>
                </div>
            </div>
        </div>
        </form>

    </div>

</div>