<div class="dialog-container">
  <div class="title">
    <h4 mat-dialog-title>{{ title }}</h4>
  </div>

  <form [formGroup]="projectForm" novalidate>
    <div class="dialog-contents" mat-dialog-content>
      <div class="div-box">
        <mat-form-field class="fullwidth" appearance="fill">
          <mat-label>Project Name</mat-label>
          <input matInput name="name" formControlName="name" required>
        </mat-form-field>
        <div *ngIf="getControl.name.touched && getControl.name.invalid" class="text-danger">
          <div *ngIf="getControl.name.errors.required">Project Name is required.</div>
          <div *ngIf="getControl.name.errors.emptySpace">Empty space is not allowed.</div>
          <div *ngIf="getControl.name.errors.maxlength">Length cannot exceed more than 50 characters.</div>
        </div>
        <mat-form-field class="fullwidth" appearance="fill">
          <mat-label>Customer Name </mat-label>
          <mat-select formControlName="customer" name="customer" required
            (selectionChange)="this.projectForm.controls['refinery'].setValue('');getRefineryList(null)">
            <ng-container *ngFor="let customer of customerList  | sortBy:'asc':'name'">
              <mat-option value="{{customer.id}}">{{customer.name}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <div *ngIf="getControl.customer.touched && getControl.customer.invalid" class="text-danger">
          <div *ngIf="getControl.customer.errors.required">Customer Name is required.</div>
        </div>

        <mat-form-field class="fullwidth" appearance="fill">
          <mat-label for="">Refinery</mat-label>
          <mat-select formControlName="refinery" name="refinery" required>
            <ng-container *ngFor="let refinery of refineryList | sortBy:'asc':'name'">
              <mat-option value="{{refinery.id}}">{{refinery.name}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <div *ngIf="getControl.refinery.touched && getControl.refinery.invalid" class="text-danger">
          <div *ngIf="getControl.refinery.errors.required">Refinery is required.</div>
        </div>

        <mat-form-field class="fullwidth" appearance="fill">
          <mat-label>Description</mat-label>
          <textarea matInput [(ngModel)]="projectData['description']" [ngModelOptions]="{standalone: true}" name="description"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button class="mat-button-sm cancel_btn" [mat-dialog-close]=null>Cancel</button>
      <button type="submit" [disabled]="projectForm.status === 'INVALID'" mat-button class="mat-button-primary-sm"
        (click)="onSubmit()">Save</button>
    </div>
  </form>


</div>