import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ComponentsService } from 'src/app/services/components/components.service';
import { CLASS_ARRAY, CONSTANTMESSAGE, CRUDE_TYPE_ARRAY } from 'src/app/shared/constants/constants-messages';
import { BhAlertService } from 'bh-theme';
import { CustomValidators } from 'src/app/shared/helpers/custom-validator';
import { forkJoin } from 'rxjs';
import { AuthConfigService } from '../../auth/auth-config.service';

@Component({
  selector: 'ple-add-component',
  templateUrl: './add-component.component.html',
  styleUrls: ['./add-component.component.scss']
})
export class AddComponentComponent implements OnInit {
  componentForm: FormGroup;
  title = '';
  isDisabled = true;
  newComponent: boolean = true;
  initiateForm = false;
  dataSourceArray: string[];
  crudeTypeArray: string[] = CRUDE_TYPE_ARRAY;
  classArray: string[] = CLASS_ARRAY;
  originArray: string[];
  ASIMethodArray: string[];
  isSMERole:boolean = false;
  aggregateReq:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {},
    public dialogRef: MatDialogRef<AddComponentComponent>,
    public formBuilder: FormBuilder,
    private componentsService: ComponentsService,
    private _bhAlertService: BhAlertService,
    private authConfigService: AuthConfigService
  ) {
    console.log("modal", data);

    if (data && data['id']) {
      this.newComponent = false;
      this.title = 'Edit Component Details';
    } else {
      this.newComponent = true;
      this.title = 'Create New Component';
    }
    this.isSMERole = this.authConfigService.checkIfSME();    
    if(this.isSMERole && this.data && this.data['dataType'] && (this.data['dataType'] === 'SAMPLE' || this.data['dataType'] === 'AGGREGATE' )){
      this.aggregateReq = true;
    }
  }

  ngOnInit(): void {
    if ( this.data && this.data['id']) {
      this.componentsService.getSingleComponent(this.data['id']).subscribe(
        output => {
          this.data = output;
          this.getDropdownValues();
      });
    } else {
     this.getDropdownValues();
    }

  }
  get getControl() {
    return this.componentForm.controls;
  }

  getDropdownValues() {
    forkJoin([
      this.componentsService.getDataSourceList(),
      this.componentsService.getOriginList(),
      this.componentsService.getASIMethodList(),
    ]).subscribe((allResults: Array<string[]>) => {
      if(allResults && allResults.length) {
        this.dataSourceArray = allResults[0].sort();
        this.originArray = allResults[1].sort();
        this.ASIMethodArray = allResults[2].sort();
        this.getFormData();
      }
    });
   }
 getFormData() {
   if(this.crudeTypeArray){
    this.crudeTypeArray=this.crudeTypeArray.sort();
   }
   if(this.classArray){
    this.classArray=this.classArray.sort();
   }
  let dataSourceValue = this.checkIfOtherValue(this.data['dataSource'], this.dataSourceArray);
  let crudeTypeValue = this.checkIfOtherValue(this.data['crudeType'], this.crudeTypeArray);
  let classValue = this.checkIfOtherValue(this.data['class'], this.classArray);
  let originValue = this.checkIfOtherValue(this.data['origin'], this.originArray);
  let ASIMethodValue = this.checkIfOtherValue(this.data['ASI_Method'], this.ASIMethodArray);
  this.componentForm = this.formBuilder.group({
    componentName: [this.data['componentName'] ? this.data['componentName'] : '', [Validators.required, Validators.maxLength(150), CustomValidators.NoEmptySpaceValidator()]],
    ASI: [{value : this.data['ASI'] != null ? this.data['ASI'] : '', disabled : this.data['dataType'] === 'SAVED BLEND' }, [ Validators.min(-20), Validators.max(600)]],
    asphaltenePercentage: [{value : this.data['asphaltenePercentage'] != null ? this.data['asphaltenePercentage'] : '', disabled : this.data['dataType'] === 'SAVED BLEND'}, [Validators.required, Validators.min(0), Validators.max(25)]],
    nTemp: [{value : this.data['nTemp'] ? this.data['nTemp'] : '', disabled : this.data['dataType'] === 'SAVED BLEND'}, [Validators.required, Validators.min(10), Validators.max(40)]],
    componentType: [this.data['componentType'] ? this.data['componentType'] : '', [Validators.required]],
    nFactor: [{value : this.data['nFactor'] ? this.data['nFactor'] : '', disabled : this.data['dataType'] === 'SAVED BLEND'}, [Validators.required, Validators.min(1.385), Validators.max(1.65)]],
    notes: [this.data['notes'] ? this.data['notes'] : ''],
    dataSource: [dataSourceValue.key === 'Other' ? 'Other': dataSourceValue.value ],
    origin: [originValue.key === 'Other' ? 'Other' : originValue.value],
    crudeType: [crudeTypeValue.key === 'Other' ? 'Other' : crudeTypeValue.value] ,
    dataSourceOther: [dataSourceValue.key === 'Other' ? dataSourceValue.value : ''],
    crudeTypeOther: [crudeTypeValue.key === 'Other' ? crudeTypeValue.value : ''] ,
    classOther:[classValue.key === 'Other' ? classValue.value : ''],
    originOther: [originValue.key === 'Other' ? originValue.value : ''],
    ASI_MethodOther: [ASIMethodValue.key === 'Other' ? ASIMethodValue.value : ''],
    class: [classValue.key === 'Other' ? 'Other' : classValue.value],
    ASI_Method:  [ASIMethodValue.key === 'Other' ? 'Other' : ASIMethodValue.value],
    totalSulphurContent: [this.data['totalSulphurContent']  != null ? this.data['totalSulphurContent'] : '', [ Validators.min(0), Validators.max(10)]],    
    aggregate:[this.data['dataType'] ? this.data['dataType'] === 'AGGREGATE'? true : false : false]

  })
  this.initiateForm = true;
 }
  onSubmit() {
    this.data['componentName'] = this.componentForm.value.componentName;  
    this.data['componentType'] = this.componentForm.value.componentType; 
    this.data['dataSource'] = this.componentForm.value.dataSource === 'Other' ?   this.componentForm.value.dataSourceOther : this.componentForm.value.dataSource ;
    this.data['notes'] = this.componentForm.value.notes;
    this.data['crudeType'] = this.componentForm.value.crudeType === 'Other' ? this.componentForm.value.crudeTypeOther : this.componentForm.value.crudeType;
    this.data['origin'] =  this.componentForm.value.origin === 'Other' ? this.componentForm.value.originOther : this.componentForm.value.origin;
    this.data['class'] = this.componentForm.value.class === 'Other' ? this.componentForm.value.classOther : this.componentForm.value.class;
    this.data['ASI_Method'] = this.componentForm.value.ASI_Method === 'Other' ? this.componentForm.value.ASI_MethodOther : this.componentForm.value.ASI_Method;
    if(this.data['dataType'] && (this.data['dataType'] ==='SAMPLE' || this.data['dataType'] === 'AGGREGATE')){
      this.data['dataType'] = this.componentForm.value.aggregate === true ? 'AGGREGATE' : 'SAMPLE';
    }else{
      this.data['dataType'] = this.data['dataType'];
    }
    this.data['totalSulphurContent'] = this.componentForm.value.totalSulphurContent;
    
    if(this.data['dataType'] !== 'SAVED BLEND') {
      this.data['ASI'] = this.componentForm.value.ASI === "" ? null : this.componentForm.value.ASI;
      this.data['nTemp'] = this.componentForm.value.nTemp;
      this.data['nFactor'] = this.componentForm.value.nFactor;
      this.data['asphaltenePercentage'] = this.componentForm.value.asphaltenePercentage; 
    }

    console.log("DATA", this.data);
    if(this.newComponent) {
      this.componentsService.saveComponent(this.data).subscribe(
        output => {
          console.log("POST Request is successful ", output);
          this._bhAlertService.openSnackBar({
            type: "success",
            message: CONSTANTMESSAGE.ADD_COMPONENT,
            subtitle: "",
            autodismiss: true,
            duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
          });
          this.dialogRef.close(true);
        },
        error => {
          console.log("Error", error);
        }
      );
    } else {
      this.componentsService.updateComponent(this.data).subscribe(
        output => {
          console.log("PUT Request is successful ", output);
          this._bhAlertService.openSnackBar({
            type: "success",
            message: CONSTANTMESSAGE.UPDATE_COMPONENT,
            subtitle: "",
            autodismiss: true,
            duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
          });
          this.dialogRef.close(true);
        },
        error => {
          console.log("Error", error);
        }
      );
    }
  }

  checkIfOtherValue (value, arrayList) {
    let obj = {key: "", value : ""};
    if(value) {
      let foundObject =  arrayList.find(item => value === item);
      if(foundObject) {
        obj.value = foundObject;
      } else {
        obj.key = "Other";
        obj.value = value;
      }
    } else {
      obj.value = '';
    }
    return obj;
  }  

}
