import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ple-delete-component',
  templateUrl: './delete-component.component.html',
  styleUrls: ['./delete-component.component.scss']
})
export class DeleteComponentComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DeleteComponentComponent>
  ) {
    console.log("modal", data);
  }

  ngOnInit(): void {
  }

  delete(isToBeDeleted) {
    this.data['delete'] = isToBeDeleted;
    this.dialogRef.close(this.data);
  }
}
