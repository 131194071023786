import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Component, OnInit, Inject, ViewChild } from "@angular/core";


@Component({
  selector: "app-binarygraph-modal",
  templateUrl: "./binarygraph-modal.component.html",
  styleUrls: ["./binarygraph-modal.component.scss"],
})


export class BinaryGraphModalComponent implements OnInit {
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BinaryGraphModalComponent>,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  agree(){
    this.dialogRef.close('agree');
  }

  cancel () {
    this.dialogRef.close('close');
  }
  ngAfterViewInit() { }

}
