<div class="projectDetails">
    <div class="lbm-spinner" *ngIf="activeSpinner" align="center">
        <mat-spinner></mat-spinner>
    </div>
    <div class="lbm-project-details-header">
        <!-- <lib-bh-breadcrumbs [maxLinks]='2'></lib-bh-breadcrumbs> -->
        <h2 class="pl-4">{{project?.name}}</h2>
        <div class="pl-4"><span>{{project?.customer.name}}</span> | <span>{{project?.refinery.name}}</span></div>
    </div>
    <div>
        <mat-tab-group #tabGroup class="pl-1">
            <mat-tab label="Components">
                <div class="componentstab">
                    <div class="cleft">
                        <div class="bh-table">
                            <div class="bh-table-header">
                                <div class="bh-table-title mat-subtitle-1">Components</div>
                                <span class="fill-spacer"></span>
                                <div class="bh-table-action-group">
                                    <!-- <mat-icon matTooltip="Filter List" class="bh-table-action-button">filter_list</mat-icon>
                                    <mat-icon matTooltip="More Action" class="bh-table-action-button">more_vert</mat-icon> -->
                                    <button mat-button class="bh-table-action-button mat-button-primary-sm"
                                        (click)="openDialog()" [disabled]="!isLoggedUserasCreatedUser">Select Components</button>
                                </div>
                            </div>
                            <table mat-table matSort matSortActive="name" [dataSource]="dataSource"
                            [ngClass]="isSMERole ? 'table-class-sme' : 'table-class'"  class="mat-elevation-z8">

                                <!--- Note that these columns can be defined in any order.
                                      The actual rendered columns are set as a property on the row definition" -->
                                <ng-container matColumnDef="tag">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 40px;"></th>
                                    <td mat-cell *matCellDef="let element"
                                        style="width: 40px; padding-top: 4px; padding-bottom: 4px;">
                                        <mat-icon *ngIf="element.tag === 'Global'" style="color: #02a783">public
                                        </mat-icon>
                                        <mat-icon *ngIf="element.tag !=='Global' && element.dataType == 'SAVED BLEND'" style="color: #bdbdbd">public_off</mat-icon>
                                        <mat-icon *ngIf="element.tag !=='Global' && element.dataType !== 'SAVED BLEND'" style="color: #bdbdbd">public</mat-icon> 
                                    </td>
                                </ng-container>
                                <!-- name Column -->
                                <ng-container matColumnDef="componentName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="{'width': isSMERole? '160px' : ''}"> Name
                                    </th>
                                    <td mat-cell *matCellDef="let element" [ngStyle]="{'width': isSMERole? '160px' : ''}"> {{element.componentName}} </td>
                                </ng-container>

                                <!-- Data Added Column -->
                                <ng-container matColumnDef="createdOn">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:110px;"> Date
                                        Added </th>
                                    <td mat-cell *matCellDef="let element" style="width:110px;"> {{element.createdOn |
                                        date}} </td>
                                </ng-container>

                                <!-- Data Type  -->
                                <ng-container matColumnDef="dataType">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:120px"> Data Type </th>
                                    <td mat-cell *matCellDef="let element" style="width:123px; padding-top: 4px; padding-bottom: 4px;"> {{element.dataType}} </td>
                                </ng-container>

                                <!-- ASI Column -->
                                <ng-container matColumnDef="ASI">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:65px;"> ASI </th>
                                    <td mat-cell *matCellDef="let element" style="width:60px;"> {{element.ASI | number:'1.0-0'}} </td>
                                </ng-container>

                                <!-- Asphaltenes Column -->
                                <ng-container matColumnDef="asphaltenePercentage">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Asphaltene % </th>
                                    <td mat-cell *matCellDef="let element"> {{element.asphaltenePercentage}} </td>
                                </ng-container>

                                <!-- Component Type -->
                                <ng-container matColumnDef="componentType">
                                    <th  mat-header-cell *matHeaderCellDef mat-sort-header> Component Type</th>
                                    <td mat-cell *matCellDef="let element">
                                    <ng-container *ngIf="element['componentType'] === 'Y'">Fuel Oil</ng-container>
                                    <ng-container *ngIf="element['componentType'] === 'D'">Diluent</ng-container>
                                    <ng-container *ngIf="element['componentType'] === 'N'">Crude Oil</ng-container>
                                    </td>
                                </ng-container>

                                <ng-container *ngIf="isSMERole" matColumnDef="nFactor">
                                    <th  mat-header-cell *matHeaderCellDef class="width-col" mat-sort-header> n-Factor </th>
                                    <td mat-cell *matCellDef="let element"> {{element.nFactor | number:'1.0-3'}} </td>
                                </ng-container>
                              
                                <ng-container *ngIf="isSMERole" matColumnDef="nTemp">
                                    <th  mat-header-cell *matHeaderCellDef class="width-col" mat-sort-header> n-Temp </th>
                                    <td mat-cell *matCellDef="let element"> {{element.nTemp | number:'1.0-3'}} </td>
                                </ng-container>
                              
                                <ng-container *ngIf="isSMERole" matColumnDef="fuelOilLSCIFactor">
                                    <th  mat-header-cell *matHeaderCellDef mat-sort-header> FuelOil LSCI Factor </th>
                                    <td mat-cell *matCellDef="let element"> {{element['fuelOilLSCIFactor'] | number:'1.0-3'}} </td>
                                </ng-container>
                              
                                <ng-container *ngIf="isSMERole" matColumnDef="notes">
                                    <th  mat-header-cell *matHeaderCellDef mat-sort-header> Notes </th>
                                    <td mat-cell *matCellDef="let element" class="showPointer text-truncate" matTooltip="{{element['notes']}}"> {{element['notes']}} </td>
                                </ng-container>
                              
                                <ng-container *ngIf="isSMERole" matColumnDef="addedByUser">
                                    <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> Added By </th>
                                    <td mat-cell *matCellDef="let element"> {{element['addedByUser']}} </td>
                                </ng-container>                               
                                <!-- Pagination Row (full width column) -->
                                <ng-container matColumnDef="paginator">
                                    <th mat-footer-cell *matFooterCellDef [colSpan]="displayedColumns.length">
                                        <mat-paginator [pageSizeOptions]="[5, 10, 15]" [pageSize]="5"
                                            showFirstLastButtons></mat-paginator>
                                    </th>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 36px;"></tr>
                                <tr mat-footer-row *matFooterRowDef="['paginator']; sticky: true"></tr>
                                <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
                            </table>
                        </div>
                        <div class="noData" *ngIf="!activeSpinner && dataSource.data.length === 0" class="lbm-illustration-container">
                            <bh-illustrations [illustrationData]="emptyState"></bh-illustrations>
                        </div>
                    </div>
                    <div class="cright">
                        <mat-card class="card-top">
                            <h3>Base Conditions</h3>
                            <hr>
                            <mat-form-field appearance="fill" class="fields mat-input-basefield">	
                                <mat-label>NFIT (deg/day)</mat-label>
                                <input type="number" step='any' matInput [(ngModel)]="nfitVal" (keypress)="validateNFITNumber($event)" [attr.disabled]="!isLoggedUserasCreatedUser">
                                <mat-icon matTooltipClass="tooltipStyle"
                                    matTooltip="Normalized Furnace Inlet Temperature"
                                    matTooltipPosition="left"
                                >info</mat-icon>
                            </mat-form-field>                         
                            <mat-form-field appearance="fill" class="fields mat-input-basefield" [class.text-danger]="nfitError">	
                                <mat-label>LSCI</mat-label>
                                <input matInput type="number"  step='any' [(ngModel)]="lsciVal" (keypress)="validateISCINumber($event)" (keyup)="checkVal($event)" [attr.disabled]="!isLoggedUserasCreatedUser">
                                <mat-icon
                                    matTooltip="LIFESPAN Stability Concern Index"
                                    matTooltipPosition="left"
                                >info</mat-icon>
                            </mat-form-field>
                            <div *ngIf="nfitError" class="text-danger">
                                <div style="padding-left: 5px">The Range for LSCI value (-5 to +5)</div> 
                            </div>
                            <mat-form-field appearance="fill" class="fields w-218 mat-input-basefield">
                                <mat-label>Baseline Date</mat-label>
                                <input matInput [matDatepicker]="picker" readonly [max]="today" (dateChange)="orgValueChange()" [(ngModel)]="updatedDate"  [attr.disabled]="!isLoggedUserasCreatedUser">
                                <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="!isLoggedUserasCreatedUser"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>                             
                            
                              
                            <div mat-dialog-actions align="end">
                                <button [disabled]="isBasevaluesSaved || checkDisable()" mat-button
                                    class="mat-button-primary-sm" (click)="apply()">Apply</button>
                            </div>
                        </mat-card>
                        
                        <div *ngIf="isFuelOilCompPresent && isSMERole"  >
                            <mat-card   class="card-bottom">
                            <h3>FO LSCI Factor Multiplier</h3>
                            <hr>                            
                                <mat-form-field  appearance="fill" class="fields mat-input-basefield" [class.text-danger]="fuelOilFactorError">	
                                    <input  matInput type="number" step='any' [(ngModel)]="fuelOilFactorMuliplier" (keypress)="validateFuelOilFactor($event)" (keyup)="checkFactorVal($event)" [disabled]="!isLoggedUserasCreatedUser">
                                    <mat-icon
                                        matTooltip="Fuel Oil LSCI Factor Multiplier"
                                        matTooltipPosition="left"
                                    >info</mat-icon>                                    
                                </mat-form-field>
                                <div *ngIf="fuelOilFactorError" class="text-danger mat-input-basefield">
                                    <div class ="fuel-oil-error">The Value should be in range 0 to 2</div> 
                                </div>
                                <div mat-dialog-actions align="end">
                                    <button [disabled]=" checkFactorDisable()" mat-button
                                        class="mat-button-primary-sm" (click)="applyFuelOilFactorMutiplier()">Apply</button>
                                </div>
                                  
                            </mat-card>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Blends" *ngIf="dataSource && dataSource.data && project &&  project.id">
                <ng-container>
                    <lbm-blend-table #blendsTable (validateBlend)="validate($event)" [componentsChanged]="componentsChanged" [selectedComponents]="dataSource.data" [isLoggedUserasCreatedUser]="isLoggedUserasCreatedUser"
                        [projectId]="project.id" [projectName]="projectName"  [projectCustomerName]="projectCustomerName" (onBlendsSaved)="onBlendsSaved()" (onBlendsChange)="blendsChanged($event)"></lbm-blend-table>
                </ng-container>
            </mat-tab>
            <mat-tab label="Report" *ngIf="getBlendData && getBlendData['blends'] && dataSource && dataSource.data && project &&  project.id">
                <lbm-report-table *ngIf="selectedTabIndex === 2" [blendsData]="getBlendData" [selectedComponents]="dataSource.data" [isLoggedUserasCreatedUser]="isLoggedUserasCreatedUser" [projectId]="project.id" [projectName]="projectName"></lbm-report-table>    
            </mat-tab>
        </mat-tab-group>
    </div>
</div>