import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { APP_DI_CONFIG, AppConfig } from './../../app-config/app-config';
import {AppConfigService} from './../../app-config/app-config.service';

@Injectable({
  providedIn: 'root',
})

export class CustomerService {

  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) { }
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  public getCustomerList() {
    return this.httpClient.get(this.appConfigService.getConfig().MASTER_DATA_API_SERVER + this.appConfigService.getConfig().GET_CUSTOMER_LIST);
  }

  public getRefineryList(selection) {
    return this.httpClient.get(this.appConfigService.getConfig().MASTER_DATA_API_SERVER + 'customers/'+ selection + '/refineries');
  }
}
