import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";



import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";



import { BhAlertService } from "bh-theme";
import { CONSTANTMESSAGE } from "./../../shared/constants/constants-messages";
import { AuthConfig, NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import {AppConfigService} from './../../app-config/app-config.service';
import { SpinnerService } from "./spinner.service";
import { AuthConfigService } from "src/app/modules/auth/auth-config.service";
import { Router } from "@angular/router";


/** Pass untouched request through to the next request handler. */
@Injectable({
  providedIn: "root",
})
export class ApiInterceptor implements HttpInterceptor {


  constructor(private _bhAlertService: BhAlertService, private readonly oauthService: OAuthService,
    private appConfigService: AppConfigService,
    private spinnerService : SpinnerService,
    private authConfigService: AuthConfigService,
    private router: Router) { }


  handleError(error: HttpErrorResponse) {
    console.log(error.status);
    let message;
    if(error && error.status && error.status === 401) {
      message = CONSTANTMESSAGE.UNAUTHORIZED_USER;
      if(this.authConfigService.hasValidAccessToken()){
      }else{
        this.spinnerService.deactivate();
        this._bhAlertService.openSnackBar({
          type: "error",
          message: message,
          subtitle: "",
          autodismiss: true,
          duration: "2000",
        });
        this.router.navigate(['']).then(() => {
          window.location.reload();
        });
      }
  
    } else if(error && error.status && error.status === 400 && error.error.description) {
      message = error.error.description;
    } else if(error && error.status && error.status === 400 && error.message) {
      message = error.message;
    } else if(error && error.status && error.status === 403 && error?.error?.description === CONSTANTMESSAGE.USER_NOT_ALLOWED_TO_SHARE_PROJECT) {
      message = CONSTANTMESSAGE.USER_NOT_ALLOWED_TO_SHARE_PROJECT;
    } else if(error?.error?.errors && error?.error?.errors.length) {
      message = error.error.errors[0].description ? error.error.errors[0].description  : CONSTANTMESSAGE.FAILED_TO_LOAD_API;
    } else {
      message = CONSTANTMESSAGE.FAILED_TO_LOAD_API;
    }
    this.spinnerService.deactivate();
    this._bhAlertService.openSnackBar({
      type: "error",
      message: message,
      subtitle: "",
      autodismiss: true,
      duration: "2000",
    });
    return throwError(error);
  }

  checkIfAdminUrl(url) {
    return (url.includes('https://login')  && url.includes('bakerhughes.com')) || url.includes('eiam/')  ? true : false;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let request;
    if(this.checkIfAdminUrl(req.url)) {
      request = req.clone({
        setHeaders: {
          Authorization: `SSWS `+this.appConfigService.getConfig().EIAM_API_KEY
        }
      });
    } else {
      request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.oauthService.getAccessToken()}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err: any) => {
        return this.handleError(err);
      })
    );
  }
}
