import { SharedModule } from './../../shared/shared.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatNativeDateModule } from '@angular/material/core';
import {
  ThemeLibModule,
  BhHeaderLinks,
  NavService,
  BhModalService,
} from 'bh-theme';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from './../../shared/services/api-interceptor';
import { MatSidenavModule } from '@angular/material/sidenav';

import { ComponentsRoutingModule } from './components-routing.module';
import { LibraryComponent } from './../components/components.component';
import { GridComponentComponent } from './grid-component/grid-component.component';
import { AddComponentComponent } from './add-component/add-component.component';
import { DeleteComponentComponent } from './delete-component/delete-component.component';
import { FilterComponentComponent } from './filter-component/filter-component.component';
import { SelectComponentTypeComponent } from './select-component-type/select-component-type.component';
import { AddAssayComponent } from './add-assay-component/add-assay-component.component';
import { ApprovalModalComponent } from './approval-modal/approval-modal.component';
import { BlendAsComponentComponent } from './blend-as-component/blend-as-component.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ComponentsRoutingModule,
    ThemeLibModule,
    MatCardModule,
    MatTabsModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
  ],
  declarations: [
    LibraryComponent,
    GridComponentComponent,
    AddComponentComponent,
    DeleteComponentComponent,
    FilterComponentComponent,
    SelectComponentTypeComponent,
    AddAssayComponent,
    ApprovalModalComponent,
    BlendAsComponentComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  ],
  exports: []
})
export class ComponentsModule { }
