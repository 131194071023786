import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { ComponentsService } from 'src/app/services/components/components.service';
import { CLASS_ARRAY, CONSTANTMESSAGE, CRUDE_TYPE_ARRAY } from 'src/app/shared/constants/constants-messages';
import { CustomValidators } from 'src/app/shared/helpers/custom-validator';
import {forkJoin} from "rxjs";
import {tap} from "rxjs/operators";
@Component({
  selector: 'lbm-add-component',
  templateUrl: './add-assay-component.component.html',
  styleUrls: ['./add-assay-component.component.scss']
})
export class AddAssayComponent implements OnInit {
  componentAssayForm: FormGroup;
  title = '';
  isDisabled = true;
  newComponent: boolean = true;
  initiateForm = false;
  gravityOptions: string[] = ['Specific Gravity', 'API Gravity'];
  dataSourceArray: string[];
  crudeTypeArray: string[] = CRUDE_TYPE_ARRAY;
  classArray: string[] = CLASS_ARRAY;
  originArray: string[];
  spGravityFlag = false;
  apiGravityFlag = false;
  ccrFlag = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {},
    public dialogRef: MatDialogRef<AddAssayComponent>,
    public formBuilder: FormBuilder,
    private componentsService: ComponentsService,
  ) {
    console.log("modal", data);
    if (data && data['id']) {
      this.newComponent = false;
      this.title = 'Edit Component Details';
    } else {
      this.newComponent = true;
      this.title = 'Create New Component';
    }
  }

  ngOnInit(): void {
    if ( this.data && this.data['id']) {
      this.componentsService.getSingleComponent(this.data['id']).subscribe(
        output => {
          this.data = output;
          this.getDropdownValues();
      });
    } else {
      this.getDropdownValues();
    }

  }
  get getControl() {
    return this.componentAssayForm.controls;
  }

 getDropdownValues() {
  forkJoin([
    this.componentsService.getDataSourceList(),
    this.componentsService.getOriginList(),
  ]).subscribe((allResults: Array<string[]>) => {
    if(allResults && allResults.length) {
      this.dataSourceArray = allResults[0].sort();
      this.originArray = allResults[1].sort();
      this.getFormData();
    }
  });
 }
 getFormData() {
  if(this.crudeTypeArray){
    this.crudeTypeArray=this.crudeTypeArray.sort();
   }
   if(this.classArray){
    this.classArray=this.classArray.sort();
   }
  let dataSourceValue = this.checkIfOtherValue(this.data['dataSource'], this.dataSourceArray);
  let crudeTypeValue = this.checkIfOtherValue(this.data['crudeType'], this.crudeTypeArray);
  let classValue = this.checkIfOtherValue(this.data['class'], this.classArray);
  let originValue = this.checkIfOtherValue(this.data['origin'], this.originArray);
  this.componentAssayForm = this.formBuilder.group({
    componentName: [this.data['componentName'] ? this.data['componentName'] : '', [Validators.required, Validators.maxLength(150), CustomValidators.NoEmptySpaceValidator()]],
    componentType: [this.data['componentType'] ? this.data['componentType'] : '', [Validators.required]],
    CCRorMCR:[this.data['CCRorMCR']!= null  ? this.data['CCRorMCR'] : '', [Validators.required, Validators.min(0), Validators.max(41.67)]],
    TAN:[this.data['TAN']!= null  ? this.data['TAN'] : '', [Validators.required, Validators.min(0), Validators.max(5)]],
    asphaltenePercentage: [this.data['asphaltenePercentage']!= null  ? this.data['asphaltenePercentage'] : '', [ Validators.min(0), Validators.max(25)]],
    totalSulphurContent: [this.data['totalSulphurContent'] != null ? this.data['totalSulphurContent'] : '', [ Validators.min(0), Validators.max(10)]],
    temperatureDensity: [this.data['temperatureDensity'] ? this.data['temperatureDensity'] : ''],
    dataSource: [dataSourceValue.key === 'Other' ? 'Other': dataSourceValue.value,[Validators.required] ],
    crudeType: [crudeTypeValue.key === 'Other' ? 'Other' : crudeTypeValue.value] ,
    origin: [originValue.key === 'Other' ? 'Other' : originValue.value],
    class: [classValue.key === 'Other' ? 'Other' : classValue.value],
    notes: [this.data['notes'] ? this.data['notes'] : ''],
    dataSourceOther: [dataSourceValue.key === 'Other' ? dataSourceValue.value : ''],
    crudeTypeOther: [crudeTypeValue.key === 'Other' ? crudeTypeValue.value : ''] ,
    classOther:[classValue.key === 'Other' ? classValue.value : ''],
    originOther:[originValue.key === 'Other' ? originValue.value : ''],
    gravitySelected:[this.checkGravitySelected()],
    specificGravity: [this.data['specificGravity'] ? this.data['specificGravity'] : '', [Validators.min(0.687), Validators.max(1.152)]],
    APIGravity: [this.data['APIGravity'] ? this.data['APIGravity'] : '',  [Validators.min(-8.69), Validators.max(74.43)]] 
  })
  this.initiateForm = true;

 }
  onSubmit() {
    this.data['componentName'] = this.componentAssayForm.value.componentName;
    this.data['componentType'] = this.componentAssayForm.value.componentType;
    this.data['CCRorMCR'] = this.componentAssayForm.value.CCRorMCR;
    this.data['TAN'] = this.componentAssayForm.value.TAN;
    this.data['asphaltenePercentage'] = this.componentAssayForm.value.asphaltenePercentage;
    this.data['totalSulphurContent'] = this.componentAssayForm.value.totalSulphurContent;
    this.data['temperatureDensity'] = this.componentAssayForm.value.temperatureDensity;
    this.data['dataSource'] = this.componentAssayForm.value.dataSource === 'Other' ?   this.componentAssayForm.value.dataSourceOther : this.componentAssayForm.value.dataSource ;
    this.data['crudeType'] = this.componentAssayForm.value.crudeType === 'Other' ? this.componentAssayForm.value.crudeTypeOther : this.componentAssayForm.value.crudeType;
    this.data['origin'] = this.componentAssayForm.value.origin === 'Other' ? this.componentAssayForm.value.originOther : this.componentAssayForm.value.origin;
    this.data['class'] = this.componentAssayForm.value.class === 'Other' ? this.componentAssayForm.value.classOther : this.componentAssayForm.value.class;
    this.data['notes'] = this.componentAssayForm.value.notes;
    if(this.componentAssayForm.controls['gravitySelected'].value === 'Specific Gravity') {
      this.data['specificGravity'] = this.componentAssayForm.value.specificGravity;
      delete this.data['APIGravity'];
    } else {
      this.data['APIGravity'] = this.componentAssayForm.value.APIGravity;
      delete this.data['specificGravity'];
    }
    this.data['dataType'] = 'ASSAY';

    console.log("DATA", this.data);
    if(this.newComponent) {
      this.componentsService.saveComponent(this.data).subscribe(
        output => {
          console.log("POST Request is successful ", output);
          this.dialogRef.close(true);
        },
        error => {
          console.log("Error", error);
        }
      );
    } else {
      this.componentsService.updateComponent(this.data).subscribe(
        output => {
          console.log("PUT Request is successful ", output);
          this.dialogRef.close(true);
        },
        error => {
          console.log("Error", error);
        }
      );
    }
  }

  checkIfOtherValue (value, arrayList) {
    let obj = {key: "", value : ""};
    if(value) {
      let foundObject =  arrayList.find(item => value === item);
      if(foundObject) {
        obj.value = foundObject;
      } else {
        obj.key = "Other";
        obj.value = value;
      }
    } else {
      obj.value = '';
    }
    return obj;
  }

  checkDisableButton() {
    let disableFlag = true;
    console.log(this.componentAssayForm.status);
    if(this.componentAssayForm.status === 'INVALID') {
      disableFlag = true;
    } else {
      if(this.componentAssayForm.value.gravitySelected === 'Specific Gravity') {
        if(!this.componentAssayForm.value.specificGravity || this.componentAssayForm.value.specificGravity  === '') {
          disableFlag = true;
          this.spGravityFlag = true;
          this.apiGravityFlag = false;
        } else {
          disableFlag = false;
          this.spGravityFlag = false;
          this.apiGravityFlag = false;
        }
      } else if (this.componentAssayForm.value.gravitySelected === 'API Gravity') {
        if(!this.componentAssayForm.value.APIGravity || this.componentAssayForm.value.APIGravity  === '') {
          disableFlag = true;
          this.spGravityFlag = false;
          this.apiGravityFlag = true;
        } else {
          disableFlag = false;
          this.spGravityFlag = false;
          this.apiGravityFlag = false;
        }
      } else {
        disableFlag = true;
      }
  
      if(!this.ccrFlag && disableFlag === false) {
        disableFlag = true;
      }
    }

    return disableFlag;
  }

  checkGravitySelected() {
    if(this.data && this.data['specificGravity'] && this.data['specificGavity'] !== '') {
      return 'Specific Gravity';
    } else if(this.data && this.data['APIGravity'] && this.data['APIGravity'] !== '') {
      return 'API Gravity';
    } else {
      return 'Specific Gravity';
    }
  }

  valuechange(event, key) {
    if(key === 'specificGravity') {
      if(!this.componentAssayForm.value.specificGravity || this.componentAssayForm.value.specificGravity  === '') {
        this.spGravityFlag = true;
      } else {
        this.spGravityFlag = false;
      }
    } else if(key === 'APIGravity') {
      if(!this.componentAssayForm.value.APIGravity || this.componentAssayForm.value.APIGravity  === '') {
        this.apiGravityFlag = true;
      } else {
        this.apiGravityFlag = false;
      }
    }
  }

   validateCCR(event) {
    if(this.componentAssayForm && this.componentAssayForm.value && this.componentAssayForm.value.asphaltenePercentage && this.componentAssayForm.value.asphaltenePercentage !== '') {
      if(this.componentAssayForm.value.CCRorMCR < this.componentAssayForm.value.asphaltenePercentage &&  this.componentAssayForm.value.CCRorMCR !== "") {
        this.ccrFlag = false;
      } else {
        this.ccrFlag = true;
      }
    } else {
      this.ccrFlag = true;
    }
  }

}

