import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { ProfileDetails } from 'bh-theme/lib/types/profile-details';
import { NotificationItem } from 'bh-theme/lib/types/notification-details';
import { MatSidenav } from '@angular/material/sidenav';
import { NavService, BhModalService } from 'bh-theme';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthConfigService } from './modules/auth/auth-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  @ViewChild('appDrawer') appDrawer: MatSidenav;
  isSideNav: boolean = true;
  isMenuOpen = true;
  profileDetails: ProfileDetails = {
    profileFirstName: 'Haley',
    profileLastName: 'Schmitz',
    profileEmail: 'haley@bakerhughes.com',
    profileImageUrl: '',
    logoutUrl: '/',
    profileMenuItems: []
  };

  // side nav
  navItems: any[] = [
    {
      displayName: 'Projects',
      iconName: 'dashboard',
      route: 'projects'
    },
    {
      displayName: 'Components Library',
      iconName: 'work',
      route: 'components/list'
    }
  ];
  mobileMenuData: any = {
    profileFirstName: this.profileDetails.profileFirstName,
    profileLastName: this.profileDetails.profileLastName,
    profileEmail: this.profileDetails.profileEmail,
    profileImageUrl: this.profileDetails.profileImageUrl,
    logoutUrl: this.profileDetails.logoutUrl,
    mobileMenuItems: [
      { subTitle: "Menu", navList: this.navItems },
      { subTitle: "Settings", navList: this.profileDetails.profileMenuItems }
    ]
  };

  isAdmin = false;
  errorPage : boolean = true;
  constructor(private navService: NavService,  
    private _bhModalService: BhModalService,
    private readonly oauthService: OAuthService,
    private authConfigService: AuthConfigService) {
      if(this.authConfigService.hasValidAccessToken()){
        console.log("yes access");
        this.errorPage = false;
      } else{
        console.log("no access");
        this.errorPage = true;
      }
     }
  ngOnInit(): void {

    let tokenPayload = this.authConfigService.getSessionStorage();
    
    this.profileDetails = {
      profileFirstName: tokenPayload ? tokenPayload['firstName'] : '',
      profileLastName:tokenPayload ? tokenPayload['lastName'] : '',
      profileEmail: tokenPayload ? tokenPayload['preferred_username'] : '',
      logoutUrl: '/',
      profileMenuItems: []
    };
    sessionStorage.setItem('loggedUserId', tokenPayload['sub'])
    this.isAdmin  = this.authConfigService.checkIfAdmin();
    if(this.isAdmin) {
      let adminObj = {
        displayName: 'Admin',
        iconName: 'person',
        route: 'admin'
      };
      this.navItems.push(adminObj);
    }
  }
  ngAfterViewInit(): void {
    this.navService.appDrawer = this.appDrawer;
  }
  toggleSidenav() {
    this.navService.toggle();
  }
  toggleMobileMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  navigationSelected() {
    this.isMenuOpen = false;
  }
  
}
