<!--app header starts here-->
<lib-baker-header  *ngIf="!errorPage" [isSideNavigation]="isSideNav"
  (toggleNavigation)="toggleSidenav()" (toggleMobileMenu)="toggleMobileMenu()" [isMenuOpen]="isMenuOpen" [headerLogoSrc] = "'../../assets/bhlogo.svg'">
  <div appname class="mat-toolbar-row__divider">
    <a class="mat-toolbar__app-name-text">LIFESPAN&#8482; Blending Model</a>
  </div>
  <div profile-menu>
    <lbm-header-profile-menu [profileDetails]="profileDetails">
    </lbm-header-profile-menu>
  </div>
</lib-baker-header>
<!--app header ends here-->
<lib-bh-mobile-menu *ngIf="isMenuOpen && !errorPage" [mobileMenuData]="mobileMenuData" (navigationSelected)="navigationSelected()"></lib-bh-mobile-menu>
<!--side nav starts here-->
<mat-sidenav-container  *ngIf="!errorPage" class="sidenav-container" hasBackdrop="false" autosize>
  <mat-sidenav #appDrawer class="sidenav" mode="side" [fixedInViewport]="false" opened="true"
    [attr.role]="'navigation'">
    <mat-nav-list>
      <bh-nav-list *ngFor="let item of navItems" [item]="item" [itemList]="navItems"></bh-nav-list>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <div class="pages-container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-container *ngIf="errorPage">
  <router-outlet></router-outlet>
</ng-container>
<!--side nav ends here-->








