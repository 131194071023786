import { NgModule, Provider } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProjectsComponent } from '../projects/projects.component';
import { CreateProjectComponent } from './create-project/create-project.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';

import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { ConfirmAwayGuard } from 'src/app/shared/guards/confirm-away.guard';
const mainRoutes: Routes = [
  {
    path: '',
    component: ProjectsComponent,
    children: [
      { path: '', component: ProjectsComponent, canActivate : [AuthGuard] },
      { path: 'add-project', component : CreateProjectComponent, canActivate : [AuthGuard]},
    ]
  },
  {
    path: 'project-details/:id',
    component: ProjectDetailsComponent, canDeactivate: ['canDeactivateDetails'],canActivate : [AuthGuard],
    // data: {
    //   breadcrumb: 'Project-details'
    // }
  },
];

const providers: Provider[] = [
  {
    provide: 'canDeactivateDetails',
    useValue: (component: ProjectDetailsComponent) => {
      return component.canDeactivateBlends();
    }
  }
];
@NgModule({
  imports: [RouterModule.forChild(mainRoutes)],
  exports: [RouterModule],
  providers: providers
})
export class ProjectsRoutingModule { }
