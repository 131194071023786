import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BhAlertService } from 'bh-theme';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { CreateProjectComponent } from './../create-project/create-project.component';
import { ProjectService } from 'src/app/services/project/project.service';
import { getCurrentUTCDate } from 'src/app/shared/helpers/helpers';
import { EditProjectComponent } from '../edit-project/edit-project.component';

import { IllustrationData, IllustrationNames, IllustrationActionButtonTypes } from 'bh-theme';
import { FilterProjectComponent } from '../filter-project/filter-project.component';
import { DeleteProjectComponent } from '../delete-project/delete-project.component';
import { UserListModalComponent } from '../user-list-modal/user-list-modal.component';
import { CONSTANTMESSAGE } from 'src/app/shared/constants/constants-messages';
import { UsersComponent } from '../users/users.component';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: "lbm-projects-list",
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
})
export class ProjectsListComponent implements OnInit {

  @ViewChild("filterButton") filterButton: ElementRef;

  projects: any[];
  emptyState: IllustrationData = {
    illustrationName: IllustrationNames.EmptyBox,
    title: 'No projects found',
    desc: 'Create a new project'
  }
  noDataFlag = false;
  searchText: string = '';
  originalList: any[] = [];
  activeSpinner: boolean;
  showTooltipPosition = 'above';
  loggedUserId;

  constructor(
    private dialog: MatDialog,
    private _bhAlertService: BhAlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private spinnerService : SpinnerService
  ) {
    this.loggedUserId = sessionStorage.getItem('loggedUserId');
    this.spinnerService.spinnerActive.subscribe(active => 
      this.toggleSpinner(active)); 
   }

  ngOnInit(): void {
    this.spinnerService.activate();
    this.getAllProjects();
  }

  toggleSpinner(active){
    this.activeSpinner = active
  }

  getAllProjects(){
    this.projectService.GetProjects().subscribe((data: any[]) => {
      console.log("check", data);
      if (data && data['projectList']) {
        this.originalList = data['projectList'].sort((a, b) => (+new Date(a.createdDate) < +new Date(b.createdDate)) ? 1 : -1);
        this.projects = data['projectList'].sort((a, b) => (+new Date(a.createdDate) < +new Date(b.createdDate)) ? 1 : -1);
      } else {
        this.noDataFlag = true;
      }
      this.spinnerService.deactivate();
    });
  }

  createProject(item) {
    let dialogRef = this.dialog.open(CreateProjectComponent, {
      width: '300px',
      // height: '310px',
      data: item ? item : {},
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.spinnerService.activate();
      if (res !== null) {
        const result = {};
        if (item !== null) {
          result['id'] = res['id'];
        }

        result['customerId'] = 0;
        result['refineryId'] = 0;
        result['createdAt'] = getCurrentUTCDate().toString();
        result['customer'] = res.customer;
        result['description'] = res.description;
        result['name'] = res.name;
        result['refinery'] = res.refinery;
        if (item !== null) {
          this.projectService.updateProject(result).subscribe(
            data => {
              console.log("PUT Request is successful ", data);
              this.projectService.GetProjects().subscribe((result: any[]) => {
                console.log("check", result);
                this.projects = result['projectList'].sort((a, b) => (+new Date(a.createdDate) < +new Date(b.createdDate)) ? 1 : -1);
                this.originalList = result['projectList'].sort((a, b) => (+new Date(a.createdDate) < +new Date(b.createdDate)) ? 1 : -1);
                this._bhAlertService.openSnackBar({
                  type: "success",
                  message: CONSTANTMESSAGE.UPDATE_PROJECT,
                  subtitle: "",
                  autodismiss: true,
                  duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
                });
                this.spinnerService.deactivate();
              });
            },
            error => {
              console.log("Error", error);
              this.spinnerService.deactivate();
            }
          );
        } else {
          this.projectService.saveProject(result).subscribe(
            data => {
              console.log("POST Request is successful ", data);
              this.projectService.GetProjects().subscribe((result: any[]) => {
                this.projects = result['projectList'].sort((a, b) => (+new Date(a.createdDate) < +new Date(b.createdDate)) ? 1 : -1);
                this.originalList = result['projectList'].sort((a, b) => (+new Date(a.createdDate) < +new Date(b.createdDate)) ? 1 : -1);
                this._bhAlertService.openSnackBar({
                  type: "success",
                  message: CONSTANTMESSAGE.ADD_PROJECT,
                  subtitle: "",
                  autodismiss: true,
                  duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
                });
                this.spinnerService.deactivate();
              });
            },
            error => {
              console.log("Error", error);
              this.spinnerService.deactivate();
            }
          );
        }


      } else {
        this.spinnerService.deactivate();
      }
    });
  }
  

  onOptionsClicked(event, item, index) {
    let dialogRef = this.dialog.open(EditProjectComponent, {
      width: '300px',
      data: {
        targetX: event.x - 30,
        targetY: this.loggedUserId === item.createdBy ? event.y - 50 : event.y ,
        itemId: item.id,
        itemIndex: index,
        loggedUserId: this.loggedUserId,
        createdBy: item.createdBy
      },
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe((res) => {
      const createdUser = this.originalList.filter((elem) => {
        return elem['id'] === res['itemId'];
      })[0];
      if (res && (res['option'] === 'Edit')) {
        this.createProject(createdUser);
      }
      if (res && (res['option'] === 'Delete')) {
        this.deleteModal(res['itemId']);
      }
      if (res && (res['option'] === 'editMembers')) {
        console.log(item);
        let usersDialogRef = this.dialog.open(UsersComponent, {
          width: "80vw",
          height: 'auto',          
          data: {data: item, createdUser: createdUser}
        });
        usersDialogRef.afterClosed().subscribe((res) => {
          this.getAllProjects();
        });
      }
      if (res && (res['option'] === 'Duplicate')) {
        this.duplicateProject(res)
      }
    });
  }

  search(text) {
    if (text.trim().length >= 2) {
      this.projects = this.originalList.filter(function (e) {
        return e.name.toUpperCase().includes(text.toUpperCase());
      });
    } else {
      this.projects = this.originalList;
    }
  }

  filter(event) {
    let dialogRef = this.dialog.open(FilterProjectComponent, {
      width: '300px',
      data: {
        targetX: event.target.getBoundingClientRect().x - 10,
        targetY: event.target.getBoundingClientRect().y + event.target.getBoundingClientRect().height
      },
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      console.log("Filter ", res);
      if (res) {
        if (res['option'] == 'Updated') {
          this.projects = this.originalList.filter((elem) => {
            if (elem["updatedDate"]) {
              return elem;
            };
          });

        }

        if (res['option'] == 'Created') {
          this.projects = this.originalList.filter((elem) => {
            if (elem["createdDate"]) {
              return elem;
            };
          });

        }
        if (res['option'] == 'Clear') {
          this.projects = this.originalList;

        }
      }
    });
  }

  seeMoreUsers(item) {
    const createdUser = this.originalList.filter((elem) => {
      return elem['id'] === item.id;
    })[0];
    let usersListDialogRef = this.dialog.open(UserListModalComponent, {
      width: "500px",
      data: {data: item, createdUser: createdUser},
      disableClose: true,
    });
    usersListDialogRef.afterClosed().subscribe((res) => {
      this.getAllProjects();
    });
  }

  deleteModal(id) {
    let dialogRef = this.dialog.open(DeleteProjectComponent, {
      width: "530px",
      data: {
        delete: false,
        id
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      this.spinnerService.activate();
      if (result['delete']) {
        this.delete(result['id']);
      } else {
        this.spinnerService.deactivate();
      }
    });
  }

  delete(id) {
    this.projectService.deleteProject(id).subscribe(
      output => {
        console.log("DELETE Request is successful ", output);
        this.projectService.GetProjects().subscribe((result: any[]) => {
          console.log('datamodelDELETE', result['projectList'])
          if (result && result['projectList']) {
            this.projects = result['projectList'].sort((a, b) => (+new Date(a.createdDate) < +new Date(b.createdDate)) ? 1 : -1);
            this.originalList = result['projectList'].sort((a, b) => (+new Date(a.createdDate) < +new Date(b.createdDate)) ? 1 : -1);
          } else {
            this.projects = [];
            this.originalList = [];
          }
          this.spinnerService.deactivate();
          this._bhAlertService.openSnackBar({
            type: "success",
            message: CONSTANTMESSAGE.DELETE_PROJECT,
            subtitle: "",
            autodismiss: true,
            duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
          });
        });
      });
  }

  duplicateProject(res){
    this.projectService.duplicateProject(res['itemId']).subscribe(
      data => {
        console.log("POST Request is successful ", data);
        this.projectService.GetProjects().subscribe((result: any[]) => {
          console.log("check", result);
          if(result){
            this.projects = result['projectList'].sort((a, b) => (+new Date(a.createdDate) < +new Date(b.createdDate)) ? 1 : -1);
            this.originalList = result['projectList'].sort((a, b) => (+new Date(a.createdDate) < +new Date(b.createdDate)) ? 1 : -1);
            this._bhAlertService.openSnackBar({
              type: "success",
              message: CONSTANTMESSAGE.DULPLICATE_PROJECT,
              subtitle: "",
              autodismiss: true,
              duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
            });
          }
          this.spinnerService.deactivate();
        });
      },
      error => {
        console.log("Error", error);
        this.spinnerService.deactivate();
      }
    );

  }

  goToDetailPage (id) {
    this.router.navigate(['/project-details/' + id]);
  }

  getInitials(text) {
    (fullname=>fullname.map((n, i)=>(i==0||i==fullname.length-1)&&n[0]).filter(n=>n).join(""))
(text.split(" "));
  }
}
