import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import {
  axisBottom  as d3_axisBottom,
  axisLeft    as d3_axisLeft,
  scaleLinear as d3_scaleLinear,
  select      as d3_select
} from 'd3';

@Component({
  selector: 'ple-binary-graph',
  templateUrl: './binary-graph.component.html',
  styleUrls: ['./binary-graph.component.scss']
})
export class BinaryGraphComponent implements OnInit, OnChanges {
  data = [];

  @Input() blendsData;
  @Input() selectedComponents;

  margin: { top: number; right: number; bottom: number; left: number; };
  contentWidth: number;
  contentHeight: number;
  width: number;
  height: number;
  public svg;
  public svgInner;
  public yScale;
  public yScaleRight;
  public xScale;
  public xAxis;
  public yAxis;
  public yAxisRight;
  public lineGroup;
  public lineGroupRight;
  lineColor = '#02A783';
  lineColor2 = "#F0B429";
  @ViewChild('chartElem', { static: true }) chartElem: ElementRef;

  constructor() {}

  ngOnInit() {
    console.log(this.blendsData);
    if(this.svg) {
      this.removeChart();
    }

    this.initializeChart();
    this.createChart();
    this.drawChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.firstChange && changes.hasOwnProperty('blendsData') && changes.blendsData.currentValue && changes.blendsData.currentValue !== changes.blendsData.previousValue) {
      let chartData = [];
      let componentField = this.selectedComponents[1].listName;
      let valueField = 'ASI';
      let valueFieldRight = 'LSCI';
      changes.blendsData.currentValue.forEach(blendObj => {
        chartData.push({
          blend: blendObj[componentField],
          value: blendObj[valueField],
          valueRight: blendObj[valueFieldRight]
        });
      });
      this.data = chartData;
      if (this.svg) {
        this.removeChart();
        this.initializeChart();
        this.createChart();
        this.drawChart();
      }
    }
  }

  private initializeChart(): void {
    this.svg = d3
    .select(this.chartElem.nativeElement)
    .select('.linechart');
    this.margin = {
      top: 40,
      right: 40,
      bottom: 40,
      left: 40
    };
    this.width = +this.svg.style("width").replace("px", "");
    this.height = 350;

    this.contentWidth = this.width - this.margin.left - this.margin.right;
    this.contentHeight = this.height - this.margin.top - this.margin.bottom;
    this.svg = this.svg
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height);
    this.svgInner = this.svg.append("g").attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

  }

  createChart (): void {
    this.yScale = d3
    .scaleLinear()
    .domain([d3.max(this.data, d => d.value) + 3, d3.min(this.data, d => d.value) - 3])
    .range([0, this.contentHeight]); 

    this.yScaleRight = d3
    .scaleLinear()
    .domain([d3.max(this.data, d => d.valueRight)+0.5 , d3.min(this.data, d => d.valueRight)- 0.5 ])
    .range([0, this.contentHeight]);

    this.xScale = d3
    .scaleLinear()
    .domain(d3.extent(this.data, d => d.blend))
    .range([0, this.contentWidth]);

     // Create yAxis
     this.yAxis = this.svgInner
     .append('g')
     .attr('id', 'y-axis');

     // Create yAxisRight
     this.yAxisRight = this.svgInner
     .append('g')
     .attr('id', 'y-axis-right')
     .attr("transform", "translate(" + this.contentWidth + ",0)");
 
     // Create xAxis
     this.xAxis = this.svgInner
       .append('g')
       .attr('id', 'x-axis')
       .attr("transform", "translate(" + 0 + "," + this.contentHeight + ")");
    const yAxisGrid = d3_axisLeft(this.yScale).tickSize(-this.contentWidth).tickFormat(d3.timeFormat('')).ticks(10);
    this.svgInner.append('g')
    .attr('class', 'y axis-grid')
    .call(yAxisGrid);
     this.lineGroup = this.svgInner
       .append('g')
       .append('path')
       .attr('id', 'line')
       .style('fill', 'none')
       .style('stroke', this.lineColor)
       .style('stroke-width', '1px');
    this.lineGroupRight = this.svgInner
       .append('g')
       .append('path')
       .attr('id', 'lineRight')
       .style('fill', 'none')
       .style('stroke', this.lineColor2)
       .style('stroke-width', '1px')

  }

  private drawChart(): void {
    this.svg.attr('width', this.width);

    const xAxis = d3
      .axisBottom(this.xScale)
      .ticks(10)
      .tickFormat(d => d + "%")
      .tickPadding(10)
      .tickSize(0);

    this.xAxis.call(xAxis)
      .append("text")
      .attr("fill", "black")//set the fill here
      .style("transform","translate(" + (this.width / 2) +"px, " + 30 + "px)")
      .text("%" +this.selectedComponents[1].componentName+ " in Blend ");
    const yAxis = d3
      .axisLeft(this.yScale)
      .tickPadding(10)
      .tickSize(0);

    this.yAxis.call(yAxis)
    .append("text")
      .attr("fill", "black")//set the fill here
      .style("transform","translate(-2%, 38%) rotate(270deg)")
      .text("ASI");

    const yAxisRight = d3
      .axisRight(this.yScaleRight)
      .tickPadding(10)
      .tickSize(0);

    this.yAxisRight.call(yAxisRight)
      .append("text")
        .attr("fill", "black")//set the fill here
        .style("transform","translate(3%, 38%) rotate(270deg)")
        .text("LSCI");

    const line = d3
      .line()
      .x(d => d[0])
      .y(d => d[1])
      .curve(d3.curveMonotoneX);

    const points: [number, number][] = this.data.map(d => [
      this.xScale(d.blend),
      this.yScale(d.value)

    ]);
    const pointsRight: [number, number][] = this.data.map(d => [
      this.xScale(d.blend),
      this.yScaleRight(d.valueRight)
    ]);

    this.lineGroup.attr('d', line(points));
    this.lineGroupRight.attr('d', line(pointsRight));
    this.svgInner
      .selectAll("circle")
      .append("g")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("r", 4)
      .attr("cx", d => this.xScale(d.blend))
      .attr("cy", d => this.yScale(d.value))
      .style("fill", d => this.lineColor);
    this.svgInner
      .selectAll("square")
      .append("g")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("r", 4)
      .attr("cx", d => this.xScale(d.blend))
      .attr("cy", d => this.yScaleRight(d.valueRight))
      .style("fill", d => this.lineColor2);

    const tooltip = this.svgInner.append("g").attr('class', 'tooltipDiv');

    this.svgInner.on("touchmove mousemove", (event) => {
      const { blend, value, valueRight } = this.bisect(d3.pointer(event, this.chartElem.nativeElement)[0]);   
      let tooltipYPosition = this.yScale(value);
      if(tooltipYPosition > 195) {
        tooltipYPosition = 195;
        tooltip
        .attr("class", "tooltipDownward");
      } else {
        tooltipYPosition = this.yScale(value);
        tooltip
        .attr("class", "tooltipUpward");
      }
      let tooltipXPosition = this.xScale(blend);
      if(tooltipXPosition < 55) {
        tooltipXPosition = 55;
         tooltip
         .attr("class", "tooltipDownward");
      } else if(tooltipXPosition > 1000){
        tooltipXPosition = 1000;
         tooltip
         .attr("class", "tooltipDownward");

      }else {
        tooltipXPosition = this.xScale(blend);
         tooltip
         .attr("class", "tooltipUpward");
      }

      tooltip
        .attr("class", "tooltipContainer")      
        .attr("transform", `translate(${tooltipXPosition + 10 },${tooltipYPosition + this.margin.right})`)
        .call(this.callout, `${this.formatBlend(blend)}\n${this.formatValue(value, valueRight)}`);

    });

    this.svgInner.on("touchend mouseleave", () => tooltip.call(this.callout, null));
  }

  bisect(mx) {
    const bisectFn = d3.bisector<any, any>(d => d.blend).left;
    const blend = this.xScale.invert(mx);
    const index = bisectFn(this.data, blend, 1);
    const a = this.data[index - 1];
    const b = this.data[index + 1];
    return b && (blend - a.blend > b.blend - blend) ? b : a;
  }

  formatBlend(blend) {
    return this.selectedComponents[1].componentName + ': ' + blend + '%' + '\n' +  this.selectedComponents[0].componentName + ': ' + (100 - blend) + '%';
  }

  formatValue(value,valueRight) {
    return 'ASI: ' + Math.round(value) + '\n' + 'LSCI:' + (valueRight).toFixed(1);
  }

  callout(g, value) {
    if (!value) return g.style("display", "none");
    g.style("display", null)
      .style("pointer-events", "none")
      .style("font", "10px sans-serif");

    const path = g.selectAll("path")
      .data([null])
      .join("path")
      .attr("fill", "white")
      .attr("stroke", "#EBEFEE");

    const text = g.selectAll("text")
      .data([null])
      .join("text")
      .call(text => text
        .selectAll("tspan")
        .data((value + "").split(/\n/))
        .join("tspan")
        .attr("x", 0)
        .attr("y", (d, i) => `${i * 1.1}em`)
        .text(d => d));

    const { x, y, width: w, height: h } = text.node().getBBox();
    text.attr("transform", `translate(${-w / 2},${15 - y})`);
    path.attr("d", `M${-w / 2 - 10},5H${w / 2 + 10}v${h + 20}h-${w + 20}z`);
  }

  removeChart() {
    d3.selectAll(".linechart svg").remove();
  }
  @HostListener('window:resize')
  redraw(): void {
    if(this.svg) {
      this.removeChart();
    }

    this.initializeChart();
    this.createChart();
    this.drawChart();
  }

}
