import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IllustrationData, IllustrationNames, IllustrationActionButtonTypes, BhAlertService } from 'bh-theme';
import { ComponentsService } from 'src/app/services/components/components.service';
import { MatDialog } from "@angular/material/dialog";
import { AddComponentComponent } from '../add-component/add-component.component';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DeleteComponentComponent } from '../delete-component/delete-component.component';
import { FilterComponentComponent } from '../filter-component/filter-component.component';
import { AuthConfigService } from '../../auth/auth-config.service';
import { SelectComponentTypeComponent } from '../select-component-type/select-component-type.component';
import { AddAssayComponent } from '../add-assay-component/add-assay-component.component';
import { CONSTANTMESSAGE } from 'src/app/shared/constants/constants-messages';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { removedDuplicates } from 'src/app/shared/helpers/helpers';
import { interpolateRainbow } from 'd3';
import { element } from 'protractor';
import { SelectionModel } from "@angular/cdk/collections";
import { ApprovalModalComponent } from '../approval-modal/approval-modal.component';
import { ConfirmModalComponent } from 'src/app/modules/projects/confirm-modal/confirm-modal.component';


@Component({
  selector: 'lbm-grid-component',
  templateUrl: './grid-component.component.html',
  styleUrls: ['./grid-component.component.scss'],
})
export class GridComponentComponent implements OnInit, AfterViewInit {
  //dataSource = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;  
  dataModel = [];
  dataDeleted = false;
  isSMERole = false;
  displayedColumns: string[] = ['icon', 'componentName', 'createdOn',  'dataType', 'ASI', 'asphaltenePercentage', 'componentType',  'id'];
  searchText = '';
  localComponents;  
  emptyState: IllustrationData = {
    illustrationName: IllustrationNames.EmptyBox,
    title: 'No Components Added',
    desc: 'Manually add components'
  }
  dataSource = new MatTableDataSource<any>([]);
  activeSpinner: boolean;
  filterSelected = null;
  selection = new SelectionModel<any>(true, []);
  constructor(private dialog: MatDialog, private componentsService: ComponentsService, 
    private authConfigService: AuthConfigService, private _bhAlertService: BhAlertService,
    private spinnerService : SpinnerService ) {      
      this.spinnerService.spinnerActive.subscribe(active => 
        this.toggleSpinner(active)); 
      this.isSMERole = this.authConfigService.checkIfSME();
    if (this.isSMERole) {
      this.displayedColumns = ['icon', 'componentName', 'createdOn', 'dataType', 'ASI',  'asphaltenePercentage', 'componentType','nFactor', 'nTemp','correctedNFactor', 'SBn', 'In', 'fuelOilLSCIFactor', 'ASI_Method','notes', 'createdBy','approvedBy','id'];
    }
    
  }

  ngOnInit(): void {    
    this.spinnerService.activate();
    this.isSMERole  = this.authConfigService.checkIfSME();
    this.componentsService.GetComponents().subscribe((data: any[]) => {
      if(data && data['createdComponents']) {
        this.localComponents = data['createdComponents'];
      }
      let localOrgComponents;
      if(data && data['localCompoToOrgUsers']) {
        localOrgComponents = removedDuplicates(data['localCompoToOrgUsers'], this.localComponents);
        this.localComponents = [...this.localComponents,...localOrgComponents];
        console.log(localOrgComponents);
      }
        this.dataModel = [...data['data'],...data['createdComponents'],...localOrgComponents];
        this.dataSource.data =  [...data['data'],...data['createdComponents'], ...localOrgComponents];
        this.dataSource.paginator = this.paginator;      
        this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
          return  data[sortHeaderId].toString().trim().toLocaleLowerCase();
        };
      
      this.spinnerService.deactivate();
      if(this.isSMERole && this.dataSource.data && this.dataSource.data.length > 0){
        this._bhAlertService.openSnackBar({
          type: "success",
          message: CONSTANTMESSAGE.LOCAL_COMPONENT_APPROVAL_MESSAGE,
          subtitle: "",
          autodismiss: true,
          duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
       });
      }
    });
    
  }

  ngAfterViewInit () : void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      return  data[sortHeaderId].toString().trim().toLocaleLowerCase();
    };
  }

  toggleSpinner(active){
    console.log("inside Toggle Spinner")
    this.activeSpinner = active
  }

  textAreaReadonly(value, element) {
    console.log("Checked", value, element)
  }

  refresh(): Observable<any[]> {
    let data = this.dataSource.data;
    return of(data);
  }

  saveItem(isNewItem) {
    if (isNewItem) {
      this.componentsService.GetComponents().subscribe((data: any[]) => {
        console.log('datamodelPost', data['data'])
        if(data && data['createdComponents']) {
          this.localComponents = data['createdComponents'];
        }
        let localOrgComponents;
        if(data && data['localCompoToOrgUsers']) {
          localOrgComponents = removedDuplicates(data['localCompoToOrgUsers'], this.localComponents);
          this.localComponents = [...this.localComponents,...localOrgComponents];
        }
          this.dataModel = [...data['data'],...data['createdComponents'], ...localOrgComponents];
          this.dataSource.data =  [...data['data'],...data['createdComponents'], ...localOrgComponents];
          this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
            return  data[sortHeaderId].toString().trim().toLocaleLowerCase();
          };
          this.iterateUsingFilter();
        this.searchText = '';
        const componentIds = this.selection.selected.map(row => row.id);
        const rowsToSelect = this.dataSource.data.filter(item => componentIds.includes(item.id));    
        this.selection.clear();
        rowsToSelect.forEach(row => this.selection.select(row));
        this.spinnerService.deactivate();
      });
    } else {
        // console.log("PUT Request is successful ", output);
        this.componentsService.GetComponents().subscribe((data: any[]) => {
          console.log('datamodelPut', data['data']);
          if(data && data['createdComponents']) {
            this.localComponents = data['createdComponents'];
          }
          let localOrgComponents;
          if(data && data['localCompoToOrgUsers']) {
            localOrgComponents = removedDuplicates(data['localCompoToOrgUsers'], this.localComponents);
            this.localComponents = [...this.localComponents,...localOrgComponents];
          }
            this.dataModel = [...data['data'],...data['createdComponents'], ...localOrgComponents];
            this.dataSource.data =  [...data['data'],...data['createdComponents'], ...localOrgComponents];
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
              return  data[sortHeaderId].toString().trim().toLocaleLowerCase();
            };
            this.iterateUsingFilter();
          this.searchText = '';
          const componentIds = this.selection.selected.map(row => row.id);
          const rowsToSelect = this.dataSource.data.filter(item => componentIds.includes(item.id));    
          this.selection.clear();
          rowsToSelect.forEach(row => this.selection.select(row));
          this.spinnerService.deactivate();
        });
    }
    
  }

  editComponent(item) {
    if(item && item.id) {
      item = this.dataSource.data.filter(cmp => cmp.id === item.id)[0];
      console.log(item);
      if(item.dataType === "ASSAY") {
        let dialogRef = this.dialog.open(AddAssayComponent, {
          width: "800px",
          data: item,
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((res) => {
          if(res !== null) {
            this.spinnerService.activate();
            this.saveItem(false);
          }
        });
      } else {
        let dialogRef = this.dialog.open(AddComponentComponent, {
          width: "800px",
          data: item,
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((res) => {
          if(res !== null) {
            this.spinnerService.activate();
            this.saveItem(false);
          }
        });
      }
     
    }
  }

  createComponent(item) {
    let dialogRef = this.dialog.open(AddComponentComponent, {
      width: "800px",
      data: item,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if(res !== null) {
        this.spinnerService.activate();
        this.saveItem(true);
      }
    });
  }

  createAssayComponent(item) {
    let dialogRef = this.dialog.open(AddAssayComponent, {
      width: "800px",
      data: item,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if(res !== null) {
        this.spinnerService.activate();
        this.saveItem(true);
      }
    });
  }

  selectComponentType() {
    let dialogRef = this.dialog.open(SelectComponentTypeComponent, {
      width: "300px",
      data: {},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      console.log('result', res);
      if(res === 'Sample') {
        this.createComponent({'dataType' : 'SAMPLE' });
      } else if (res === 'Assay') {
        this.createAssayComponent({'dataType' : 'ASSAY' });
      } else {
        
      }
    });
  }

  search() {
    
    if (this.searchText.trim().length > 0) {
      this.dataSource.data = this.dataModel.filter((elem) => {
        return elem['componentName'].toUpperCase().includes(this.searchText.toUpperCase());
      });
    } else {
      this.dataSource.data = this.dataModel;
    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (a === b) {​​​​​​​​
      return 1;
    }​​​​​​​​ else {​​​​​​​​
      if (typeof (a) === "string" && typeof (b) === "string") {​​​​​​​​
      return (a.toUpperCase() < b.toUpperCase() ? -1 : 1) * (isAsc ? 1 : -1);
            }​​​​​​​​ else {​​​​​​​​
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
            }​​​​​​​​
    }​​​​​​​​
  }


  deleteModal(id) {
    let dialogRef = this.dialog.open(DeleteComponentComponent, {
      width: "530px",
      data: {
        delete: false,
        id
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      this.spinnerService.activate();
      if (result['delete']) {
        this.searchText = '';
        this.delete(result['id']);
      } else {
        this.spinnerService.deactivate();
      }
    });
  }

  delete(id) {
    this.componentsService.deleteComponent(id).subscribe(
      output => {
        console.log("DELETE Request is successful ", output);
        this.componentsService.GetComponents().subscribe((data: any[]) => {
          let localOrgComponents;
          if(data && data['localCompoToOrgUsers']) {
            localOrgComponents = removedDuplicates(data['localCompoToOrgUsers'], data['createdComponents']);
            this.localComponents = [...this.localComponents,...localOrgComponents];      
          }
            this.dataModel = [...data['data'],...data['createdComponents'], ...localOrgComponents];
            this.dataSource.data =  [...data['data'],...data['createdComponents'], ...localOrgComponents];
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
              return  data[sortHeaderId].toString().trim().toLocaleLowerCase();
            };
            this.iterateUsingFilter();
            const componentIds = this.selection.selected.map(row => row.id);
            const rowsToSelect = this.dataSource.data.filter(item => componentIds.includes(item.id));    
            this.selection.clear();
            rowsToSelect.forEach(row => this.selection.select(row));            
          this.spinnerService.deactivate();
          this._bhAlertService.openSnackBar({
            type: "success",
            message: CONSTANTMESSAGE.DELETE_COMPONENT,
            subtitle: "",
            autodismiss: true,
            duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
          });
        });
      });
  }
  iterateUsingFilter () {
    if (this.filterSelected === 'Global') {
      this.dataSource.data = this.dataModel.filter((elem) => {
        if (elem["tag"] === 'Global') {
          return elem;
        };
      });
    }
    if (this.filterSelected === 'Local') {
      this.dataSource.data = this.dataModel.filter((elem) => {
        if (elem["tag"] !== 'Global') {
          return elem;
        };
      });
    }
    //Local
    if (this.filterSelected == 'Clear') {
      this.filterSelected = null;
      this.dataSource.data = this.dataModel;

    }
  }

  filter(event) {
    let dialogRef = this.dialog.open(FilterComponentComponent, {
      width: '300px',
      data: {
        targetX: event.target.getBoundingClientRect().x - 10,
        targetY: event.target.getBoundingClientRect().y + event.target.getBoundingClientRect().height
      },
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      console.log("Filter ", res);
      if (res) {
        this.filterSelected = res['option'];
        this.searchText = '';
        this.iterateUsingFilter();
        
      }
    });
  }  
  checkPermissionForLocal (item) {
    let enableFlag = false;
    if(this.isSMERole){
      enableFlag = true;
    } else {
      let user= this.authConfigService.getUserId();
      if(item['tag'] === 'Global') {
        enableFlag = false;
      } else if (item['createdBy'] && item['createdBy'] !== user['uid']) {
        enableFlag = false;
      } else {
        enableFlag = true;
      }

    }
    return enableFlag;
  }

  toggleRowSelection($event, row) {
    if(row.tag !=='Global' && row.dataType !== 'SAVED BLEND'){
      if ($event) {
        this.selection.toggle(row);      
      }      
    }
    
  }

  cancelApproval(){
    this.selection.clear();
  }

  approveBySME(resolve?){   
    const componentIds = this.selection.selected.map(row => row.id);
    const componentNames = this.selection.selected.map(row => row.componentName);
    

    const title = 'Approve Components';
    const message = 'Below selected components will be changed to Global. Do you want to continue?';
    const dialogRef = this.dialog.open(ApprovalModalComponent, {
      disableClose: true,
      data: {
        title: title,
        content: message,
        components: componentNames,
        cancel: 'Cancel',
        save: 'Save',
        showSaveCancel: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'agree') {
        this.spinnerService.activate();
        let patchData =  { "op": "replace", "path": "/approved", "value": true }       
        this.componentsService.approveComponents(componentIds, patchData).subscribe((data: any[]) => {           
          this.selection.clear();
          this.componentsService.GetComponents().subscribe((data: any[]) => {            
            if(data && data['createdComponents']) {
              this.localComponents = data['createdComponents'];
            }
            let localOrgComponents;
            if(data && data['localCompoToOrgUsers']) {
              localOrgComponents = removedDuplicates(data['localCompoToOrgUsers'], this.localComponents);
              this.localComponents = [...this.localComponents,...localOrgComponents];
            }
              this.dataModel = [...data['data'],...data['createdComponents'], ...localOrgComponents];
              this.dataSource.data =  [...data['data'],...data['createdComponents'], ...localOrgComponents];
              this.iterateUsingFilter();
            this.searchText = '';
            this.spinnerService.deactivate();
          });

          this._bhAlertService.openSnackBar({
            type: "success",
            message: CONSTANTMESSAGE.LOCAL_COMPONENT_APPROVAL_SUCCESS_MESSAGE,
            subtitle: "",
            autodismiss: true,
            duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
         });
         if(resolve){
          resolve(true);
         }
        }); 
      }else{
        if(resolve){
          resolve(true);
         }
      }
    });  

  }  

  canDeactivateApprovals() {
    
    return new Promise<boolean>((resolve, reject) => {
      if (this.selection && this.selection.selected && this.selection.selected.length > 0) {
        
        let title = 'Leave Approvals';
        let message = 'Any unsaved changes will be lost.';         
      
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
          data: {
            title: title,
            content: message,
            showSaveCancel: true
          }
        });

        dialogRef.afterClosed().subscribe(result => {

          if(result === 'agree'){              
            this.approveBySME(resolve);                 
          } else if(result === 'continueWithoutSave'){
            this.selection.clear();
            resolve(true);
          }
        });
      }else{
        resolve(true);
      }
    });
  } 

}
