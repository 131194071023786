import { HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { NgModule, ErrorHandler } from '@angular/core';
import { SearchPipe } from './pipes/search.pipe';
import { GetInitialPipe} from './pipes/getInitials.pipe';

import { ThemeLibModule, BhHeaderLinks, NavService, BhModalService } from 'bh-theme';
import { SortByPipe } from './pipes/sort.pipe';

@NgModule({
  declarations: [
    SearchPipe,
    GetInitialPipe,
    SortByPipe
  ],
  imports: [
    ThemeLibModule,
    MatCardModule
  ],
  exports: [
    SearchPipe,GetInitialPipe,ThemeLibModule,MatCardModule,HttpClientModule,SortByPipe
  ],
  providers: [
    {
      provide: ErrorHandler
    },
  ]
})
export class SharedModule { }
