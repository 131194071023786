import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/app-config/app-config.service';

@Injectable({
    providedIn: 'root'
})
export class BlendsService {
    constructor(private httpClient: HttpClient,
        private appConfigService: AppConfigService) { }

    public GetCalculation(component) {
        return this.httpClient.post(this.appConfigService.getConfig().CALCULATE_API_SERVER + this.appConfigService.getConfig().GET_CALCULATIONS,
            component
        );
    }

    public downloadReportTemplate(projectId, projectName) {
        return this.httpClient.get(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS + "/" + projectId + "/reports", { responseType: 'blob' as 'blob', observe: 'response' })
            .subscribe(response => {
                // console.log(response)
                console.log(response.headers.keys())
                var contentDisposition = response.headers.get('content-disposition');
                // var filename = contentDisposition ? contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim() : 'report';
                // console.log(filename);
                var filename = 'BlendReport_'+projectName;
                this.downloadFile(response.body, filename) 
            });
    }

    public downloadTemplate(projectId, projectName) {
        return this.httpClient.get(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS + "/" + projectId + "/" + this.appConfigService.getConfig().GET_BLEND_TEMPLATES, { responseType: 'blob' as 'blob', observe: 'response' })
            .subscribe(response => {
                var filename = 'BlendsTemplate_'+projectName;
                this.downloadFile(response.body, filename) 
            });
    }

    downloadFile(data: any, filename: string) {
        const blob: any = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let link = document.createElement("a");
        if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename+'.xlsx');
            // link.setAttribute("download", filename ? filename :'blend.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
