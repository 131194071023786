import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lbm-filter-component',
  templateUrl: './filter-component.component.html',
  styleUrls: ['./filter-component.component.scss']
})
export class FilterComponentComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {},
    public dialogRef: MatDialogRef<FilterComponentComponent>
  ) {
    console.log("filterModal", data);
  }

  ngOnInit(): void {
  }
  onSelect(option) {
    console.log("option", option);
    this.data["option"] = option;
    this.dialogRef.close(this.data);
  }
}
