<div class="blend-btns">
    <button mat-button class="bh-table-action-button mat-button-primary-sm" [disabled]="checkSaveEnable() || !isLoggedUserasCreatedUser" (click)="saveBlends()">SAVE</button>
</div>

<div class="componentstab">
    <div class="cleft">
        <div class="bh-table">
            <div class="bh-table-header">
                <div class="bh-table-title mat-subtitle-1">Blends</div>
                <span class="fill-spacer"></span>
                <div class="bh-table-action-group">
                    <div class="lbm-search-holder">
                      <mat-icon class="wc-right" [ngClass]="{'disableIcon': !isLoggedUserasCreatedUser}">search</mat-icon>
                      <input type="text" matInput placeholder="Search" class="ple-left" (input)="searchTable($event)"  [attr.disabled]="!isLoggedUserasCreatedUser">
                    </div>
                    <div class="chart-menu-container" *ngIf="componentHeaders.length === 2 && isLoggedUserasCreatedUser"   [matMenuTriggerFor]="chartMenu">
                        <mat-icon>show_chart</mat-icon>
                        Charts
                        <mat-icon>arrow_drop_down</mat-icon>
                    </div>
                    <mat-menu #chartMenu="matMenu" xPosition="before">
                        <button class="cBtns" *ngIf="componentHeaders.length === 2" [disabled]="!showGraphConfiguration || !( showGraphConfiguration && dataModel?.length > 0)" mat-menu-item (click)="blendChangeFlag = true;clearGraphConfiguration()">Clear Binary Graph Configuration</button>
                        <button *ngIf="componentHeaders.length === 2" class="cBtns" [disabled]="( showGraphConfiguration && dataModel?.length > 0)" mat-menu-item (click)="switchToGraphConfiguration()">Create Binary Graph Configuration</button>                    
                    </mat-menu>
                    <mat-icon matTooltip="Components" class="bh-table-action-button visibility" [ngClass]="{'disableIcon': !isLoggedUserasCreatedUser}" *ngIf="showComponents"
                        (click)=toggleComponents()>
                        visibility</mat-icon>
                    <mat-icon matTooltip="Components" class="bh-table-action-button visibility" [ngClass]="{'disableIcon': !isLoggedUserasCreatedUser}" *ngIf="!showComponents"
                        (click)=toggleComponents()>
                        visibility_off</mat-icon>
                    <span class="context-text">Components</span>
                    <mat-icon matTooltip="Output" class="bh-table-action-button visibility" [ngClass]="{'disableIcon': !isLoggedUserasCreatedUser}" *ngIf="showOutput"
                        (click)=toggleOutput()>
                        visibility</mat-icon>
                    <mat-icon matTooltip="Output" class="bh-table-action-button visibility" [ngClass]="{'disableIcon': !isLoggedUserasCreatedUser}" *ngIf="!showOutput"
                        (click)=toggleOutput()>
                        visibility_off</mat-icon>
                    <span class="context-text">Output</span>
                    <input style="display: none;" type="file" #fileInput (change)="onFileChange($event)" multiple="false" accept=".xls, .xlsx, .csv" />
                    <mat-menu #appMenu="matMenu" xPosition="before">
                        <button class="cBtns" mat-menu-item (click)="downloadComponents()">Download Blend Template</button>
                        <button class="cBtns" mat-menu-item (click)="importComponents()">Import Blends</button>              
                        <button class="cBtns" mat-menu-item (click)="deleteAllBlends()">Delete All Blends</button>                 
                    </mat-menu>
                    <mat-icon  class="bh-table-action-button" [ngClass]="{'disableIcon': !isLoggedUserasCreatedUser  || (componentHeaders.length < 2) || (showGraphConfiguration && dataModel?.length > 0)}" matTooltip="More Actions" [matMenuTriggerFor]="appMenu">
                        more_vert
                    </mat-icon>
                    <button mat-button class="bh-table-action-button mat-button-primary-sm" [disabled]="!isLoggedUserasCreatedUser || ( showGraphConfiguration && dataModel?.length > 0)|| (componentHeaders.length < 2) || (dataSource.data.length === 150)" (click)="addNewBlend()">Add
                        Blend</button>
                    <button mat-button class="bh-table-action-button mat-button-primary-sm" [disabled]="!isLoggedUserasCreatedUser || checkComponentsVal()"
                        (click)="calculate()">Calculate</button>
                </div>
            </div>
            <div class="parent-table">
                <table mat-table matSort matSortActive="description" [dataSource]="dataSource"
                    class="mat-elevation-z8" style="width: 100%; flex: 1;">

                    <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->
                    <!-- Percentage total Column -->
                    <ng-container matColumnDef="total" sticky class="mat-column-make-sticky">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 120px;"></th>
                        <td mat-cell *matCellDef="let element" style="width: 120px;">
                            
                            <p  class="{{getTotalColor(element.total)}}"> 
                                <mat-icon *ngIf="element.total >= 98 && element.total <= 102" style="color: #02a783">check_circle_outline</mat-icon>                          
                                <mat-icon *ngIf="(element.total >= 96 && element.total < 98) || (element.total > 102 && element.total <= 104) || (element.total >= 90 && element.total < 96) || (element.total > 104 && element.total <= 110) "  matTooltip="Preferred Range for Total Blend Percentage is between 98% to 102%.
                                Modify Fractions or Proceed to Calculate." matTooltipClass="tooltipStyle" matTooltipPosition="above">error_outline</mat-icon>
                                <mat-icon *ngIf="element.total < 90 " matTooltip="Total Blend Percentage should be 90% or above for performing Calculation"  matTooltipClass="tooltipStyle" matTooltipPosition="above">error_outline</mat-icon>
                                <mat-icon *ngIf="element.total > 110 " matTooltip="Total Blend Percentage should be less than 110% for performing Calculation"  matTooltipClass="tooltipStyle" matTooltipPosition="above">error_outline</mat-icon>
                                <span class="total-style">
                                {{getTotal(element.total)}} %
                                </span>
                            </p>                           
                            
                        </td>
                    </ng-container>

                    <!-- Blend Description Column -->
                    <ng-container matColumnDef="Blend Description" sticky class="mat-column-make-sticky">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width:145px; border-right: 1px solid #ced7d4;"> Blend Description
                        </th>
                        <td mat-cell *matCellDef="let element; index as i" class="blend-description-content" [ngClass]="{'disableMode': !isLoggedUserasCreatedUser || showGraphConfiguration  }"  (click)="editCell('Blend Description',i)">
                            <mat-form-field floatLabel="never" [ngClass]="editingCell !== ('Blend Description'+ i) ? 'hideInputLine' : ''">
                                <input class="no-border" matInput name="Blend Description{{$i}}" (keydown)="checkFieldModify($event)"
                                    [(ngModel)]="element['Blend Description']" required>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <!-- Component Columns -->
                    <ng-container *ngFor="let key of componentHeaders" matColumnDef="{{key.listName}}">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:115px;padding-left: 5px;" matTooltip="{{key.listName}}" [matTooltipPosition]="showTooltipPosition" matTooltipClass="tooltipStyle"> {{key.componentName}} </th>
                        <td mat-cell *matCellDef="let element; index as i" [ngClass]="{'disableMode': !isLoggedUserasCreatedUser ||  showGraphConfiguration}" style="width:115px;padding: 5px;" (click)="editCell(key.listName , i)">
                            <mat-form-field floatLabel="never" [ngClass]="editingCell !== (key.listName + i) ? 'hideInputLine' : ''">
                                <input type="number" step='any' matInput name="{{key.listName + $i}}"
                                    [(ngModel)]="element[key.listName]" [min]="0" (keydown)="checkMaxLength($event,element,key.listName,i)" (keyup)="checkMaxLength($event,element,key.listName,i)"
                                    (focus)="onElementFocus($event)" (blur)="checkEmpty($event,element,key.listName)" required>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <!-- ASI Column -->
                    <ng-container matColumnDef="ASI" [stickyEnd]="isStickyEndColumn('ASI')">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 50px;width: 50px;border-left: 1px solid #ced7d4;padding-left: 5px;">ASI </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{'greyOutCell':  element['isBlendValueChanged'] || checkBlendPercentage(element) || outOfDateFlag}" style="min-width: 50px;width: 50px;text-align:center;border-left: 1px solid #ced7d4;padding-left: 5px;">{{element.ASI | number:'1.0-0'}}</td>
                    </ng-container>


                    <!-- Asphaltenes Column -->
                    <ng-container matColumnDef="Blend Asphaltene%" [stickyEnd]="isStickyEndColumn('Blend Asphaltene%')">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 90px;width: 90px;">Asphaltene% </th>
                        <td mat-cell *matCellDef="let element"  [ngClass]="{'greyOutCell':  element['isBlendValueChanged'] || checkBlendPercentage(element) || outOfDateFlag}" style="min-width: 90px;width: 90px;text-align: center;"> {{element['Blend Asphaltene%']}} </td>
                    </ng-container>

                    <!-- LSCI Column -->
                    <ng-container matColumnDef="LSCI" [stickyEnd]="isStickyEndColumn('LSCI')">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:100px;min-width: 100px;" matTooltip="LIFESPAN Stability Concern Index" [matTooltipPosition]="'above'"> LSCI </th>
                        <td mat-cell *matCellDef="let element"  [ngClass]="{'greyOutCell':  element['isBlendValueChanged'] || checkBlendPercentage(element) || outOfDateFlag}" style="width:100px;min-width: 100px;"> 
                            <p class="{{getLSCI(element.LSCI)}}">{{element.LSCI | number:'1.1-1'}} </p>
                        </td>
                    </ng-container>

                    <!-- Min NFIT Column -->
                    <ng-container matColumnDef="minNFIT" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:70px;min-width: 70px;"> Min NFIT <br> deg/day </th>
                        <td mat-cell *matCellDef="let element"  [ngClass]="{'greyOutCell': element['isBlendValueChanged'] || checkBlendPercentage(element) || outOfDateFlag}" style="width:70px;min-width: 70px;text-align: center;"> {{element['minNFIT']}}</td>
                    </ng-container>
                    
                     <!-- Max NFIT Column -->
                     <ng-container matColumnDef="maxNFIT" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:70px;min-width: 70px;"> Max NFIT <br> deg/day </th>
                        <td mat-cell *matCellDef="let element"  [ngClass]="{'greyOutCell': element['isBlendValueChanged'] || checkBlendPercentage(element) || outOfDateFlag}" style="width:70px;min-width: 70px;text-align: center;">{{element['maxNFIT']}} </td>
                    </ng-container>

                     <!--blend SBN  Column -->
                     <ng-container matColumnDef="blendSBn" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:70px;min-width: 70px;"> Blend SBN </th>
                        <td mat-cell *matCellDef="let element"  [ngClass]="{'greyOutCell':  element['isBlendValueChanged'] || checkBlendPercentage(element) || outOfDateFlag}" style="width:70px;min-width: 70px;text-align: center;"> {{element['blendSBn']}}</td>
                    </ng-container>

                     <!-- blendIN Column -->
                     <ng-container matColumnDef="blendIn" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:60px;min-width: 60px;"> Blend IN </th>
                        <td mat-cell *matCellDef="let element"  [ngClass]="{'greyOutCell': element['isBlendValueChanged'] || checkBlendPercentage(element) || outOfDateFlag}" style="width:60px;min-width: 60px;text-align: center;"> {{element['blendIn']}}</td>
                    </ng-container>

                     <!-- fuelOil Column -->
                     <ng-container matColumnDef="fuelOil_LSCI_Factor" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:70px;min-width: 70px;"> Fuel Oil <br> LSCI Factor </th>
                        <td mat-cell *matCellDef="let element"  [ngClass]="{'greyOutCell': element['isBlendValueChanged'] ||  checkBlendPercentage(element) || outOfDateFlag}" style="width:70px;min-width: 70px;text-align: center;"> {{element['fuelOil_LSCI_Factor']}}</td>
                    </ng-container>

                    <!-- Max NFIT Column -->
                    <!-- <ng-container matColumnDef="maxNFIT" [stickyEnd]="isStickyEndColumn('maxNFIT')">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Max NFIT <br>deg/day </th>
                        <td mat-cell *matCellDef="let element"> {{element['maxNFIT']}} </td>
                    </ng-container> -->

                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions" [stickyEnd]="isStickyEndColumn('actions')">
                        <th mat-header-cell *matHeaderCellDef style="width: 20px;"></th>
                        <td mat-cell *matCellDef="let element" style="text-align:center; width: 20px;">
                            <mat-menu #rowMenu="matMenu" xPosition="before">
                                <button class= "cBtns" mat-menu-item [ngClass]="{'disableOption': !isLoggedUserasCreatedUser}" (click)="deleteBlend(element)" >Delete blend</button>
                                <button class="cBtns" mat-menu-item [ngClass]="{'disableOption': !isLoggedUserasCreatedUser || element['isBlendValueChanged'] || checkBlendPercentage(element) || outOfDateFlag}"
                                   (click)="createBlendAsComponent(element)">Save as a Component</button> 
                            </mat-menu>
                            <mat-icon  class="bh-table-action-button" matTooltip="Actions" [matMenuTriggerFor]="rowMenu">
                                more_vert
                            </mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 36px;"></tr>
                </table>

            </div>

        </div>

        <mat-paginator [class.pagination-hidden]="dataModel.length === 0" [pageSizeOptions]="[5, 10, 15]" [pageSize]="5"
            showFirstLastButtons></mat-paginator>
        <div class="noData" *ngIf="dataModel.length === 0" class="lbm-illustration-container">
            <bh-illustrations [illustrationData]="emptyState"></bh-illustrations>
        </div>
    </div>

</div>