import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lbm-delete-blends',
  templateUrl: './delete-blends.component.html',
  styleUrls: ['./delete-blends.component.scss']
})
export class DeleteBlendsComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DeleteBlendsComponent>
  ) {
    console.log("modal", data);
  }

  ngOnInit(): void {
  }

  delete(isToBeDeleted) {
    this.data['delete'] = isToBeDeleted;
    this.dialogRef.close(this.data);
  }

}
