export const OAuthModuleConfigValue = {
  resourceServer : {
    allowedUrls : [],
    sendAccessToken: true
  }
}

export class OAuthModuleConfigVal {
  resourceServer: OAuthResourceServerConfig = {
    allowedUrls:[],
    sendAccessToken: true
  };
}

export class OAuthResourceServerConfig {
  allowedUrls?: Array<string>;
  sendAccessToken = true;
  customUrlValidation?: (url: string) => boolean;
}