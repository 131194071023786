<div class="bh-table user-modal" style="padding: 15px 20px 15px" *ngIf="!isOpenedEdiMembersModal">
    <div class="lbm-project-details-header">
        <h2 class="title-project">{{data?.data?.name}} Members</h2>
        <div><span>{{data?.data?.customer.name}}</span> | <span>{{data?.data?.refinery.name}}</span></div>
    </div>
    <div *ngIf="users" class="user-container">
        <ng-container *ngFor="let user of users; index as i">
            <div style="flex:0 200px">
                <div class="lbm-user-container">
                    <div class="lbm-person"  [ngStyle]="{'background-color': getColors(i) }">{{user.name | getinitials}}</div>
                    <div class="lbm-fullname">{{user.name}}</div>
                </div>
            </div>
        </ng-container>
    </div>
    <mat-dialog-actions align="end">
        <button mat-button *ngIf="loggedUserId === data?.data?.createdBy" class="bh-table-action-button mat-button-primary-sm" (click)="editMembers()">Edit Members</button>
        <button mat-button class="mat-button-sm cancel_btn" [mat-dialog-close]="true" cdkFocusInitial>Exit</button>
    </mat-dialog-actions>
</div>
    