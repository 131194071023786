import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lbm-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data,
  public dialogRef: MatDialogRef<DeleteUserComponent>) { }

  ngOnInit(): void {
  }


  delete(isToBeDeleted) {
    this.data['delete'] = isToBeDeleted;
    this.dialogRef.close(this.data);
  }
}
