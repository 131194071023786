import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ComponentsService } from 'src/app/services/components/components.service';

@Component({
  selector: 'lbm-select-component-type',
  templateUrl: './select-component-type.component.html',
  styleUrls: ['./select-component-type.component.scss']
})
export class SelectComponentTypeComponent implements OnInit {
  title = 'Add New Component';
  measurementType: string;
  dataType: string[] = [ 'Sample', 'Assay'];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {},
    public dialogRef: MatDialogRef<SelectComponentTypeComponent>,
    public formBuilder: FormBuilder,
    private componentsService: ComponentsService
  ) {
    console.log("modal", data);

  }

  ngOnInit(): void {

  }

  onSubmit() {
    this.dialogRef.close(this.measurementType);
  }
 

}
