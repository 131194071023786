<div class="dialog-container select-component-type">
    <div class="title">
        <h4 mat-dialog-title>{{title}}</h4>
    </div>

    <div>
            <div class="dialog-contents" mat-dialog-content>
                <label id="example-radio-group-label">Select Data Type</label>
                <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="measurementType">
                <mat-radio-button class="example-radio-button" *ngFor="let type of dataType" [value]="type">
                    {{type}}
                </mat-radio-button>
                </mat-radio-group>
            <div>
                <div mat-dialog-actions align="end">
                    <button mat-button class="mat-button-sm cancel_btn" [mat-dialog-close]=null>Cancel</button>
                    <button mat-button class="mat-button-primary-sm" [disabled]="!measurementType"
                        (click)="onSubmit()">Next</button>
                </div>
            </div>
        </div>

    </div>

</div>