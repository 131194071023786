import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { UsersComponent } from "../users/users.component";
import { ProjectService } from "src/app/services/project/project.service";


@Component({
  selector: "app-user-list-modal",
  templateUrl: "./user-list-modal.component.html",
  styleUrls: ["./user-list-modal.component.scss"],
})


export class UserListModalComponent implements OnInit {
  users = [];
  Colors: any[] = ["#9400d3", "#ff1493", "#0b9660", "#FF0000", "#fb9667"];
  isOpenedEdiMembersModal:boolean = false;
  loggedUserId;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserListModalComponent>,
    private dialog: MatDialog,
    private projectService: ProjectService
  ) {
    this.loggedUserId = sessionStorage.getItem('loggedUserId');
    console.log("UserListModalData: ", data);
  }

  ngOnInit(): void {
    this.users = this.data?.data?.sharedUsers ? JSON.parse(JSON.stringify(this.data.data.sharedUsers)) : [];
    if(this.data?.createdUser?.createdBy) {
      this.users.push({name: this.data?.createdUser?.createdByName, id: this.data?.createdUser?.createdBy});
    }
  }

  ngAfterViewInit() { }

  editMembers() {
    this.isOpenedEdiMembersModal = true;
    let dialogRef = this.dialog.open(UsersComponent, {
      width: "80vw",
      height: 'auto',      
      data: this.data
    });

    dialogRef.afterClosed().subscribe((res) => {
      // this.getAllProjects();
      this.isOpenedEdiMembersModal = false;
      this.projectService.getProjectById(this.data.data.id).subscribe((data: any) => {
        if (data) {
          console.log(data);
          this.data.data = data;
          this.users = data?.sharedUsers ? JSON.parse(JSON.stringify(this.data.data.sharedUsers)) : [];
          if(this.data?.createdUser?.createdBy) {
            this.users.push({name: this.data?.createdUser?.createdByName, id: this.data?.createdUser?.createdBy});
          }
        }
      })
    });
  }

  getColors(index) {
    let num = 0;
    if (index != 0) {
      num = this.getnumber(index)
    } else {
      return '##00FF00'
    }
    return this.Colors[num];
  }

  getnumber(data) {
    let i = data;
    if (i > this.Colors.length - 1) {
      i = i - this.Colors.length;
      if (i < this.Colors.length) {
        return i;
      }
      else {
        this.getnumber(i);
      }
    }
    else {
      return i;
    }
  }

}
