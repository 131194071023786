
import { AbstractControl, NgControlStatus, ValidatorFn, Validators } from "@angular/forms";

export class CustomValidators extends Validators {

   static NoEmptySpaceValidator():ValidatorFn{
    return (control:AbstractControl)=>{
      const value = control.value.trim();
      return (value.length==0 && control.value.length>0)?{"emptySpace":true}:null;
    }
  }
}