<div class="error_toast_msg" *ngIf="selection.selected.length === 1 || selection.selected.length > 30">
  <div class="toast_details">
      <span><mat-icon>fmd_bad</mat-icon></span>
      <span class="toast_msg" *ngIf="selection.selected.length === 1">Select minimum 2 components</span>
      <span class="toast_msg" *ngIf="selection.selected.length > 30">You have reached maximum limit for adding components</span>
  </div>
</div>

<div class="bh-table" [class.selectListHidden]="isCreateCompClicked">
  <div class="bh-table-header">
    <div class="bh-table-title mat-subtitle-1">Select Components</div>
    <span class="fill-spacer"></span>
    <div class="bh-table-action-group">
      <!-- <mat-icon matTooltip="Filter List" class="bh-table-action-button">filter_list</mat-icon>
      <mat-icon matTooltip="More Action" class="bh-table-action-button">more_vert</mat-icon> -->
      <span class="selectedComp" *ngIf="selection.selected.length >= 1"> {{selection.selected.length}} Components
        Selected</span>
      <div class="lbm-search-holder">
        <mat-icon class="wc-right">search</mat-icon>
        <input type="text" matInput placeholder="Search" class="ple-left" [(ngModel)]="searchText" (input)="searchTable($event)">
      </div>
      <button mat-stroked-button class="bh-table-action-button" mat-dialog-close>Cancel</button>
      <button mat-button class="bh-table-action-button mat-button-primary-sm"
        [disabled]="selection.selected.length <= 1 || selection.selected.length > 30 || !selectionChanged" (click)="saveConfirm()">Save</button>
      <button mat-button class="bh-table-action-button mat-button-primary-sm" (click)="selectComponentType()">ADD NEW COMPONENT</button>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="lbm-spinner"  *ngIf="activeSpinner" align="center">
      <mat-spinner></mat-spinner>
    </div>
    <div class="parent-table">
      <table mat-table matSort  [dataSource]="dataSource" [ngClass]="isSMERole ? 'table-class-sme' : 'table-class'" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
  
        <ng-container matColumnDef="tag">
          <th mat-header-cell *matHeaderCellDef style="width: 40px;"></th>
          <td mat-cell *matCellDef="let element" style="width: 40px; padding-top: 4px; padding-bottom: 4px;">
            <mat-icon *ngIf="element.tag === 'Global'" style="color: #02a783">public</mat-icon>            
            <mat-icon *ngIf="element.tag !=='Global' && element.dataType == 'SAVED BLEND'" style="color: #bdbdbd">public_off</mat-icon>
            <mat-icon *ngIf="element.tag !=='Global' && element.dataType !== 'SAVED BLEND'" style="color: #bdbdbd">public</mat-icon> 
          </td>
        </ng-container>
  
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef style="width: 40px;">
            <!-- <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox> -->
          </th>
          <td mat-cell *matCellDef="let row" style="width: 40px; padding-top: 4px; padding-bottom: 4px;">
            <mat-checkbox color="primary" (click)="$event.stopPropagation()"
              (change)="toggleRowSelection($event, row)" [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- namen Column -->
        <ng-container matColumnDef="componentName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="width-col" [ngClass]="isSMERole ? 'col-name-min-width' : ''" > Component Name </th>
          <td mat-cell *matCellDef="let element" style=" padding-top: 4px; padding-bottom: 4px;"> {{element.componentName}} </td>
        </ng-container>
  
        <!-- Date Column -->
        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="width-col" > Date Added </th>
          <td mat-cell *matCellDef="let element"  style="padding-top: 4px; padding-bottom: 4px;"> {{element.createdOn | date}} </td>
        </ng-container>
  
        <!-- Data Type  -->
        <ng-container matColumnDef="dataType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header  class="width-col"  > Data Type </th>
            <td mat-cell *matCellDef="let element"   style="padding-top: 4px; padding-bottom: 4px;"> {{element.dataType}} </td>
        </ng-container>
  
        <!-- asi Column -->
        <ng-container matColumnDef="ASI">
          <th mat-header-cell *matHeaderCellDef mat-sort-header  class="width-col" > ASI </th>
          <td mat-cell *matCellDef="let element"   style="padding-top: 4px; padding-bottom: 4px;"> 
            <ng-container *ngIf="element.dataType !== 'ASSAY'">{{element.ASI | number:'1.0-0'}}</ng-container>  
            <ng-container *ngIf="element.dataType === 'ASSAY'"> {{element.ASI | number:'1.0-0'}}</ng-container>  
          </td>
        </ng-container>      
  
        <!-- Asphaltenes % Column -->
        <ng-container matColumnDef="asphaltenePercentage">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="width-col" > Asphaltenes % </th>
          <td mat-cell *matCellDef="let element"   style="padding-top: 4px; padding-bottom: 4px;"> {{element.asphaltenePercentage}} </td>
        </ng-container>
  
        <!-- Component Type -->
        <ng-container matColumnDef="componentType">
          <th  mat-header-cell *matHeaderCellDef mat-sort-header class="width-col" > Component Type</th>
          <td mat-cell *matCellDef="let element">
             <ng-container *ngIf="element['componentType'] === 'Y'">Fuel Oil</ng-container>
             <ng-container *ngIf="element['componentType'] === 'D'">Diluent</ng-container>
             <ng-container *ngIf="element['componentType'] === 'N'">Crude Oil</ng-container>
          </td>
      </ng-container>
  
      <ng-container *ngIf="isSMERole" matColumnDef="nFactor">
        <th  mat-header-cell *matHeaderCellDef mat-sort-header class="width-col" > n-Factor </th>
        <td mat-cell *matCellDef="let element"> {{element.nFactor | number:'1.0-3'}} </td>
    </ng-container>
  
    <ng-container *ngIf="isSMERole" matColumnDef="nTemp">
        <th  mat-header-cell *matHeaderCellDef mat-sort-header class="width-col" > n-Temp </th>
        <td mat-cell *matCellDef="let element"> {{element.nTemp | number:'1.0-3'}} </td>
    </ng-container>
  
    <ng-container *ngIf="isSMERole" matColumnDef="correctedNFactor">
      <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> Corrected n-Factor</th>
      <td mat-cell *matCellDef="let element"> {{element.correctedNFactor | number:'1.0-3'}} </td>
    </ng-container>
  
    <ng-container *ngIf="isSMERole" matColumnDef="SBn">
        <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> SBn </th>
        <td mat-cell *matCellDef="let element"> {{element['SBn'] | number:'1.0-3'}} </td>
    </ng-container>
  
    <ng-container *ngIf="isSMERole" matColumnDef="In">
        <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> In </th>
        <td mat-cell *matCellDef="let element"> {{element['In'] | number:'1.0-3'}} </td>
    </ng-container>
  
    <ng-container *ngIf="isSMERole" matColumnDef="fuelOilLSCIFactor">
        <th class="width-col"  mat-header-cell *matHeaderCellDef mat-sort-header> FuelOil LSCI Factor </th>
        <td mat-cell *matCellDef="let element"> {{element['fuelOilLSCIFactor'] | number:'1.0-3'}} </td>
    </ng-container>
  
    <ng-container matColumnDef="ASI_Method">
      <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header style="width:140px;white-space: pre-line;">ASI Method </th>
      <td mat-cell *matCellDef="let element"> {{element.ASI_Method}} </td>
  </ng-container>
  
    <ng-container *ngIf="isSMERole" matColumnDef="notes">
        <th  mat-header-cell *matHeaderCellDef mat-sort-header class="width-col-notes"> Notes </th>
        <td mat-cell class="showPointer text-truncate" matTooltip="{{element['notes']}}" *matCellDef="let element"> {{element['notes']}} </td>
    </ng-container>
  
    <ng-container *ngIf="isSMERole" matColumnDef="createdBy">
        <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> Added By </th>
        <td mat-cell *matCellDef="let element"> {{element['createdBy']}} </td>
    </ng-container>

    <ng-container *ngIf="isSMERole" matColumnDef="approvedBy">
      <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> Approved By </th>
      <td mat-cell *matCellDef="let element"> {{element['approvedBy']}} </td>
    </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 36px;"></tr>
        <!-- <tr mat-footer-row *matFooterRowDef="['paginator']; sticky: true"></tr> -->
      </table>
        
        <!-- Pagination Row (full width column) -->
        <!-- <ng-container matColumnDef="paginator">
          <th mat-footer-cell *matFooterCellDef [colSpan]="displayedColumns.length"> -->
            <mat-paginator [pageSizeOptions]="[5, 10, 15]" [pageSize]="10"  showFirstLastButtons></mat-paginator>
          <!-- </th>
        </ng-container> -->
  
    </div>

  </mat-dialog-content>
</div>
