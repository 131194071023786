import { NgModule, Provider } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";

import { LibraryComponent } from "../components/components.component";
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { ConfirmAwayGuard } from 'src/app/shared/guards/confirm-away.guard';
import {GridComponentComponent} from '../components/grid-component/grid-component.component'

const mainRoutes: Routes = [
  {
    path: "",
    component: LibraryComponent,
    canActivate : [AuthGuard]
  },
  {
    path: "list",
    component: GridComponentComponent, 
    canDeactivate: ['canDeactivateApprovals'],
    canActivate : [AuthGuard]
  }
];
const providers: Provider[] = [
  {
    provide: 'canDeactivateApprovals',
    useValue: (component: GridComponentComponent) => {
      return component.canDeactivateApprovals();
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(mainRoutes)],
  exports: [RouterModule],
  providers: providers
})
export class ComponentsRoutingModule {}
