<div>
    <div class="lbm-spinner" *ngIf="activeSpinner" align="center">
        <mat-spinner></mat-spinner>
    </div>
    <div class="lbm-projects-header lbm-align-sides">
        <h3 class="title">Projects ({{projects ? projects.length : '0'}})</h3>
        <div class="lbm-header-right">
            <div class="lbm-search-holder">
                <mat-icon class="wc-right">search</mat-icon>

                <input type="text" matInput placeholder="Search" class="ple-left" name="search" [(ngModel)]="searchText"
                    (ngModelChange)="search($event)">
            </div>
            <button mat-button class="mat-button-tertiary" #filterButton (click)="filter($event)">
                <mat-icon class="mat-icon-left mat-icon--sml">filter_list</mat-icon> Filter
            </button>
            <button mat-button class="mat-button-primary project-btn" (click)="createProject(null)">New Project</button>
        </div>
    </div>
    <div *ngIf="!activeSpinner && originalList.length === 0" class="lbm-illustration-container">
        <bh-illustrations [illustrationData]="emptyState"></bh-illustrations>
    </div>
    <div class="parent-container">
        <ng-container *ngFor="let projectItem of projects; index as i;">
            <div class="lbm-projects-list">
                <mat-card class="project-card-title"  (click)="goToDetailPage(projectItem.id)">
                    <h6 class="title-project"   matTooltip="{{projectItem.name}}" [matTooltipPosition]="showTooltipPosition">{{projectItem.name}}</h6>
                     <div class="created-div">
                        <p class="created-name">Created by {{projectItem.createdByName}}</p>
                        <p>Created on {{projectItem.createdDate | date}}</p>
                        <p *ngIf="projectItem.updatedDate">Updated on {{projectItem.updatedDate| date}}</p>
                    </div>
                    <div class="lbm-align-sides lbm-image-container">
                        <div class="image-grid">
                            <div class="lbm-person">
                                <span *ngIf="projectItem.createdByName">
                                    {{projectItem.createdByName | getinitials}}
                                </span>
                                <span class="user-name">{{projectItem.createdByName}}</span>
                            </div>
                            <ng-container *ngFor="let user of projectItem.sharedUsers; index as u">
                                <div class="lbm-person" [ngClass]="(u===0)?'darkviolet':'deeppink'" *ngIf="u < 2">
                                    <span>{{user.name | getinitials}}</span>
                                    <span class="user-name">{{user.name}}</span>
                                </div>
                            </ng-container>
                            <div *ngIf="projectItem.sharedUsers && projectItem.sharedUsers.length > 2" class="more-users" (click)="$event.stopPropagation();seeMoreUsers(projectItem)">
                                <span> +{{projectItem.sharedUsers.length -2}}</span>
                            </div>
                        </div>
                        <mat-icon class="mat-icon-left mat-icon--sml"
                            (click)="$event.stopPropagation();onOptionsClicked($event, projectItem,i)">
                            more_horiz
                        </mat-icon>
                    </div>
                </mat-card>
            </div>
        </ng-container>
    </div>
</div>