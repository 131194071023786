import { SharedModule } from './../../shared/shared.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatNativeDateModule } from '@angular/material/core';
import {
  ThemeLibModule,
  BhHeaderLinks,
  NavService,
  BhModalService,
} from 'bh-theme';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from './../../shared/services/api-interceptor';
import { MatSidenavModule } from '@angular/material/sidenav';

import { ComponentsRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { AdminListComponent } from './list-admin/list-admin.component';
import { FilterAdminComponent } from './filter-admin/filter-admin.component';
import { AddUserComponent } from './add-user/add-user.component';
import { DeleteUserComponent } from './delete-user/delete-user.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ComponentsRoutingModule,
    ThemeLibModule,
    MatCardModule,
    MatTabsModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
  ],
  declarations: [
    AdminComponent,
    AdminListComponent,
    FilterAdminComponent,
    AddUserComponent,
    DeleteUserComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  ],
  exports: []
})
export class AdminModule { }
