import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Component, OnInit, Inject, ViewChild, ViewChildren, QueryList, ElementRef, AfterViewInit, } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTabGroup } from "@angular/material/tabs";
import { AdminService } from "src/app/services/admin/admin.service";
import { ProjectService } from "src/app/services/project/project.service";
import { IllustrationData, IllustrationNames,BhAlertService } from "bh-theme";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { AppConfigService } from "src/app/app-config/app-config.service";
import { forkJoin, merge } from "rxjs";
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { UserService } from "src/app/services/userservice/user.service";
import {fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { CONSTANTMESSAGE } from "src/app/shared/constants/constants-messages";


@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})


export class UsersComponent implements OnInit {
  roleArray = ['MyID_1278117_Dev_OIC Lifespan Blend_InternalUsers','MyID_1278117_Dev_OIC Lifespan Blend_Admins','MyID_1278117_Dev_OIC Lifespan Blend_SME','B2B_1278117_Dev_OIC Lifespan Blend_ExternalUsers'];
  shareMembersDisplayColumns: string[] = ['name','org', 'action'];
  supportTeamDisplayedColumns: string[] = ['select', 'user', 'company', 'role'];
  selection = new SelectionModel<any>(true, []);
  shareMembersDataSource = new MatTableDataSource<any>([]);
  dataModel = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  
  selectedIds = [];
  initialSelectedIds: number[] = [];
  selectionChanged = false;
  searchedUsers = [];
  selectedUser = [];
  searchText = '';
  textLimitReached = false;
  
  userForm: FormGroup;

  externalUsers = [];
  internalUsers = [];
  emptyState: IllustrationData = {
    illustrationName: IllustrationNames.EmptyBox,
    title: 'Shared Users Not available',
    desc: ''
  }
  
  emptysupportTeamState: IllustrationData = {
    illustrationName: IllustrationNames.EmptyBox,
    title: 'BH Support Team Not available',
    desc: ''
  }
  activeSpinner: boolean = false;
  @ViewChild('inputSearchOktaUser') inputSearchOktaUser: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UsersComponent>,
    private dialog: MatDialog,
    private adminService: AdminService,
    private projectService: ProjectService,
    private spinnerService : SpinnerService,
    private appConfigService: AppConfigService,
    private userService: UserService,
    private _bhAlertService: BhAlertService,
  ) {
    console.log("UserData: ", data);
    this.spinnerService.spinnerActive.subscribe(active => 
      this.toggleSpinner(active)); 
  }

  ngOnInit(): void {
    let sharedUsers = this.data?.data?.sharedUsers ? this.data.data.sharedUsers : [];
    let projectOwnerId = this.data.data.createdBy;
    console.log(sharedUsers);
    let idListString = "";
    sharedUsers.map((res, i)=> {
      idListString = idListString + res.id;
      if(i !== (sharedUsers.length -1)) {
        idListString = idListString + ',';
      }
    });
    if(projectOwnerId){
      idListString = idListString+ ','+ projectOwnerId;
    }
    this.getUserDetails(idListString);   
  }

  toggleSpinner(active){
    this.activeSpinner = active
  }


  ngAfterViewInit() {
    this.shareMembersDataSource.paginator = this.paginator;
    this.shareMembersDataSource.sort = this.sort;
    this.shareMembersDataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      return  data[sortHeaderId].toString().trim().toLocaleLowerCase();
    };
    // server-side search
    fromEvent(this.inputSearchOktaUser.nativeElement,'input')
        .pipe(
            filter(Boolean),
            debounceTime(600),
            distinctUntilChanged(),
            tap((text) => {                           
              let filter = this.inputSearchOktaUser.nativeElement.value;
              if(filter != null && filter.length > 2){
                this.textLimitReached = true;
                let org = this.data.data.customer.name;              
                this.searchUser(filter,org)
            }else{
              this.textLimitReached = false;;
              this.searchedUsers = [];
            }
                
            })
        )
        .subscribe();
}

searchUser(filter,org){

  this.userService.getUserSearch(filter,org).subscribe(res=>  { 
    console.log(res)
    if(res && res['data'] ){
      let data = res['data'];              
      let userArray  = Object.values(data);
      this.searchedUsers = userArray;
    }else{
      this.searchedUsers = [];
     }
  });
}
selectUser(user){    
  this.selectedUser = [] ;
  this.selectedUser.push(user);
  let org = this.data.data.customer.name; 
  let filter = user.name ;             
  this.searchUser(filter,org)
  
}

addMemberToGrid(){
  this.searchText = '';
  let idToBeAdded = this.selectedUser.map(row => row.id)[0];
  let userAlreadyAdded = this.dataModel.filter(elem => elem.id === idToBeAdded)[0];  
  if(userAlreadyAdded == null || userAlreadyAdded.length === 0){
   this.shareMembersDataSource.data = [...this.dataModel , ...this.selectedUser];
   this.dataModel = [...this.dataModel , ...this.selectedUser];
   this.shareMembersDataSource.paginator = this.paginator;  
   this.shareMembersDataSource.sort = this.sort; 
    this.shareMembersDataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
    return  data[sortHeaderId].toString().trim().toLocaleLowerCase();
    };
  this.selectionChanged = true;
  }else{
    this._bhAlertService.openSnackBar({
      type: "success",
      message: CONSTANTMESSAGE.SHARE_MEMBER_ADDED_ALREADY,
      subtitle: "",
      autodismiss: true,
      duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
    });
  }
  
}


  searchTable() {
        
    if (this.searchText.trim().length > 0) {
      this.shareMembersDataSource.data = this.dataModel.filter((elem) => {        
        return (elem['name']).toUpperCase().includes(this.searchText.trim().toUpperCase());
      });
    } else {
      this.shareMembersDataSource.data = this.dataModel;
    }
  
    
  }

  save() {
    this.spinnerService.activate();
    let selectedIds = this.dataModel.map(row => row.id);
    selectedIds = selectedIds.filter(id => id !== this.data.createdUser.createdBy);
    this.projectService.addSharedUsersToProject(this.data.data.id, selectedIds).subscribe((res) => {
      this.dialogRef.close(true);
      this.searchText = '';
      this._bhAlertService.openSnackBar({
        type: "success",
        message: CONSTANTMESSAGE.SHARE_PROJECT,
        subtitle: "",
        autodismiss: true,
        duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
      });
    });
    this.spinnerService.deactivate();
  }

  getUserDetails(idListString) {
    if(idListString){
      this.spinnerService.activate();
      this.userService.getUserDetails(idListString).subscribe(res=> {      
      let userArray  = Object.values(res['data']);        
      console.log(userArray) ;   
      this.shareMembersDataSource.data = userArray.sort();
      this.dataModel = userArray.sort();
      this.shareMembersDataSource.paginator = this.paginator;  
      this.shareMembersDataSource.sort = this.sort; 
      this.shareMembersDataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        return  data[sortHeaderId].toString().trim().toLocaleLowerCase();
      };
      this.searchText = '';
      this.spinnerService.deactivate();
      });
    }
  }


  deleteUser(item) {    
    if(item.id != this.data.data.createdBy){
    this.shareMembersDataSource.data = this.dataModel.filter(elem => elem. id !== item.id);
    this.dataModel = this.dataModel.filter(elem => elem. id !== item.id);
    this.searchText ='';
    this.selectionChanged = true;
    }
    
  }
  

}
