import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";

import { CustomerService } from './../../../services/project/customer.service';
import { CustomerListInfoModel, CustomerInfoModel } from './../../../shared/models/customerList.model';
import { AuthConfigService } from "../../auth/auth-config.service";
import { CustomValidators } from '../../../shared/helpers/custom-validator';
@Component({
  selector: "lbm-create-project",
  templateUrl: "./create-project.component.html",
  styleUrls: ["./create-project.component.scss"],
})
export class CreateProjectComponent implements OnInit {
  title = "Create New Project";
  submitButtonText = "CREATE";
  customerList: CustomerInfoModel[];
  refineryList: CustomerInfoModel[];
  error = false;
  errorCustomer = false;
  errorRefinery = false;
  projectData;
  projectForm: FormGroup;
  getExternalRole;
  errorName = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {},
    public dialogRef: MatDialogRef<CreateProjectComponent>,
    private customerService: CustomerService,
    public formBuilder: FormBuilder,
    private authConfigService: AuthConfigService
  ) {
    this.getExternalRole  = this.authConfigService.checkIfExternalUser();
    this.projectData = { ...data };

    //this.errorCustomer = this.projectData['customer'] === undefined;
    //this.errorRefinery = this.projectData['refinery'] === undefined;

    if (this.projectData['id']) {
      this.title = "Edit Project Details";
    } else {
      this.error = true;

    }
  }

  ngOnInit(): void {
    this.fillFormData();
    this.customerService.getCustomerList().subscribe((res: CustomerListInfoModel) => {
      this.customerList = res && res.data ? res.data : null;
      if(this.getExternalRole) {
        this.customerList = this.customerList.filter(res => res.name == this.getExternalRole);
      }
      if (this.projectData['id']) {
        this.getRefineryList(this.projectData['customer']['id']);
      }
    });
  }

  fillFormData() {
    this.projectForm = this.formBuilder.group({
      name: [this.projectData['name'] ? this.projectData['name'] : '', [Validators.required, Validators.maxLength(50), CustomValidators.NoEmptySpaceValidator()]],
      customer: [this.projectData['customer']?.id ? this.projectData.customer.id : '', [Validators.required]],
      refinery: [this.projectData['refinery']?.id ? this.projectData.refinery.id : '', [Validators.required]]
    });
  }

  getRefineryList(selection): void {
    selection = selection === null ? this.projectForm.value.customer : selection;
    let customerItem = this.customerList.find(customer => customer.id === parseInt(selection));
    if (customerItem) {
      this.customerService.getRefineryList(customerItem.id).subscribe((res: CustomerListInfoModel) => {
        this.refineryList = res && res.data ? res.data : null;
      });
    }
    //this.errorCustomer = this.projectData['customer'] === undefined;
  }
  get getControl() {
    return this.projectForm.controls;
  }

  onSubmit() {
    console.log('OnSave', this.projectForm);
      this.projectData['name'] = this.projectForm.value.name;
      this.projectData['customer'] = this.customerList.find(customer => customer.id === parseInt(this.projectForm.value.customer)).id;
      this.projectData['refinery'] = this.refineryList.find(customer => customer.id === parseInt(this.projectForm.value.refinery)).id;
    this.dialogRef.close(this.projectData)
  }
}
