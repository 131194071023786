import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { ProfileDetails } from 'bh-theme/lib/types/profile-details';
import { NotificationItem } from 'bh-theme/lib/types/notification-details';
import { MatSidenav } from '@angular/material/sidenav';
import { NavService, BhModalService } from 'bh-theme';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'auth-error',
  templateUrl: './auth-error.component.html',
  styleUrls: ['./auth-error.component.scss']
})
export class AuthErrorComponent implements OnInit{
 
  constructor(private navService: NavService,  
    private _bhModalService: BhModalService,
    private readonly oauthService: OAuthService) {
      console.log("inside admin constructor");
     }
  ngOnInit(): void {
    console.log("inside admin ngonit");
    
  }
  
}