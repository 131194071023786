<div class="dialog-container">
    <div class="title">
      <h4 mat-dialog-title>{{ title }}</h4>
    </div>
  
    <form [formGroup]="addUserForm" novalidate>
      <div class="dialog-contents" mat-dialog-content>
        <div class="div-box">
          <mat-form-field class="fullwidth" appearance="fill">
            <mat-label>First Name</mat-label>
            <input matInput name="firstName" formControlName="firstName" required>
          </mat-form-field>
          
          <div *ngIf="getControl.firstName.touched && getControl.firstName.invalid" class="text-danger">
            <div *ngIf="getControl.firstName.errors.required">First Name is required.</div>
            <div *ngIf="getControl.firstName.errors.emptySpace">Empty space is not allowed.</div>
         </div>

          <mat-form-field class="fullwidth" appearance="fill">
            <mat-label>Last Name </mat-label>
            <input matInput name="lastName" formControlName="lastName" required>
          </mat-form-field>
          <div *ngIf="getControl.lastName.touched && getControl.lastName.invalid" class="text-danger">
            <div *ngIf="getControl.lastName.errors.required">Last Name is required.</div>
            <div *ngIf="getControl.lastName.errors.emptySpace">Empty space is not allowed.</div>
         </div>
          
  
          <mat-form-field class="fullwidth" appearance="fill">
            <mat-label >Organization</mat-label>
            <input matInput name="org" formControlName="org" required>
          </mat-form-field>
          <div *ngIf="getControl.org.touched && getControl.org.invalid" class="text-danger">
            <div *ngIf="getControl.org.errors.required">Organization is required.</div>
            <div *ngIf="getControl.org.errors.emptySpace">Empty space is not allowed.</div>
            
          </div> 
  
          <mat-form-field class="fullwidth" appearance="fill">
            <mat-label>EmailID</mat-label>           
            <input matInput type="email" name="email" formControlName="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" required>
            </mat-form-field>
            <div *ngIf="getControl.email.touched && getControl.email.invalid" class="text-danger">
                <div *ngIf="getControl.email.errors.required">EmailID is required.</div>
                <div *ngIf="getControl.email.errors.emptySpace">Empty space is not allowed.</div>
                <div *ngIf="getControl.email.errors.pattern">Please provide a valid email address.</div>
            </div> 
            <mat-form-field class="fullwidth" appearance="fill">
                <mat-label for="">Role</mat-label>
                <mat-select formControlName="role" name="role" required>                    
                    <mat-option *ngIf="!(addUserForm.controls['email'].value.toLowerCase().endsWith('@bakerhughes.com'))"value="EXTERNAL">ExternalUser</mat-option>                    
                </mat-select>
              </mat-form-field>
              <div  *ngIf="getControl.role.touched" class="text-danger" >
                <div *ngIf="addUserForm.controls['email'].value.toLowerCase().endsWith('@bakerhughes.com')">Internal User cannot be added</div>
              </div>
              <div *ngIf="getControl.role.touched && getControl.role.invalid" class="text-danger">
                <div *ngIf="getControl.role.errors.required">Role is required.</div>
              </div>
      </div>
      </div>
      <div mat-dialog-actions align="end">
        <button mat-button class="mat-button-sm cancel_btn" [mat-dialog-close]=null>Cancel</button>
        <button type="submit"  mat-button class="mat-button-primary-sm"
          (click)="onSubmit()" [disabled]="addUserForm.status === 'INVALID'">Save</button>
      </div>
    </form>
  
  
  </div>