<div class="dialog-options" [ngStyle]="{
  'left.px': data['targetX'],
  'top.px': data['targetY']
}">
    <div class="dialog-link" (click)="onSelect('internal')">Internal User</div>
    <div class="dialog-link" (click)="onSelect('external')">External User</div>
    <div class="dialog-link" (click)="onSelect('sme')">SME</div>
    <div class="dialog-link" (click)="onSelect('admin')">Admin</div>
    <div class="dialog-link" (click)="onSelect('active')">Active Users</div>
    <div class="dialog-link" (click)="onSelect('inactive')">Inactive Users</div>
    <div class="dialog-link" (click)="onSelect('clear')">Clear Filter</div>
</div>