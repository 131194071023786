<div class="lbm-spinner" *ngIf="activeSpinner" align="center">
    <mat-spinner></mat-spinner>
</div>
<div class="title">Admin</div>
<div class="bh-table mat-elevation-z8">
    <div class="bh-table-header">
        <div class="bh-table-title mat-subtitle-1">User Roles</div>
        <span class="fill-spacer"></span>
        <div class="bh-table-action-group">
            <button mat-button class="" #filterButton (click)="filter($event)">
                <mat-icon class="mat-icon-left mat-icon--sml">filter_list</mat-icon>
            </button>
            <div class="lbm-search-holder">
                <mat-icon class="wc-right">search</mat-icon>
                <input type="text" matInput placeholder="Search" class="ple-left" (input)="searchTable($event)">
            </div>
            <button mat-button class="bh-table-action-button mat-button-primary-sm" (click)="addUser()">ADD NEW USER</button>
        </div>
    </div>

    <div class="parent-table  admin-container">
        <table  mat-table matSort  [dataSource]="dataSource"  matSortActive="email"   matSortDirection="asc" class="mat-elevation-z8"> 
            <ng-container matColumnDef="name">
                <th class="width-col-1" mat-header-cell *matHeaderCellDef mat-sort-header> User</th>
                <td class="width-col-1" mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>     
            <ng-container matColumnDef="org">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                <td mat-cell *matCellDef="let element">{{element.org}}</td>
            </ng-container>        
            <ng-container matColumnDef="email">
                <th class="width-col-1" mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
                <td class="width-col-1" mat-cell *matCellDef="let element">{{element.email}}</td>
            </ng-container>
            <ng-container matColumnDef="internalUser">
                <th class="width-roles" mat-header-cell *matHeaderCellDef> Internal User </th>
                <td class="width-roles" mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.role === 'INTERNAL'">
                        <mat-radio-button  class="role-selected ng-star-inserted mat-radio-checked" disabled  selected></mat-radio-button>
                    </ng-container>
                    <ng-container *ngIf="element.role !== 'INTERNAL'">
                        <mat-radio-button  disabled></mat-radio-button>
                    </ng-container>
                </td>
            </ng-container>     
            <ng-container matColumnDef="externalUser">
                <th class="width-roles" mat-header-cell *matHeaderCellDef> External User </th>
                <td class="width-roles" mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.role === 'EXTERNAL'">
                        <mat-radio-button  class="role-selected ng-star-inserted mat-radio-checked" disabled  selected></mat-radio-button>
                    </ng-container>
                    <ng-container *ngIf="element.role !== 'EXTERNAL'">
                        <mat-radio-button  disabled></mat-radio-button>
                    </ng-container>
                </td>
            </ng-container>   
            <ng-container matColumnDef="SME">
                <th class="width-roles" mat-header-cell *matHeaderCellDef> SME </th>
                <td class="width-roles" mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.role === 'SME'">
                        <mat-radio-button  class="role-selected ng-star-inserted mat-radio-checked" disabled  selected></mat-radio-button>
                    </ng-container>
                    <ng-container *ngIf="element.role !== 'SME'">
                        <mat-radio-button  disabled></mat-radio-button>
                    </ng-container>
                </td>
            </ng-container>  
             <ng-container matColumnDef="admin">
                <th class="width-roles" mat-header-cell *matHeaderCellDef> Admin </th>
                <td class="width-roles" mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.role === 'ADMIN'">
                        <mat-radio-button class="role-selected ng-star-inserted mat-radio-checked" selected disabled></mat-radio-button>
                    </ng-container>
                    <ng-container *ngIf="element.role !== 'ADMIN'">
                        <mat-radio-button  disabled></mat-radio-button>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="activeInactive">
                <th class="width-roles" mat-header-cell *matHeaderCellDef> Active /<br> Inactive </th>
                <td class="width-roles" mat-cell *matCellDef="let element">
                    <ng-container>
                        <mat-checkbox [disabled]="element.role === 'ADMIN' || element.role === 'SME' || element.role === 'INTERNAL'" color="primary" (click)="$event.stopPropagation()"
                            (change)="activeInactive($event, element)" [checked]="element.active">
                        </mat-checkbox>        
                    </ng-container>                    
                </td>
            </ng-container> 
            <ng-container matColumnDef="final">
                <th class="width-col" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <ng-container >
                    <mat-menu #appMenu="matMenu" xPosition="before">
                        <button [disabled]="!element.active || element.role === 'ADMIN' || element.role === 'SME' || element.role === 'INTERNAL'" mat-menu-item (click)="editDetails(element)">Edit Details</button>
                        <button [disabled]="element.role === 'ADMIN' || element.role === 'SME' || element.role === 'INTERNAL'" mat-menu-item (click)="deleteUser(element)">Delete User</button>
                    </mat-menu>
                        <mat-icon  class="bh-table-action-button" matTooltip="More Actions" [matMenuTriggerFor]="appMenu">
                            more_vert
                        </mat-icon>
                    </ng-container>
  
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 36px;"></tr>
        </table>
        <mat-paginator [class.pagination-hidden]="dataModel.length === 0" [pageSizeOptions]="[5, 10, 15]" [pageSize]="5"
        showFirstLastButtons></mat-paginator>
    </div>
</div>

<div class="noData" *ngIf="!activeSpinner && dataModel.length === 0" class="lbm-illustration-container">
    <bh-illustrations [illustrationData]="emptyState"></bh-illustrations>
</div>
