import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CustomValidators } from 'src/app/shared/helpers/custom-validator';
import { IllustrationData, IllustrationNames, BhModalService, BhAlertService } from 'bh-theme';
import { CONSTANTMESSAGE } from 'src/app/shared/constants/constants-messages';
import { ProjectService } from 'src/app/services/project/project.service';
import { thresholdSturges } from 'd3';



@Component({
	selector: 'blend-as-component',
	templateUrl: 'blend-as-component.component.html',
     styleUrls: ['./blend-as-component.component.scss']
})

export class BlendAsComponentComponent implements OnInit {
	title = 'Save Blend as a Component';
	componentName: string;
	componentType: string;
	blendAsComponentForm: FormGroup;
	constructor(
	  @Inject(MAT_DIALOG_DATA) public data: {},
	  public dialogRef: MatDialogRef<BlendAsComponentComponent>,
	  public formBuilder: FormBuilder,
	  private _bhAlertService: BhAlertService,
	  private projectsService: ProjectService
	 
	) {
		this.componentName = data['blendName'];
		this.componentType = 'N';
	  console.log("blend description", data);
  
	}
  
	ngOnInit(): void {
		this.blendAsComponentForm = this.formBuilder.group({
			componentName: [this.data['componentName'] ? this.data['componentName'] : '', [Validators.required, Validators.maxLength(150), CustomValidators.NoEmptySpaceValidator()]],
			componentType: [this.data['componentType'] ? this.data['componentType'] : '', [Validators.required]]
		});
	}
  
	get getControl() {
		return this.blendAsComponentForm.controls;
	  }

	onSubmit() {
	  this.data['componentName'] = this.blendAsComponentForm.value.componentName;
	  this.data['componentType'] = this.blendAsComponentForm.value.componentType;
	  this.data['json']['componentName']  = this.blendAsComponentForm.value.componentName; 
	  this.data['json']['componentType'] = this.blendAsComponentForm.value.componentType;
	  this.data['json']['dataSource'] = this.data['json']['dataSource']['name'];
	  this.data['json']['savedBlends'] = this.data['savedBlends'];
      this.projectsService.saveBlendAsComponent(this.data['projectId'],this.data['json']).subscribe(
        output => {
          this._bhAlertService.openSnackBar({
            type: "success",
            message: CONSTANTMESSAGE.ADD_BLEND_AS_COMPONENT,
            subtitle: "",
            autodismiss: true,
            duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
          });
		  this.dialogRef.close(this.data);
        }
        
      );
	 
	}
   
  
  }
  
