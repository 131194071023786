export const CONSTANTMESSAGE = { 
    FAILED_TO_LOAD_DATA : "Failed to load data",
    FAILED_TO_LOAD_API : "Failed to load api",
    USER_NOT_ALLOWED_TO_SHARE_PROJECT: "User is not allowed to share this Project ",
    UNAUTHORIZED_USER : "Unauthorized user",
    ADD_COMPONENT_PROJECT: "Successfully Added New Component",
    TOASTER_MESSAGE_DURATION: "2000",
    BASE_CONDITION_APPLIED: "Base conditions applied successfuly",
    BLENDS_SAVE_SUCCESSFULLY: "Saved Blends Successfully.",
    ADD_COMPONENT: "Successfully Added New Component",
    UPDATE_COMPONENT: "Successfully Updated Component",
    DELETE_PROJECT: "Project Deleted Successfully",
    UPDATE_PROJECT: "Project Updated Successfully",
    ADD_PROJECT: "Project Added Successfully",
    DELETE_COMPONENT: "Component Deleted Successfully",
    DELETE_BLEND: "Blend Deleted Successfully",
    DELETE_BLENDS: "Blends Deleted Successfully",
    ADD_USER: "User added successfully",
    ADD_USER_EXISTS: "User added successfully",
    ADD_USER_FAIL: "Failed to add User",
    EDIT_USER : "User details edited successfully",
    EDIT_USER_FAIL:"Failed to edit User details",
    DELETE_USER : "User deleted successfully",
    DELETE_USER_FAIL : "Falied to delete the user",
    INACTIVE_USER_SUCCESS: "User inactivated successfully",
    INACTIVE_USER_FAIL: "Failed to inactivate user",
    ACTIVE_USER_SUCCESS: "User activated successfully",
    ACTIVE_USER_FAIL: "Failed to activate user",
    FUEL_OIL_FACTOR_SUCCESS : "Fuel Oil LSCI Factor Multiplier applied successfully",
    EXISTING_USER : "Existing User details updated successfully",
    BINARY_GRAPH_AVAILABLE_MESSAGE:"Binary Graph Configuration is available in the Charts menu.",
    DULPLICATE_PROJECT: "Project duplicated Successfully",
    LOCAL_COMPONENT_APPROVAL_MESSAGE:"<b>Approval</b>: Click globe icon to change Local components to Global",
    LOCAL_COMPONENT_APPROVAL_SUCCESS_MESSAGE:"Approved to Global Components successfully",
    ADD_BLEND_AS_COMPONENT  : "Successfully added new component to the Library",
    SHARE_PROJECT  : "Successfully shared the project",
    SHARE_MEMBER_ADDED_ALREADY  : "Member is already added"
}

export const DATA_SOURCE_ARRAY = ["BH Europe", "BH India"];

export const CRUDE_TYPE_ARRAY = ["Heavy", "Medium", "Light", "Extra Light"];

export const CLASS_ARRAY = ["Intermediate", "Naphthenic", "Paraffinic"];

export const CONST_BINARY_ARRAY = "[100,95,90,85,80,75,70,65,60,55,50,45,40,35,30,25,20,15,10,5,0]";

export const CONST_BINARY_ARRAY_REVERSE = "[0,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100]";