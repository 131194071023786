import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_DI_CONFIG, AppConfig } from './../../app-config/app-config';
import {AppConfigService} from './../../app-config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ComponentsService {

  private COMPONENT_API_SERVER = "http://localhost:9001/v1/";
  private GET_COMPONENTS = "components";

  constructor(private httpClient: HttpClient,  private appConfigService: AppConfigService) { }

  public GetComponents() {
    return this.httpClient.get(this.appConfigService.getConfig().COMPONENT_API_SERVER + this.appConfigService.getConfig().GET_COMPONENTS);
  }

  public saveComponent(result) {
    console.log('service', result);
    return this.httpClient.post(this.appConfigService.getConfig().COMPONENT_API_SERVER + this.appConfigService.getConfig().GET_COMPONENTS, result);
  }

  public getSingleComponent(id) {
    return this.httpClient.get(this.appConfigService.getConfig().COMPONENT_API_SERVER + this.appConfigService.getConfig().GET_COMPONENTS + "/" + id);
  }

  public updateComponent(result) {
    console.log('servicePut', result);
    return this.httpClient.put(this.appConfigService.getConfig().COMPONENT_API_SERVER + this.appConfigService.getConfig().GET_COMPONENTS + '/' + result.id, result);
  }
  public deleteComponent(id) {
    console.log('serviceDelete', id);
    return this.httpClient.delete(this.appConfigService.getConfig().COMPONENT_API_SERVER + this.appConfigService.getConfig().GET_COMPONENTS + '/' + id);
  }

  public getDataSourceList(): any {
    return this.httpClient.get(this.appConfigService.getConfig().MASTER_DATA_API_SERVER + this.appConfigService.getConfig().GET_DATASOURCE_LIST);
  }

  public getOriginList(): any {
    return this.httpClient.get(this.appConfigService.getConfig().MASTER_DATA_API_SERVER + this.appConfigService.getConfig().GET_ORIGIN_LIST);
  }

  public getASIMethodList(): any {
    return this.httpClient.get(this.appConfigService.getConfig().MASTER_DATA_API_SERVER + this.appConfigService.getConfig().GET_ASIMETHOD_LIST);
  }

  public approveComponents(componentIds, data) {
    return this.httpClient.patch(this.appConfigService.getConfig().COMPONENT_API_SERVER + this.appConfigService.getConfig().GET_COMPONENTS +  '?ids=' + componentIds, data);
  }

}
