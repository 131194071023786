import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { APP_DI_CONFIG, AppConfig } from './../../app-config/app-config';
import {AppConfigService} from './../../app-config/app-config.service';

@Injectable({
  providedIn: 'root',
})

export class ProjectService {
  
  private BASE_URL = 'http://localhost:9002';
  // private REST_API_SERVER = "assets/json/projects/getProjects.json";
  private REST_API_SERVER = 'http://localhost:9002/v1/projects';

  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) { }
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  public GetProjects() {
    return this.httpClient.get(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS);
  }

  public getProjectById(id: number) {
    return this.httpClient.get(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS + '/' + id);
  }

  public getBlendsForProject(id: number) {
    return this.httpClient.get(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS + '/' + id + '/blends');
  }

  public saveBlendsForProject(id: number, blendsData) {
    return this.httpClient.put(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS + '/' + id + '/blends',
      blendsData
    );
  }

  public getComponentsForProject(id: number) {
    return this.httpClient.get(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS + '/' + id + '/' + this.appConfigService.getConfig().GET_COMPONENTS);
  }

  public addComponentsToProject(id: number, componentIds: number[]) {
    return this.httpClient.put(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS + '/' + id + '/' + this.appConfigService.getConfig().GET_COMPONENTS,
      {
        componentIds: componentIds
      });
  }

  public addSharedUsersToProject(id: number, userIds: string[]) {
    return this.httpClient.put(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS + '/' + id + '/' + 'users',
      {
        sharedUsers: userIds
      });
  }

  public saveProject(result) {
    return this.httpClient.post(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS,
      {
        createdAt: result.createdAt,
        customer: result.customer,
        customerId: result.customerId,
        description: result.description,
        name: result.name,
        refinery: result.refinery,
        refineryId: result.refineryId
      });
  }

  public updateProject(result) {
    return this.httpClient.put(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS + '/' + result.id,
      {
        createdAt: result.createdAt,
        customer: result.customer,
        customerId: result.customerId,
        description: result.description,
        name: result.name,
        refinery: result.refinery,
        refineryId: result.refineryId
      });
  }

  public deleteProject(id) {
    console.log('serviceDelete', id);
    return this.httpClient.delete(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS + '/' + id);
  }

  public updateBaseComponent(id, data) {
    return this.httpClient.patch(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS + '/' + id, data);
  }

  public uploadBlendSchedules(id, file){
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS + '/' + id + '/' + this.appConfigService.getConfig().UPLOAD_BLEND_SCHEDULE,formData)
  }

  public duplicateProject(result) {
    return this.httpClient.post(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS,
      {
        source_project: result
      });
  }

  public saveBlendAsComponent(id,result) {
    return this.httpClient.post(this.appConfigService.getConfig().PROJECTS_API_SERVER + this.appConfigService.getConfig().GET_PROJECTS + '/' + id + '/blends/components', result)
  }
}
