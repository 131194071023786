import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { APP_DI_CONFIG, AppConfig } from './../../app-config/app-config';
import {AppConfigService} from './../../app-config/app-config.service';

@Injectable({
  providedIn: 'root',
})

export class UserService {

  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) { }
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  public getUserDetails(idListString) {
    return this.httpClient.get(this.appConfigService.getConfig().USER_SERVICE_API_SERVER + this.appConfigService.getConfig().GET_USERS + "?ids=" + idListString);
  }

  public getUserSearch(filter,org) {
    return this.httpClient.get(this.appConfigService.getConfig().USER_SERVICE_API_SERVER + this.appConfigService.getConfig().SEARCH_USERS + "?filter=" + filter + "&org="+org);
  }

  public getLBMUsers() {
    return this.httpClient.get(this.appConfigService.getConfig().USER_SERVICE_API_SERVER + this.appConfigService.getConfig().GET_USERS);
  }

  public deleteUser(id) {
    return this.httpClient.delete (this.appConfigService.getConfig().USER_SERVICE_API_SERVER + this.appConfigService.getConfig().GET_USERS + "/" + id);
  }

  public updateUser(id, value, path) {
    let obj = [
      {
          "op": "replace",
          "path": "/" + path,
          "value": value
      }
  ]
    return this.httpClient.patch (this.appConfigService.getConfig().USER_SERVICE_API_SERVER + this.appConfigService.getConfig().GET_USERS + "/" + id, obj);
  }

  public createUser(user) {
    return this.httpClient.post (this.appConfigService.getConfig().USER_SERVICE_API_SERVER + this.appConfigService.getConfig().GET_USERS + "/" ,user);
  }
}
