import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Component, OnInit, Inject, ViewChild } from "@angular/core";


@Component({
  selector: "lbm-shared-modal",
  templateUrl: "./shared-modal.component.html",
  styleUrls: ["./shared-modal.component.scss"],
})


export class SharedModalComponent {
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SharedModalComponent>,
    private dialog: MatDialog
  ) {  }

  agree(){
    this.dialogRef.close('agree');
  }

  cancel () {
    this.dialogRef.close('close');
  }

}
