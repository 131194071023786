import { NgModule } from '@angular/core';
import { APP_DI_CONFIG,APP_CONFIG } from './app-config';


@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useValue: APP_DI_CONFIG
  }]
})

export class AppConfigModule { }
