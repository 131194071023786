import { Injectable } from '@angular/core';
import { AuthConfig, NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { filter } from 'rxjs/operators';
import { AppConfigService } from '../../app-config/app-config.service';
import { Subject } from 'rxjs';
import jwt_decode from "jwt-decode";
//import { AuthErrorComponent } from '../auth-error/auth-error.component';
import { MatDialog } from "@angular/material/dialog";
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthConfigService {

  private _accessToken: string;
  private _IdToken: string;
  private tokenReceived = new Subject();
  //private authErrorComponent: AuthErrorComponent;

    constructor(
      private readonly oauthService: OAuthService,
      private appConfigService: AppConfigService,
      private dialog: MatDialog,
      public router : Router

    ) {
    }


    get accessToken() { 
      return this._accessToken; 
    }
  
    get IdToken() { 
      return this._IdToken; 
    }

    initAuth(): Promise<any> {
      return new Promise<void>((resolveFn, rejectFn) => {

        this.setRedirectURL();
        let authConfig  : AuthConfig =  this.appConfigService.getConfig().identity;
        // setup oauthService
        authConfig.redirectUri = this.getRedirectURL();
        this.oauthService.configure(authConfig);
        this.oauthService.setStorage(sessionStorage);
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();

        // subscribe to token events
        this.oauthService.events
          .pipe(filter((e: any) => {
            if(e.type === 'token_error'){
              console.log('acces denied');
            }else{
            console.log('e.type', e.type);
            return e.type === 'token_received';
            }
          }))
          .subscribe(() => this.handleNewToken());

        this.oauthService.loadDiscoveryDocumentAndLogin().then(
          isLoggedIn => {
          if (isLoggedIn) {
            this.oauthService.setupAutomaticSilentRefresh();
            resolveFn();
          } else {
            this.oauthService.initImplicitFlow();
            rejectFn();
          }
        }).catch(err => {
          resolveFn();
          this.router.navigate(['error']); 
      });

      });
    }

    /**
     * Save new token
     */
    private handleNewToken() {
      this._accessToken = this.oauthService.getAccessToken();
      this._IdToken = this.oauthService.getIdToken();
      this.setTokenDetails(this._IdToken);
      // this.resetRedirectURL();
    }

    /**
     * Check token validity
     */
    hasValidAccessToken():boolean{
      return this.oauthService.hasValidAccessToken();
    }

    /**
     * Logout from auth service
     */
    logout(){
      this.resetRedirectURL(window.location.href);
      return this.oauthService.logOut();
    }


    /**
     * Token observer
     */
    getTokenDetails(){
      return this.tokenReceived.asObservable();
    }
  
    setTokenDetails(val:string){
      this.tokenReceived.next(this.oauthService.getIdentityClaims());
    }

    /**
     * Decoded session storage
     */
    getSessionStorage(){
      return this.oauthService.getIdentityClaims();
    }

    /**
     * Get redirect url
     * @returns URL
     */
    getRedirectURL(){
      const urlFromSession = sessionStorage.getItem('last_redirect_url');
      // if(urlFromSession) 
      //   return urlFromSession;
      // else 
      //   return window.location.href;
      return this.appConfigService.getConfig().identity.redirectUri;
    }
 
    /**
     * Set Redirect URL
     */
    setRedirectURL(){
      sessionStorage.setItem('last_redirect_url', this.getRedirectURL());
    }

    /**
     * Reset redirect URL
     */
    resetRedirectURL(url:string = ''){
      sessionStorage.setItem('last_redirect_url', url);
    }

    checkIfSME() {
      let _accessToken = this.oauthService.getAccessToken();
      let _claims = jwt_decode(_accessToken);
      let role = _claims && _claims['Claim_Role'] && _claims['Claim_Role'].length ? _claims['Claim_Role'][0] : null;
      return role === 'myid_1278117_oic_lifespan_prod_blend_sme' ? true : false;
    }

    checkIfExternalUser() {
      let _accessToken = this.oauthService.getAccessToken();
      let _claims = jwt_decode(_accessToken);
      console.log(_claims);
      let role = _claims && _claims['Claim_Role'] && _claims['Claim_Role'].length ? _claims['Claim_Role'][0] : null;
      return role === 'B2B_1278117_oic_lifespan_prod_blend_externalusers' ? _claims['Claim_Org'] : undefined; 
    } 

    checkIfAdmin() {
      let _accessToken = this.oauthService.getAccessToken();
      let _claims = jwt_decode(_accessToken);
      let role = _claims && _claims['Claim_Role'] && _claims['Claim_Role'].length ? _claims['Claim_Role'][0] : null;
      return role === 'myid_1278117_oic_lifespan_prod_blend_admins' ? true : false;
    }

    getUserId() {
      let _accessToken = this.oauthService.getAccessToken();
      let _claims = jwt_decode(_accessToken);
      return _claims;
    }

    redirectErrorPage(){
    this.router.navigate(['error']);
    }

    redirectInitialPage (page) {
      this.router.navigate([page]);
    }
}
