<div class="noData" *ngIf="noBlends" class="lbm-illustration-container">
    <bh-illustrations [illustrationData]="emptyState"></bh-illustrations>
</div>

<div class="noData" *ngIf="blendCalculations" class="lbm-illustration-container">
    <bh-illustrations [illustrationData]="blendCalculationState"></bh-illustrations>
</div>

<div class="componentstab" *ngIf="!noBlends && !blendCalculations">
    <div class="cleft">
        <mat-accordion [togglePosition]="'before'" class="crude-accordion" *ngIf="selectedComponents.length === 2 && dataModelReport.length === 21 && binaryConfigurationflag">
            <mat-expansion-panel (opened)="panelOpenStateGraph = true" (closed)="panelOpenStateGraph = false"  [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="mat-subtitle-1">
                        Crude Blend Model
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ple-binary-graph [blendsData]="dataModelReport"
                    [selectedComponents]="selectedComponents"></ple-binary-graph>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion [togglePosition]="'before'" class="crude-accordion">
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="bh-table-header">
                            <div class="bh-table-title mat-subtitle-1">Blends</div>
                            <span class="fill-spacer"></span>
                            <div *ngIf="panelOpenState" class="bh-table-action-group">
                                <div class="lbm-search-holder" (click)="$event.stopPropagation();">
                                  <mat-icon class="wc-right" [ngClass]="{'disableIcon': !isLoggedUserasCreatedUser}">search</mat-icon>
                                  <input type="text" matInput placeholder="Search" (keydown.space)="$event.stopPropagation();" [(ngModel)]="searchText" class="ple-left" (input)="$event.stopPropagation();searchTable($event)" [attr.disabled]="!isLoggedUserasCreatedUser">
                                </div>
                                <mat-icon matTooltip="Components" class="bh-table-action-button visibility bh-table-action-icon" [ngClass]="{'disableIcon': !isLoggedUserasCreatedUser}" *ngIf="showComponents"
                                    (click)="$event.stopPropagation();toggleComponents()">
                                    visibility</mat-icon>
                                <mat-icon matTooltip="Components" class="bh-table-action-button visibility bh-table-action-icon" [ngClass]="{'disableIcon': !isLoggedUserasCreatedUser}" *ngIf="!showComponents"
                                    (click)="$event.stopPropagation();toggleComponents()">
                                    visibility_off</mat-icon>
                                <span (click)="$event.stopPropagation();" class="context-text">Components</span>
                                <mat-icon matTooltip="Output" class="bh-table-action-button visibility bh-table-action-icon" [ngClass]="{'disableIcon': !isLoggedUserasCreatedUser}" *ngIf="showOutput"
                                    (click)="$event.stopPropagation();toggleOutput()">
                                    visibility</mat-icon>
                                <mat-icon matTooltip="Output" class="bh-table-action-button visibility bh-table-action-icon" [ngClass]="{'disableIcon': !isLoggedUserasCreatedUser}" *ngIf="!showOutput"
                                    (click)="$event.stopPropagation();toggleOutput()">
                                    visibility_off</mat-icon>
                                <span (click)="$event.stopPropagation();" class="context-text">Output</span>
                                <button mat-button class="bh-table-action-button mat-button-primary-sm"
                                    (click)="$event.stopPropagation();downloadComponents()">Download</button>
                            </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bh-table">
                    <div class="parent-table">
                        <table mat-table matSort matSortActive="description" [dataSource]="dataSourceReport"
                            class="mat-elevation-z8" style="width: 100%; flex: 1;">
        
                            <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->
                            <!-- Percentage total Column -->
                            <ng-container matColumnDef="total" sticky class="mat-column-make-sticky">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 120px;"></th>
                                <td mat-cell *matCellDef="let element" style="width: 120px;">

                            <p  class="{{getTotalColor(element.total)}}"> 
                                <mat-icon *ngIf="element.total >= 98 && element.total <= 102" style="color: #02a783">check_circle_outline</mat-icon>                          
                                <mat-icon *ngIf="(element.total >= 96 && element.total < 98) || (element.total > 102 && element.total <= 104) || (element.total >= 90 && element.total < 96) || (element.total > 104 && element.total <= 110) "  matTooltipClass="tooltipStyle" matTooltipPosition="above">error_outline</mat-icon>
                                <mat-icon *ngIf="element.total < 90 "   matTooltipClass="tooltipStyle" matTooltipPosition="above">error_outline</mat-icon>
                                <mat-icon *ngIf="element.total > 110 "   matTooltipClass="tooltipStyle" matTooltipPosition="above">error_outline</mat-icon>
                                <span class="total-style">
                                <!-- {{getTotal(element.total)}} % -->

                                        {{element.total}} %

                                    </span>

                            </p>  
                        </td>

                            </ng-container>
        
                            <!-- Blend Description Column -->
                            <ng-container matColumnDef="Blend Description"sticky class="mat-column-make-sticky">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width:145px;border-right: 1px solid #ced7d4;"> Blend Description
                                </th>
                                <td mat-cell *matCellDef="let element; index as i" style="min-width:145px;border-right: 1px solid #ced7d4;">
                                        {{element['Blend Description']}}
                                </td>
                            </ng-container>
        
                            <!-- Component Columns -->
                            <ng-container *ngFor="let key of componentHeaders" matColumnDef="{{key.listName}}">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:110px;padding-left: 5px;"> {{key.listName}} </th>
                                <td mat-cell *matCellDef="let element; index as i" style="width:110px;padding-left: 5px;">
                                        {{element[key.listName]}} %
                                </td>
                            </ng-container>
        
                            <!-- ASI Column -->
                            <ng-container matColumnDef="ASI" [stickyEnd]="isStickyEndColumn('ASI')">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:60px;border-left: 1px solid #ced7d4;padding-left: 5px;"> ASI </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{'greyOutCell':  outOfDateFlag}" style="width:60px;border-left: 1px solid #ced7d4;padding-left: 5px;"> {{element.ASI | number:'1.0-0'}} </td>
                            </ng-container>
        
                            <!-- LSCI Column -->
                            <ng-container matColumnDef="LSCI" [stickyEnd]="isStickyEndColumn('LSCI')">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> LSCI </th>
                                <td mat-cell *matCellDef="let element"  [ngClass]="{'greyOutCell':  outOfDateFlag}"> 
                                    <p class="{{getLSCI(element.LSCI)}}">{{element.LSCI | number:'1.1-1'}} </p>
                                </td>
                            </ng-container>
        
                            <!-- Asphaltenes Column -->
                            <ng-container matColumnDef="Blend Asphaltene%" [stickyEnd]="isStickyEndColumn('Blend Asphaltene%')">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Asphaltene% </th>
                                <td mat-cell *matCellDef="let element"  [ngClass]="{'greyOutCell':  outOfDateFlag}"> {{element['Blend Asphaltene%']}} </td>
                            </ng-container>
                            <!-- Min NFIT Column -->
                            <ng-container matColumnDef="minNFIT" >
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Min NFIT <br> deg/day </th>
                                <td mat-cell *matCellDef="let element"  [ngClass]="{'greyOutCell':  outOfDateFlag}"> {{element['minNFIT']}}</td>
                            </ng-container>
                            
                             <!-- Max NFIT Column -->
                             <ng-container matColumnDef="maxNFIT" >
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Max NFIT <br> deg/day </th>
                                <td mat-cell *matCellDef="let element"  [ngClass]="{'greyOutCell':  outOfDateFlag}">{{element['maxNFIT']}} </td>
                            </ng-container>
        
                             <!--blend SBN  Column -->
                             <ng-container matColumnDef="blendSBn" >
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Blend SBN </th>
                                <td mat-cell *matCellDef="let element"  [ngClass]="{'greyOutCell':  outOfDateFlag}"> {{element['blendSBn']}}</td>
                            </ng-container>
        
                             <!-- blendIN Column -->
                             <ng-container matColumnDef="blendIn" >
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Blend IN </th>
                                <td mat-cell *matCellDef="let element"  [ngClass]="{'greyOutCell':  outOfDateFlag}"> {{element['blendIn']}}</td>
                            </ng-container>
        
                             <!-- fuelOil Column -->
                             <ng-container matColumnDef="fuelOil_LSCI_Factor" >
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fuel Oil <br> LSCI Factor </th>
                                <td mat-cell *matCellDef="let element"  [ngClass]="{'greyOutCell':  outOfDateFlag}"> {{element['fuelOil_LSCI_Factor']}}</td>
                            </ng-container>
        
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 36px;"></tr>
                        </table>
        
                    </div>
        
                </div>
        
                <mat-paginator [class.pagination-hidden]="dataModelReport.length === 0" [pageSizeOptions]="[5, 10, 15]" [pageSize]="5"
                    showFirstLastButtons></mat-paginator>
            </mat-expansion-panel>
        </mat-accordion>
        
    </div>

</div>