import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit,  Inject } from '@angular/core';
import { CustomValidators } from '../../../shared/helpers/custom-validator';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { AdminService } from 'src/app/services/admin/admin.service';
import { BhAlertService } from 'bh-theme';
import { CONSTANTMESSAGE } from 'src/app/shared/constants/constants-messages';
import {forkJoin} from "rxjs";
import {AppConfigService} from 'src/app/app-config/app-config.service';
import { UserService } from "src/app/services/userservice/user.service";


@Component({
    selector: "lbm-add-user",
    templateUrl: "./add-user.component.html",
    styleUrls: ["./add-user.component.scss"],
  })
export class AddUserComponent implements OnInit {
    title = "Add New User";
    addUserForm: FormGroup;
    userData;
    groupIds=[];
    user;
    lbmGroupIds=[];
    externalAccessGroupId;
    externalRoleGroupId;
    internalAccessGroupId;
    internalRoleGroupId;
    SMERoleGroupId;
    adminRoleGroupId;
    activeSpinner: boolean;
    loadSpinner = true;
    editFlag = false;
    isUserExists: boolean = false;
    
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {},
        public dialogRef: MatDialogRef<AddUserComponent>,
        public formBuilder: FormBuilder,
        private adminService: AdminService,
        private _bhAlertService: BhAlertService,
        private appConfigService: AppConfigService,
        private userService: UserService,
        private spinnerService : SpinnerService
        
      ) {
         
        
        this.userData = { ...data };
                
        if (data && data['email']) {
          this.editFlag = true;
          this.title = 'Edit User Details';
        } else {
          this.editFlag = false;
          this.title = 'Add New User';
        }
        
        
      }

    ngOnInit(): void {
        this.fillFormData();
      }      

      
      fillFormData() {
        this.addUserForm = this.formBuilder.group({          
          firstName: [{value : this.userData['firstName'] ? this.userData['firstName'] : '', disabled : this.editFlag}, [Validators.required,
            CustomValidators.NoEmptySpaceValidator()]],
          lastName: [{value:this.userData['lastName'] ? this.userData['lastName'] : '', disabled : this.editFlag}, [Validators.required,
                CustomValidators.NoEmptySpaceValidator()]],
          org: [{value : this.userData['org'] ? this.userData['org'] : '', disabled : this.editFlag}, [Validators.required]],
          email: [{value : this.userData['email'] ? this.userData['email'] : '', disabled : this.editFlag}, [Validators.required]],
          role: [this.userData['role'] ? this.userData['role'] : '', [Validators.required]],
          id: this.userData['id']
        });
      }

      onSubmit() {
        
        
        if(this.editFlag && this.addUserForm && this.addUserForm.value){
          this.dialogRef.close(this.addUserForm);          
        } else {
          this.dialogRef.close(this.addUserForm);          
        }  
        
      }
 
      showSuccessMsg(msg){
        this._bhAlertService.openSnackBar({
          type: "success",
          message: msg,
          subtitle: "",
          autodismiss: true,
          duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
        });
      }
      showErrorMsg(msg){
        this._bhAlertService.openSnackBar({
          type: "error",
          message: msg,
          subtitle: "",
          autodismiss: true,
          duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
        });
      }
      

      get getControl() {
        return this.addUserForm.controls;
      }

}
