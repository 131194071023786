<div class="title">Component Library</div>
<div class="lbm-spinner" *ngIf="activeSpinner" align="center">
    <mat-spinner></mat-spinner>
</div>
<div class="bh-table mat-elevation-z8">
    <div class="bh-table-header">
        <div class="bh-table-title mat-subtitle-1">Component Library</div>
        <span class="fill-spacer"></span>
        <div class="bh-table-action-group">
            <mat-icon matTooltip="Filter List" class="bh-table-action-button" (click)=filter($event)>filter_list
            </mat-icon>
            <!-- <mat-icon matTooltip="More Action" class="bh-table-action-button">search</mat-icon> -->
            <div class="lbm-search-holder">
                <mat-icon class="wc-right">search</mat-icon>

                <input type="text" matInput placeholder="Search" class="ple-left" name="search" [(ngModel)]="searchText"
                    (ngModelChange)="search()">
            </div>
            <button mat-button class="bh-table-action-button mat-button-primary-sm" (click)="selectComponentType()">Add
                New Component
            </button>
            <ng-container *ngIf="isSMERole && selection.selected.length > 0 ">
                <span class="selectedComp" *ngIf="selection.selected.length >= 1"> {{selection.selected.length}} Components
                    Selected</span>
                <button mat-button class="bh-table-action-button mat-button-primary-sm" (click)="cancelApproval()">Cancel
                </button>
                <button mat-button class="bh-table-action-button mat-button-primary-sm" (click)="approveBySME()"> Save
                </button>
        </ng-container>
        </div>
    </div>

    <div class="parent-table">
        <table [ngClass]="isSMERole ? 'table-class-sme' : 'table-class'"  mat-table matSort matSortActive="componentName" [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="icon">
                <th class="width-col-1" mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="isSMERole ">
                    <div class="flex-name" [ngClass]="element.tag !=='Global'? element.dataType !== 'SAVED BLEND' ?'flex-name-pointer':'flex-name' :'flex-name'">
                        <div (click)="toggleRowSelection($event, element)">
                            <mat-icon *ngIf="element.tag ==='Global' || selection.isSelected(element)" style="color: #02a783">public</mat-icon>
                            <mat-icon *ngIf="element.tag !=='Global' && element.dataType !== 'SAVED BLEND' && !selection.isSelected(element) " matTooltip="Click globe icon to change Local components to Global"
                            matTooltipPosition="right" matTooltipClass="tooltipGlobal" style="color: #bdbdbd">public</mat-icon>
                            <mat-icon *ngIf="element.tag !=='Global' && element.dataType == 'SAVED BLEND' && !selection.isSelected(element) " style="color: #bdbdbd">public_off</mat-icon>
                        </div>
                    </div>
                    </ng-container>
                    <ng-container *ngIf="!isSMERole">
                    <div class="flex-name">
                        <div >
                            <mat-icon *ngIf="element.tag ==='Global'" style="color: #02a783">public</mat-icon>
                            <mat-icon *ngIf="element.tag !=='Global' && element.dataType == 'SAVED BLEND'" style="color: #bdbdbd">public_off</mat-icon>
                            <mat-icon *ngIf="element.tag !=='Global' && element.dataType !== 'SAVED BLEND'" style="color: #bdbdbd">public</mat-icon>                           
                        </div>
                    </div>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="componentName">
                <th class="width-col" [ngClass]="isSMERole ? 'col-name-min-width' : ''"  mat-header-cell *matHeaderCellDef mat-sort-header> Component Name </th>
                <td mat-cell  [ngClass]="isSMERole ? 'name-no-restriction' : ''" *matCellDef="let element">

                    <div class="col-name">
                        {{element.componentName}} 
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="createdOn">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> Date Added </th>
                <td mat-cell *matCellDef="let element"> {{element.createdOn|date}} </td>
            </ng-container>

            <ng-container matColumnDef="dataType">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header>Data Type </th>
                <td mat-cell *matCellDef="let element"> {{element.dataType}} </td>
            </ng-container>

            <ng-container  matColumnDef="ASI">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> ASI </th>
                <td mat-cell *matCellDef="let element"> 
                    <ng-container *ngIf="element.dataType !== 'ASSAY'">{{element.ASI | number:'1.0-0'}}</ng-container>  
                    <ng-container *ngIf="element.dataType === 'ASSAY'"> {{element.ASI | number:'1.0-0'}}</ng-container>  
                </td> 
            </ng-container>          

            <ng-container matColumnDef="asphaltenePercentage">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> Asphaltene % </th>
                <td mat-cell *matCellDef="let element"> {{element['asphaltenePercentage'] + " %"}} </td>
            </ng-container>

            <ng-container matColumnDef="componentType">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> Component Type</th>
                <td mat-cell *matCellDef="let element">
                   <ng-container *ngIf="element['componentType'] === 'Y'">Fuel Oil</ng-container>
                   <ng-container *ngIf="element['componentType'] === 'D'">Diluent</ng-container>
                   <ng-container *ngIf="element['componentType'] === 'N'">Crude Oil</ng-container>
                </td>
            </ng-container>

            <ng-container *ngIf="isSMERole" matColumnDef="nFactor">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> n-Factor </th>
                <td mat-cell *matCellDef="let element"> {{element.nFactor | number:'1.0-3'}} </td>
            </ng-container>

            <ng-container *ngIf="isSMERole" matColumnDef="nTemp">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> n-Temp </th>
                <td mat-cell *matCellDef="let element"> {{element.nTemp | number:'1.0-3'}} </td>
            </ng-container>

            <ng-container *ngIf="isSMERole" matColumnDef="correctedNFactor">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> Corrected n-Factor</th>
                <td mat-cell *matCellDef="let element"> {{element.correctedNFactor | number:'1.0-3'}} </td>
            </ng-container>

            <ng-container *ngIf="isSMERole" matColumnDef="SBn">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> SBn </th>
                <td mat-cell *matCellDef="let element"> {{element['SBn'] | number:'1.0-3'}} </td>
            </ng-container>

            <ng-container *ngIf="isSMERole" matColumnDef="In">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> In </th>
                <td mat-cell *matCellDef="let element"> {{element['In'] | number:'1.0-3'}} </td>
            </ng-container>

            <ng-container *ngIf="isSMERole" matColumnDef="fuelOilLSCIFactor">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> FuelOil LSCI Factor </th>
                <td mat-cell *matCellDef="let element"> {{element['fuelOilLSCIFactor'] | number:'1.0-3'}} </td>
            </ng-container>

            <ng-container matColumnDef="ASI_Method">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header>ASI Method </th>
                <td mat-cell *matCellDef="let element"> {{element.ASI_Method}} </td>
            </ng-container>

            <ng-container *ngIf="isSMERole" matColumnDef="notes">
                <th class="width-col-notes" mat-header-cell *matHeaderCellDef mat-sort-header> Notes </th>
                <td mat-cell *matCellDef="let element;" class="showPointer text-truncate" matTooltip="{{element['notes']}}"> {{element['notes']}}</td>
            </ng-container>

            <ng-container *ngIf="isSMERole" matColumnDef="createdBy">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> Added By </th>
                <td mat-cell *matCellDef="let element"> {{element['createdBy']}} </td>
            </ng-container>

            <ng-container *ngIf="isSMERole" matColumnDef="approvedBy">
                <th class="width-col" mat-header-cell *matHeaderCellDef mat-sort-header> Approved By </th>
                <td mat-cell *matCellDef="let element"> {{element['approvedBy']}} </td>
            </ng-container>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="checkPermissionForLocal(element) || (element['tag'] === 'Global' && isSMERole)" >
                    <mat-menu #appMenu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="editComponent(element)">Edit</button>
                        <button mat-menu-item (click)="deleteModal(element.id)">Delete</button>
                    </mat-menu>
                        <mat-icon  class="bh-table-action-button" matTooltip="More Actions" [matMenuTriggerFor]="appMenu">
                            more_vert
                        </mat-icon>
                    </ng-container>
  
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 36px;"[ngClass]="selection.isSelected(row) ? 'highlight' : ''"></tr>
        </table>
        <mat-paginator [class.pagination-hidden]="dataModel.length === 0" [pageSizeOptions]="[5, 10, 15]" [pageSize]="5"
        showFirstLastButtons></mat-paginator>
    </div>

</div>

<div class="noData" *ngIf="!activeSpinner && dataModel.length === 0" class="lbm-illustration-container">
    <bh-illustrations [illustrationData]="emptyState"></bh-illustrations>
</div>