import { Component, OnInit, ViewChild } from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  NavigationExtras,
} from "@angular/router";
import { KpiDetails } from "bh-theme/lib/bh-kpi/bh-kpi.component";
import {
  IllustrationData,
  IllustrationNames,
  IllustrationActionButtonTypes,
} from "bh-theme";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { MatSort } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { provideRoutes } from "@angular/router";
import { catchError, map, tap } from "rxjs/operators";
import { BhAlertService, BhModalService, BhAlertsComponent } from "bh-theme";
import { CONSTANTMESSAGE } from "./../../shared/constants/constants-messages";
import { SortHelper } from "./../../shared/helpers/sort-helper";

@Component({
  selector: "lbm-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class AdminComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _bhAlertService: BhAlertService
  ) {}

  ngOnInit(): void {}

}
