import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lbm-filter-admin',
  templateUrl: './filter-admin.component.html',
  styleUrls: ['./filter-admin.component.scss']
})
export class FilterAdminComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {},
    public dialogRef: MatDialogRef<FilterAdminComponent>
  ) {
    console.log("filterModal", data);
  }

  ngOnInit(): void {
  }

  onSelect(option) {
    console.log("option", option);
    this.data["option"] = option;
    this.dialogRef.close(this.data);
  }
}
