<div class="bh-table users" style="padding: 15px 20px 15px">
    <div class="lbm-spinner" *ngIf="activeSpinner" align="center" style="top:0;left:0">
        <mat-spinner></mat-spinner>
    </div>
    <div class="bh-table-header">
        <div>
            <h2 class="title-project">{{data.data?.name}}</h2>
            <div><span>{{data.data?.customer.name}}</span> | <span>{{data.data?.refinery.name}}</span></div>
        </div>
        <span class="fill-spacer"></span>
        <div class="bh-table-action-group">
            <span class="selectedComp" *ngIf="selection.selected.length >= 1"> {{selection.selected.length}} Members
                Selected</span>
            <div class="lbm-search-holder">
                <mat-icon class="wc-right">search</mat-icon>
                <input type="text" matInput placeholder="Search"  class="ple-left" [(ngModel)]="searchText" (input)="searchTable()">
            </div>
            <button mat-stroked-button class="bh-table-action-button" mat-dialog-close>Cancel</button>
            <button mat-button class="bh-table-action-button mat-button-primary-sm" [disabled]="!selectionChanged"
                (click)="save()">Save</button>
        </div>
    </div>
    <h3 class="edit-members">Edit Members</h3> 
    <div class="api-search">
        <form class="user-search" >
            <!-- <form  class="lbm-search-holder"> -->
            <mat-form-field >
            <mat-label>Add new member</mat-label>
            <input type="text"            
            matInput            
            [matAutocomplete]="auto"
            #inputSearchOktaUser
            class="ple-left">            
            <mat-autocomplete class="mat-auto-style" #auto="matAutocomplete">
            <mat-option *ngFor="let searchedUser of searchedUsers" value="{{searchedUser.firstName}} {{searchedUser.lastName}}"
            (click) ="selectUser(searchedUser)" matTooltip="{{searchedUser.email}}"
            matTooltipPosition="right" matTooltipClass="tooltipUserEmail">
             {{searchedUser.firstName}} {{searchedUser.lastName}}
            </mat-option>
            <mat-option *ngIf="searchedUsers.length == 0 && textLimitReached == true" value="No Match Found" class="mat-auto-style" [disabled]=true>
            No Match Found
            </mat-option>
            </mat-autocomplete>
           </mat-form-field>    
          
        </form>
        <button mat-button class="bh-table-action-button mat-button-primary-sm" (click)="addMemberToGrid()">Add Member</button>
    </div>
        
    <mat-dialog-content class="mat-typography users-mat-tab">
                <table mat-table matSort matSortActive="name"   matSortDirection="asc"
                    [class.hidetable]="shareMembersDataSource.data.length === 0" [dataSource]="shareMembersDataSource"
                    class="mat-elevation-z8" class="member-grid">

                    <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

                    <!-- name Column -->
                    <ng-container matColumnDef="name" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let element " style="padding-top: 4px; padding-bottom: 4px;">
                            {{element.name}}</td>
                    </ng-container>

                    <!-- Organization Column -->
                    <ng-container matColumnDef="org">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization </th>
                        <td mat-cell *matCellDef="let element" style="padding-top: 4px; padding-bottom: 4px;">
                            {{element.org}} </td>
                    </ng-container>

                    <!-- emailid Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>  </th>
                        <td mat-cell *matCellDef="let element" style="padding-top: 4px; padding-bottom: 4px;" align="right"  class="delete-icon">
                            <mat-icon (click)="deleteUser(element)"  >delete</mat-icon></td>
                    </ng-container>

                    <!-- Pagination Row (full width column) -->
                    <ng-container matColumnDef="paginator">
                        <th mat-footer-cell *matFooterCellDef [colSpan]="shareMembersDisplayColumns.length">                            
                                <mat-paginator [class.pagination-hidden]="shareMembersDataSource.data.length === 0" [pageSizeOptions]="[5, 10, 15]" [pageSize]="5"
                                showFirstLastButtons></mat-paginator>
                            
                        </th>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="shareMembersDisplayColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: shareMembersDisplayColumns;" style="height: 36px;"
                    [ngClass]="row.id === data.data.createdBy ? 'greyed-row' : ''">
                    </tr>
                    <tr mat-footer-row *matFooterRowDef="['paginator']; sticky: true"></tr>
                </table>
                <div *ngIf="!activeSpinner && shareMembersDataSource.data.length === 0" class="noData lbm-illustration-container">
                    <bh-illustrations [illustrationData]="emptyState"></bh-illustrations>
                </div>

    </mat-dialog-content>
</div>