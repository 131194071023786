<div class="dialog-container">
    <div class="title">
        <h4 mat-dialog-title>{{title}}</h4>
    </div>

    <div *ngIf="initiateForm">
        <form [formGroup]="componentAssayForm" novalidate>
            <div class="dialog-contents" mat-dialog-content>
            <div class="two-column-div">
                <div class="component-item">
                    <mat-form-field class="fullwidth-assay" appearance="fill">
                        <mat-label>Component Name</mat-label>
                        <input matInput name="componentName" formControlName="componentName" required />
                    </mat-form-field>
                    <div *ngIf="getControl.componentName.touched && getControl.componentName.invalid" class="text-danger">
                        <div *ngIf="getControl.componentName.errors.required">Component Name is required.</div>
                        <div *ngIf="getControl.componentName.errors.emptySpace">Empty space is not allowed.</div>
                        <div *ngIf="getControl.componentName.errors?.maxlength">Component Name can be maximum 150 characters long</div>
                    </div>
                </div>
                <div class="component-item">
                    <mat-form-field class="fullwidth-assay" appearance="fill">
                        <mat-label>Component Type</mat-label>
                        <mat-select formControlName="componentType" name="componentType" required>
                            <mat-option value="N">Crude Oil</mat-option>
                            <mat-option value="D">Diluent</mat-option>
                            <mat-option value="Y">Fuel Oil</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="getControl.componentType.touched && getControl.componentType.status === 'INVALID'"
                        class="text-danger">
                        <div *ngIf="getControl.componentType.errors.required">Component Type is required</div>
                    </div>
                </div>
            </div>


            <div class="two-column-div">
                <div class="component-item">
                    <mat-label id="example-radio-group-label" class="gravity-class">Density</mat-label>
                    <br />
                    <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group"
                    formControlName="gravitySelected">
                    <mat-radio-button class="example-radio-button" *ngFor="let gravity of gravityOptions" [value]="gravity">
                        {{gravity}}
                    </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="component-item">
                    <mat-form-field class="fullwidth-assay" appearance="fill" *ngIf="componentAssayForm.controls['gravitySelected'].value === 'Specific Gravity'">
                            <mat-label>Specific gravity *</mat-label>
                            <input matInput name="specificGravity" formControlName="specificGravity"  (keyup)="valuechange($event, 'specificGravity')" type="number"/>
                    </mat-form-field>
                    <mat-form-field class="fullwidth-assay" appearance="fill"*ngIf="componentAssayForm.controls['gravitySelected'].value === 'API Gravity'">
                            <mat-label>API Gravity *</mat-label>
                            <input matInput name="APIGravity" formControlName="APIGravity" (keyup)="valuechange($event, 'APIGravity')" type="number" />
                    </mat-form-field>
                        <div class="text-danger">
                            <div *ngIf="componentAssayForm.controls['gravitySelected'].value === 'Specific Gravity' && spGravityFlag">Specific Gravity is required</div>
                            <div *ngIf="componentAssayForm.controls['gravitySelected'].value === 'Specific Gravity' && getControl.specificGravity.touched && componentAssayForm.controls['specificGravity'].value === '' && !spGravityFlag">Specific Gravity is required</div>
                            <div *ngIf="componentAssayForm.controls['gravitySelected'].value === 'API Gravity' && apiGravityFlag">API Gravity  is required</div>
                            <div *ngIf="componentAssayForm.controls['gravitySelected'].value === 'API Gravity' && getControl.APIGravity.touched && componentAssayForm.controls['APIGravity'].value === '' && !apiGravityFlag">API Gravity is required</div>
                        </div>
                        <div *ngIf="componentAssayForm.controls['gravitySelected'].value === 'Specific Gravity' && getControl.specificGravity.touched && getControl.specificGravity.status === 'INVALID'" class="text-danger">
                            <div *ngIf="getControl.specificGravity.errors.required">Specific Gravity is required</div>
                            <div *ngIf="!getControl.specificGravity.errors.required  && getControl.specificGravity.status === 'INVALID'">Specific Gravity  should be in range 0.687 to 1.152</div>
                        </div>
                        <div *ngIf="componentAssayForm.controls['gravitySelected'].value === 'API Gravity' && getControl.APIGravity.touched && getControl.APIGravity.status === 'INVALID'" class="text-danger">
                            <div *ngIf="getControl.APIGravity.errors.required">API Gravity  is required</div>
                            <div *ngIf="!getControl.APIGravity.errors.required  && getControl.APIGravity.status === 'INVALID'">API Gravity should be in range -8.69 to 74.43</div>
                        </div>
                </div>
                   
            </div>
            <div class="two-column-div">
                <div class="component-item">
                    <mat-form-field class="fullwidth-assay" appearance="fill">
                        <mat-label>MCR or CCR</mat-label>
                        <input matInput name="CCRorMCR" formControlName="CCRorMCR" type="number" (keyup)="validateCCR($event)"  required />
                    </mat-form-field>
                    <div class="text-danger">
                        <div *ngIf="!ccrFlag">CCR should be greater than or equal to Asphaltene%</div>
                    </div>
                    <div *ngIf="getControl.CCRorMCR.touched && getControl.CCRorMCR.status === 'INVALID'" class="text-danger">
                        <div *ngIf="getControl.CCRorMCR.errors.required && ccrFlag">MCR or CCR  is required</div>
                        <div *ngIf="!getControl.CCRorMCR.errors.required  && getControl.CCRorMCR.status === 'INVALID' && ccrFlag">MCR or CCR  should be in range 0 to 41.67</div>
                    </div>
                </div>
                <div class="component-item">
                    <mat-form-field class="fullwidth-assay" appearance="fill">
                        <mat-label>TAN</mat-label>
                        <input matInput name="TAN" formControlName="TAN" type="number" required />
                    </mat-form-field>
                    <div *ngIf="getControl.TAN.touched && getControl.TAN.status === 'INVALID'" class="text-danger">
                        <div *ngIf="getControl.TAN.errors.required">TAN  is required</div>
                        <div *ngIf="!getControl.TAN.errors.required  && getControl.TAN.status === 'INVALID'">TAN  should be in range 0 to 5</div>
                    </div>
                </div>
            </div>

            <div class="two-column-div">
                <div class="component-item">
                    <mat-form-field class="fullwidth-assay" appearance="fill">
                        <mat-label>Asphaltene %</mat-label>
                        <input matInput name="asphaltenePercentage" formControlName="asphaltenePercentage" (keyup)="validateCCR($event)"
                            type="number" />
                    </mat-form-field>
                    <div *ngIf="getControl.asphaltenePercentage.touched && getControl.asphaltenePercentage.status === 'INVALID'"
                        class="text-danger">
                        <div
                            *ngIf="!getControl.asphaltenePercentage.errors.required && getControl.asphaltenePercentage.status === 'INVALID'">
                            Asphaltene % should be in range 0 to 25.
                        </div>
                    </div>
                </div>
                <div class="component-item">
                    <mat-form-field class="fullwidth-assay" appearance="fill">
                        <mat-label>Total Sulphur Content</mat-label>
                        <input matInput name="totalSulphurContent" formControlName="totalSulphurContent" type="number" />
                    </mat-form-field>
                    <div *ngIf="getControl.totalSulphurContent.touched && getControl.totalSulphurContent.status === 'INVALID'"
                        class="text-danger">
                        <div
                            *ngIf="!getControl.totalSulphurContent.errors.required && getControl.totalSulphurContent.status === 'INVALID'">
                            Total Sulphur Content should be in range 0 to 10.
                        </div>
                    </div>

                </div>
            </div>
            <div class="two-column-div">
                <div class="component-item">
                    <mat-form-field class="fullwidth-assay" appearance="fill">
                        <mat-label>Temp of Density °C</mat-label>
                        <input matInput name="temperatureDensity" formControlName="temperatureDensity"type="number" />
                    </mat-form-field>
                </div>
                <div class="component-item">
                    <mat-form-field class="fullwidth-assay" appearance="fill">
                        <mat-label>Source</mat-label> 
                        <mat-select formControlName="dataSource" name="dataSource" required>
                            <mat-option *ngFor="let dataSourceItem of dataSourceArray" [value]="dataSourceItem">
                                {{dataSourceItem}}
                            </mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="componentAssayForm.controls['dataSource'].value === 'Other'" class="fullwidth-assay" appearance="fill">
                        <mat-label>Other Source</mat-label> 
                        <input  matInput name="dataSourceOther" formControlName="dataSourceOther" required />
                    </mat-form-field>
                     <div *ngIf="getControl.dataSource.touched && getControl.dataSource.status === 'INVALID'"
                        class="text-danger">
                        <div *ngIf="getControl.dataSource.errors.required">Source is required</div>
                    </div>
                </div>
            </div>
            <div class="two-column-div">
                <div class="component-item">
                    <mat-form-field class="fullwidth-assay" appearance="fill">
                        <mat-label>Crude Type</mat-label>
                        <mat-select formControlName="crudeType" name="crudeType" >
                            <mat-option *ngFor="let crudeTypeItem of crudeTypeArray" [value]="crudeTypeItem">
                                {{crudeTypeItem}}
                            </mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="fullwidth-assay" appearance="fill" *ngIf="componentAssayForm.controls['crudeType'].value === 'Other'" >
                        <mat-label>Other Crude Type</mat-label> 
                        <input matInput name="crudeTypeOther" formControlName="crudeTypeOther" />
                    </mat-form-field>
                </div>
                <div class="component-item">
                    <mat-form-field class="fullwidth-assay" appearance="fill">
                        <mat-label>Origin</mat-label> 
                        <mat-select formControlName="origin" name="origin">
                            <mat-option *ngFor="let originItem of originArray" [value]="originItem">
                                {{originItem}}
                            </mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="componentAssayForm.controls['origin'].value === 'Other'" class="fullwidth-assay" appearance="fill">
                        <mat-label>Other Origin</mat-label> 
                        <input  matInput name="originOther" formControlName="originOther" />
                    </mat-form-field>
                </div>
            </div>
            <div class="two-column-div">
                <div class="component-item">
                    <mat-form-field class="fullwidth-assay" appearance="fill">
                        <mat-label>Class</mat-label>
                        <mat-select formControlName="class" name="class" >
                            <mat-option *ngFor="let classItem of classArray" [value]="classItem">
                                {{classItem}}
                            </mat-option>
                            <mat-option value="Other">Other</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="fullwidth-assay" appearance="fill" *ngIf="componentAssayForm.controls['class'].value === 'Other'" >
                        <mat-label>Other Class</mat-label> 
                        <input matInput name="classOther" formControlName="classOther" />
                    </mat-form-field>
                </div>
                <div class="component-item">
                    <mat-form-field class="fullwidth-notes" appearance="fill">
                        <mat-label>Notes</mat-label>
                        <textarea class = "notes-style" matInput formControlName="notes" name="notes"></textarea>
                    </mat-form-field>
                </div>

            </div>
            <div>
                <div mat-dialog-actions align="end">
                    <button mat-button class="mat-button-sm cancel_btn" [mat-dialog-close]=null>Cancel</button>
                    <button mat-button class="mat-button-primary-sm" [disabled]="componentAssayForm.status === 'INVALID' || checkDisableButton()"
                        (click)="onSubmit()">Save</button>
                </div>
            </div>
        </div>
        </form>

    </div>

</div>