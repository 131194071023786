<div class="dialog-options" [ngStyle]="{
  'left.px': data['targetX'],
  'top.px': data['targetY']
}">
<div class="dialog-link" *ngIf="data['loggedUserId'] === data['createdBy']" (click)="onSelect('Edit')">Edit Project Details</div>
<div class="dialog-link" *ngIf="data['loggedUserId'] === data['createdBy']" (click)="onSelect('editMembers')">Edit Members</div>
<div class="dialog-link" *ngIf="data['loggedUserId'] === data['createdBy']" (click)="onSelect('Delete')">Delete Project</div>
<div class="dialog-link" (click)="onSelect('Duplicate')">Duplicate Project</div>


</div>