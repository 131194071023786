<div class="bh-table" style="padding: 0 20px 15px">
    <div class="bh-table-title mat-subtitle-1">{{data.title}}</div>
    <div class="content">{{data.content}}</div>
    <mat-dialog-actions align="end" *ngIf="data.showSaveCancel">
        <button mat-button class="mat-button-sm cancel_btn" (click)="cancel()">Cancel</button>
        <button mat-button class="mat-button-warn-sm cancel_btn" (click)="continueWithoutSave()">Continue without saving</button>
        <button mat-button class="mat-button-primary-sm" (click)="agree()">Save</button>
    </mat-dialog-actions>
    <mat-dialog-actions align="end" *ngIf="data.showOk">
        <button mat-button class="bh-table-action-button mat-button-primary-sm" [mat-dialog-close]="true" cdkFocusInitial>OK</button>
    </mat-dialog-actions>
</div>
    