import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { IllustrationData, IllustrationNames } from "bh-theme";
import { AuthConfigService } from "src/app/modules/auth/auth-config.service";
import { BlendsService } from "src/app/services/blends/blends.service";
import { ProjectService } from "src/app/services/project/project.service";


@Component({
  selector: "lbm-report-table",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})


export class ReportComponent implements OnInit {
  @Input() blendsData;
  @Input() isLoggedUserasCreatedUser;
  @Input() selectedComponents;
  @Input() projectId;
  @Input() projectName;
  noBlends: boolean = false;
  blendCalculations: boolean = false;
  outOfDateFlag = false;

  dataModelReport = [];
  dataHeaders = [];
  componentHeaders = [];
  filteredData = [];
  showComponents = true;
  showOutput = true;
  selectedProjectName = '';
  searchText ='';

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  startingColumns: string[] = ['total', 'Blend Description'];
  endingColumns: string[] = ['ASI','Blend Asphaltene%', 'LSCI','minNFIT', 'maxNFIT'];
  extraColumns: string[] = ['blendSBn', 'blendIn', 'fuelOil_LSCI_Factor'];
  componentNames: string[] = [];
  displayedColumns: string[] = [...this.startingColumns];
  dataSourceReport = new MatTableDataSource<any>([]);
  panelOpenState = false;
  panelOpenStateGraph = false;
  binaryConfigurationflag = false;

  emptyState: IllustrationData = {
    illustrationName: IllustrationNames.EmptyBox,
    title: 'Ensure Model is complete and calculations are saved',
    desc: ''
  }

  blendCalculationState: IllustrationData = {
    illustrationName: IllustrationNames.EmptyBox,
    title: 'Ensure Model is complete and calculations are saved',
    desc: ''
  }
  isSMEflag = false;
  constructor(private blendService: BlendsService,
    private projectService: ProjectService,
    private authConfigService: AuthConfigService) {
      this.isSMEflag  = this.authConfigService.checkIfSME();
  }


  ngOnInit(): void {
    this.checkBlends();
    this.dataSourceReport = new MatTableDataSource<any>([this.dataModelReport]);
    this.dataSourceReport.paginator = this.paginator;
    this.dataSourceReport.sort = this.sort;
    this.dataSourceReport.filterPredicate = (data, filter: string): boolean => {
      return data['Blend Description'].toLowerCase().includes(filter.toLowerCase());
    };
    this.getComponentsHeaders(false);
    this.selectedProjectName = this.projectName;
    this.projectService.getProjectById(this.projectId).subscribe((data: any) => {
            
      if(data && data.projectType === 'B') {
        this.binaryConfigurationflag = true;
      }
      if(data && data['areCalculationsOutOfDate'] === true) {
        this.outOfDateFlag = true;
      }

    });
  }

  downloadComponents() {
    this.blendService.downloadReportTemplate(this.projectId, this.selectedProjectName);
  }


  checkBlends() {
    this.noBlends = false;
    if (this.blendsData && this.blendsData.blends.length === 0) {
      this.noBlends = true;
      return;
    } else if (this.blendsData && this.blendsData.areCalculationsOutOfDate) {
      this.blendCalculations = true;
      return;
    } else if (this.blendsData && !this.blendsData.areCalculationsOutOfDate) {
      this.blendCalculations = false;
      this.getComponentsHeaders(true);
    }
  }

  ngAfterViewInit() {
    this.dataSourceReport.paginator = this.paginator;
    this.dataSourceReport.sort = this.sort;
  }

  getProjectBlends() {
    this.projectService.getBlendsForProject(this.projectId).subscribe((result: any) => {
      if (result) {
        let blendsData = [];
        let resultBlends = result.blends || [];
        for (let blend of resultBlends) {
          let blendObject = {};
          if(this.isSMEflag) {
            blendObject = {
              "total": 0,
              "Blend Description": blend['description'],
              "LSCI": blend['outputs']['LSCI'],
              "ASI": blend['outputs']['blendASI'],
              "Blend Asphaltene%": blend['outputs']['blendAsphaltenePercentage'],
              "minNFIT":blend['outputs']['minNFIT'],
              "maxNFIT":blend['outputs']['maxNFIT'],
              "blendSBn":blend['blendSolubilityParams']['blendSBn'],
              "blendIn":blend['blendSolubilityParams']['blendIn'],
              "fuelOil_LSCI_Factor":blend['blendSolubilityParams']['fuelOil_LSCI_Factor']
            };
          } else {
            blendObject = {
              "total": 0,
              "Blend Description": blend['description'],
              "LSCI": blend['outputs']['LSCI'],
              "ASI": blend['outputs']['blendASI'],
              "Blend Asphaltene%": blend['outputs']['blendAsphaltenePercentage'],
              "minNFIT":blend['outputs']['minNFIT'],
              "maxNFIT":blend['outputs']['maxNFIT']
            };
          }
          this.componentHeaders.forEach(res => {
            if (blend.composition.hasOwnProperty(res.id)) {
              let listValue = blend.composition[res.id] * 100;
              if(listValue.toString().indexOf('.') > -1){
                listValue = parseFloat(listValue.toFixed(2))
              }
              blendObject[res.listName] = +listValue;
              blendObject['total'] += (blendObject[res.listName]);
            }
            else {
              blendObject[res.listName] = 0;
            }
          });
          blendObject['total'] = blendObject['total'].toString().indexOf('.') > - 1 ? parseFloat(blendObject['total'].toFixed(2)) : blendObject['total']; 
          blendsData.push(blendObject);
        }
        this.dataModelReport = blendsData;
        // this.blendsData = this.dataModelReport;
        this.dataSourceReport.data = this.dataModelReport;
        this.dataSourceReport.paginator = this.paginator;
        this.dataSourceReport.sort = this.sort;
      }
    })
  }

  ngOnChanges() {
      this.selectedProjectName = this.projectName;
      this.checkBlends();
  }

  isStickyEndColumn(column: string) {
    return this.endingColumns.includes(column);
  }

  toggleOutput() {
    this.showOutput = !this.showOutput;
    this.setColumns();
  }

  toggleComponents() {
    this.showComponents = !this.showComponents;
    this.setColumns();
  }

  setColumns() {
    let columnList = [...this.startingColumns];
    if (this.showComponents) {
      columnList = [...columnList, ...this.componentNames];
    }
    if (this.showOutput) {
      columnList = [...columnList, ...this.endingColumns];
    }
    if(this.showOutput && this.isSMEflag) {
      columnList = [...columnList, ...this.extraColumns];
    }
    this.displayedColumns = columnList;
  }

  getComponentsHeaders(getBlends = false) {
    if (this.selectedComponents) {
      this.componentHeaders = this.selectedComponents;
      this.componentNames = this.componentHeaders.map(res => res.listName);
      this.setColumns();
      this.dataSourceReport.data = this.dataModelReport;
      this.dataSourceReport.paginator = this.paginator;
      this.dataSourceReport.sort = this.sort;
    }
    if (getBlends && this.selectedComponents.length > 0) {
      this.getProjectBlends();
    }
  }

  searchTable(event) {
    event.stopPropagation();
    this.dataSourceReport.filter = event.target.value;
    this.searchText =  event.target.value;
  }

  
 getLSCI(val){
  if(val === ''){ return; }
  if(val <= 0){
    return 'severe';
  } else if(val > 0 && val < 1){
    return 'high';
  } else if(val >= 1 && val < 2){
    return 'moderate';
  } else if(val >= 2){
    return 'low';
  }
}

getTotalColor(val){
  if(val === ''){ return; }
  if((val >= 96 && val < 98 ) || (val > 102 && val <= 104)){
    return 'cautious';
  } else if((val >= 90 && val < 96) || (val > 104 && val <= 110)){
    return 'warning';
  } else if(val < 90 || val > 110){
    return 'not-acceptable';
  }
   else if(val >= 98 && val <= 102){
     return 'acceptable';
   }
}
}
