<div class="crude-comp">Are you sure you want to delete this Crude Component?</div>

<div class="warning-text-crude"> This cannot be undone.</div>

<div class="button-div">
    <button mat-button class="bh-table-action-button mat-raised-button-sm" (click)="delete(false)">
        CANCEL
    </button>
    <button mat-button class="bh-table-action-button mat-button-warn-sm margin-btn" (click)="delete(true)">
        DELETE
    </button>
</div>