import { InjectionToken } from '@angular/core';
//import { environment } from '../../environments/environment';
import { environment } from '../../../src/environments/environments' ;


export class AppConfig {
    baseUrl: string;
    getDeviceInfo: string;
  }

  export const APP_DI_CONFIG: AppConfig = {
    baseUrl: environment.base_url,
    getDeviceInfo: environment.api.getDeviceInfo
  };
export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');
