<div class="dialog-container select-component-type">
    <div class="title">
        <h4 mat-dialog-title>{{title}}</h4>
    </div>

    <div>
        <form [formGroup]="blendAsComponentForm" novalidate>
            <div class="dialog-contents" mat-dialog-content>     
                <div class="div-outer-container">
                    <div style="width: 60%;">
                        <mat-form-field class="fullwidth" appearance="fill">
                            <mat-label>Component Name</mat-label>
                            <input matInput formControlName="componentName" name="componentName"   [(ngModel)]="componentName" required>
                        </mat-form-field> 
                        <div *ngIf="getControl.componentName.touched && getControl.componentName.invalid" class="text-danger">
                            <div *ngIf="getControl.componentName.errors.required">Component Name is required.</div>
                            <div *ngIf="getControl.componentName.errors.emptySpace">Empty space is not allowed.</div>
                            <div *ngIf="getControl.componentName.errors?.maxlength">Component Name can be maximum 150 characters long</div>
                        </div>                 
                    </div>
                    <div style="width: 40%;">
                        <mat-form-field class="fullwidth" appearance="fill">
                            <mat-label>Component Type</mat-label>
                            <mat-select  formControlName="componentType" name="componentType"  [(ngModel)]="componentType" required>
                                <mat-option value="Y">Fuel Oil</mat-option>
                                <mat-option value="D">Diluent</mat-option>
                                <mat-option value="N">Crude Oil</mat-option>
                            </mat-select>
                        </mat-form-field>
                         <div *ngIf="getControl.componentType.touched && getControl.componentType.status === 'INVALID'"
                        class="text-danger">
                            <div *ngIf="getControl.componentType.errors.required">Component Type is required</div>
                        </div> 
                    </div>
                </div>           
            <div>
                <div mat-dialog-actions align="end">
                    <button mat-button class="mat-button-sm cancel_btn" [mat-dialog-close]=null>Cancel</button>
                    <button mat-button class="mat-button-primary-sm"  [disabled]="blendAsComponentForm.status === 'INVALID'"
                        (click)="onSubmit()">Save</button>
                </div>
            </div>
        </div>

</form>
    </div>

</div>
