<div class="mat-toolbar-row__icon-avatar" >
    <img [@rotateRing]="isMenuOpen ? 'menuOpen' : 'menuClosed'" class="mat-toolbar-row__logo--ring"
        src="assets/ring.png">
    <img *ngIf="profileDetails && profileDetails.profileImageUrl" [matMenuTriggerFor]="profileMenu" (menuOpened)="menuOpened()"
        (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" class="mat-toolbar-row__logo--avatar"
        [src]="profileDetails.profileImageUrl">
    <span *ngIf="!profileDetails.profileImageUrl" [matMenuTriggerFor]="profileMenu" (menuOpened)="menuOpened()"
        (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()"
        class="mat-toolbar-row__logo--avatar">{{profileName | getinitials}}</span>
</div>
<mat-menu class="profileMenu" #profileMenu="matMenu" yPosition="below" >
    <div [ngSwitch]="selected">
        <div class="profile-menu" *ngSwitchDefault>
            <div class="mat-toolbar-row__icon-avatar" >
                <img [@rotateRing]="isMenuOpen ? 'menuOpen' : 'menuClosed'" class="mat-toolbar-row__logo--ring"
                    src="assets/ring.png">
                <img *ngIf="profileDetails && profileDetails.profileImageUrl" [matMenuTriggerFor]="profileMenu" (menuOpened)="menuOpened()"
                    (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" class="mat-toolbar-row__logo--avatar"
                    [src]="profileDetails.profileImageUrl">
                <span *ngIf="!profileDetails.profileImageUrl" [matMenuTriggerFor]="profileMenu" (menuOpened)="menuOpened()"
                    (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()"
                    class="mat-toolbar-row__logo--avatar_2">{{profileName | getinitials}}</span>
            </div>
            <div class="mat-subtitle-1 profile-name">{{profileName}}</div>
            <div class="mat-caption profile-email" *ngIf="profileDetails?.profileEmail">{{profileDetails.profileEmail}}</div>
            <mat-divider class="profile-menu__divider divider-dark"></mat-divider>
            <mat-list class="profile-menu__list" *ngFor="let profileMenuItem of profileDetails.profileMenuItems">
                <mat-list-item class="menu-item" (click)="navigateTo($event,profileMenuItem)">
                    <mat-icon class="mat-toolbar-row__nav-icon">{{profileMenuItem.iconName}}</mat-icon>
                    <div class="menu-item__name">{{profileMenuItem.displayName}}</div>
                    <mat-icon class="right" *ngIf="profileMenuItem.isSubMenu">chevron_right</mat-icon>
                </mat-list-item>
            </mat-list>
            <mat-list class="profile-menu__list">
                <mat-divider class="profile-menu__divider divider-dark"></mat-divider>
                <mat-list-item class="menu-item" (click)="logout()">
                    <mat-icon class="mat-toolbar-row__nav-icon lbm-logout-icon">power_settings_new</mat-icon>
                    <div class="menu-item__name">Logout</div>
                </mat-list-item>
            </mat-list>
        </div>
        <div class="sub-menu" *ngSwitchCase="'subMenu'">
            <div class="subMenu-header">
                <mat-icon (click)="backClicked($event)">arrow_back</mat-icon>
                <div class="subMenu-header__title">{{backTitle}}</div>
            </div>
            <mat-divider class="divider-dark"></mat-divider>
            <div class="subMenu-body">
                <ng-content select="[childApp]"></ng-content>
            </div>
        </div>
    </div>
</mat-menu>