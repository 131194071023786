import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { AuthConfigService } from 'src/app/modules/auth/auth-config.service';

export interface ProfileDetails {
  profileFirstName: string;
  profileLastName: string;
  profileEmail: string;
  profileImageUrl?: string;
  profileMenuItems: ProfileMenuItem[];
  logoutUrl: string;
}

export interface ProfileMenuItem {
  displayName: string;
  iconName: string;
  route: string;
  isSubMenu?:boolean;
}

@Component({
  selector: 'lbm-header-profile-menu',
  templateUrl: './header-profile-menu.component.html',
  styleUrls: ['./header-profile-menu.component.scss'],
  animations: [
    trigger('rotateRing', [
      state('menuOpen', style({
        transform: 'rotateX(0deg) rotateY(0deg) rotateZ(50deg)'
      })),
      state('menuClosed', style({
        transform: 'rotateX(0deg) rotateY(0deg) rotateZ(0deg)'
      })),
      transition('menuOpen => menuClosed', [
        animate('0.3s ease-in')
      ]),
      transition('menuClosed => menuOpen', [
        animate('0.3s ease-in')
      ]),
    ]),
  ]
})
export class HeaderProfileMenuComponent implements OnInit {

  isMenuOpen: boolean;
  @Input() profileDetails: ProfileDetails;
  @Output() profilItemClicked = new EventEmitter();
  profileName: string;
  profileNameInitials: string;
  selected: string;
  backTitle: string;

  constructor(private router: Router, private authConfigService: AuthConfigService) { }

  ngOnInit(): void {
    if(this.profileDetails){
      this.profileName = this.profileDetails.profileFirstName + " " + this.profileDetails.profileLastName;
      this.setProfileInitials(this.profileDetails.profileFirstName, this.profileDetails.profileLastName);
      this.selected = "";
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.hasOwnProperty('profileDetails') && changes.profileDetails.currentValue){
      this.profileName = this.profileDetails.profileFirstName + " " + this.profileDetails.profileLastName;
      this.setProfileInitials(this.profileDetails.profileFirstName, this.profileDetails.profileLastName);
      this.selected = "";
    }
  }

  setProfileInitials(firstName, lastname) {
    if(lastname)
      this.profileNameInitials = (firstName.charAt(0) + lastname.charAt(0)).toUpperCase();
    else
      this.profileNameInitials = (firstName.charAt(0)).toUpperCase();
  }

  mouseEnter() {
    this.isMenuOpen = true;
  }

  mouseLeave() {
    this.isMenuOpen = false;
  }

  menuOpened() {
    this.selected = "";
  }

  backClicked(event) {
    event.stopPropagation();
    this.selected = "";
  }
  navigateTo(event, profileMenuItem: any) {
    if (profileMenuItem.isSubMenu) {
      event.stopPropagation();
      this.selected = "subMenu";
      this.backTitle = profileMenuItem.displayName;
    } else {
      this.router.navigate([profileMenuItem.route]);
    }
    this.profilItemClicked.emit(profileMenuItem);
  }

  logout() {
    //this.router.navigate([this.profileDetails.logoutUrl]);
    if(this.authConfigService.hasValidAccessToken()){
      this.authConfigService.logout();
      sessionStorage.removeItem('loggedUserId');
    }else{
      this.router.navigate(['']).then(() => {
        window.location.reload();
      });
    }


  }
}
