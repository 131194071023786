
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule, AuthConfig, OAuthStorage , OAuthModuleConfig} from 'angular-oauth2-oidc';

import { AuthConfigService } from './auth-config.service';
import {  OAuthModuleConfigValue,OAuthModuleConfigVal } from './auth.config';

import { AppConfigService } from 'src/app/app-config/app-config.service';


const initApp = (appConfigService:AppConfigService,
   authConfigService: AuthConfigService, oAuthConfig : OAuthModuleConfig) => {
    return () => {
        return appConfigService.loadAppConfig().then(()=>{
            const splits = appConfigService.getConfig().apiBaseUrl;
            const proto = splits[0];
            const domain = splits[1];
            const url = proto + domain;
            oAuthConfig.resourceServer.allowedUrls = [url];
            return authConfigService.initAuth();
        });
      };
}

const storageFactory = (): OAuthStorage =>  {
    return sessionStorage;
}


@NgModule({
  imports: [ HttpClientModule, OAuthModule.forRoot() ],
  providers: [
     { provide : OAuthModuleConfig, useClass : OAuthModuleConfigVal },
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: AuthConfig, useValue: AuthConfig },
    { 
      provide: APP_INITIALIZER, 
      useFactory: initApp, 
      deps: [ AppConfigService, AuthConfigService, OAuthModuleConfig], 
      multi: true
    }
  ]
  
})
export class AuthConfigModule { }