import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { IllustrationData, IllustrationNames, BhModalService, BhAlertService } from 'bh-theme';
import { SharedModalComponent } from 'src/app/components/shared-modal/shared-modal.component';
import { AuthConfigService } from 'src/app/modules/auth/auth-config.service';
import { BlendsService } from 'src/app/services/blends/blends.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { CONSTANTMESSAGE, CONST_BINARY_ARRAY, CONST_BINARY_ARRAY_REVERSE } from 'src/app/shared/constants/constants-messages';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import * as XLSX from 'xlsx';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { BinaryGraphModalComponent } from '../binaryconfig-modal/binarygraph-modal.component';
import { BlendAsComponentComponent } from 'src/app/modules/components/blend-as-component/blend-as-component.component';
import { ComponentsService } from 'src/app/services/components/components.service';
import { AppConfigService } from 'src/app/app-config/app-config.service';
import { DeleteBlendsComponent } from '../delete-blends/delete-blends.component';

@Component({
  selector: 'lbm-blend-table',
  templateUrl: './blend-table.component.html',
  styleUrls: ['./blend-table.component.scss']
})
export class BlendTableComponent implements OnInit {
  @Input() selectedComponents;
  @Input() projectId;
  @Input() isLoggedUserasCreatedUser;
  @Input() componentsChanged;
  @Input() projectName;
  @Input() projectCustomerName;
  calculateClicked = false;
  isOutOfCalcPopUpRequired :boolean = true;
  outOfDateFlag = false;

  @Output() validateBlend = new EventEmitter<{}>();
  @Output() onBlendsChange = new EventEmitter<{}>();
  @Output() onBlendsSaved = new EventEmitter<any>();
  @ViewChild('fileInput', {static: false}) fileInputVariable: ElementRef;

  originalData = [];
  dataModel = [];
  dataHeaders = [];
  componentHeaders = [];
  messageDescription: string = 'Blend Description Value should not be empty.'
  messageComposition: string = 'Blends composition fractions sum should be 100.'
  editingCell = '';
  emptyState: IllustrationData = {
    illustrationName: IllustrationNames.EmptyBox,
    title: 'No Blends Added',
    desc: 'Manually add blends or import an excel file'
  }
  filteredData = [];
  showComponents = true;
  showOutput = true;
  showGraphConfiguration = false;
  startingColumns: string[] = ['total', 'Blend Description'];
  extraColumns: string[] = [ 'blendSBn', 'blendIn', 'fuelOil_LSCI_Factor'];
  endingColumns: string[] = ['ASI', 'Blend Asphaltene%', 'LSCI','minNFIT', 'maxNFIT',];
  actionColumn: string[] = ['actions']
  componentNames: string[] = [];
  displayedColumns: string[] = [...this.startingColumns];
  dataSource = new MatTableDataSource<any>([]);
  binaryGraphCleared = false;
  projectType ;
  fromProjectCompSelection;
  binaryCompChanged;
  enableNgOnChange : boolean = true;
  blendChangeFlag = false;
  calculateDisableFlag = true;
  fuelOil_LSCI_Factor_Multiplier;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private _isnewBlendAdded: boolean = false;
  get isnewBlendAdded() {
    return this._isnewBlendAdded;
  }
  set isnewBlendAdded(value: boolean) {
    this.onBlendsChange.emit({isDirty: value, data: this.dataModel, headers: this.componentHeaders, areCalculationsOutOfDate: this.calculateClicked});
    this._isnewBlendAdded = value;
  }

  isSaveClicked: boolean = false;

  blendsData: any;
  isSMEflag = false;
  showTooltipPosition = 'above';
  activeSpinner: boolean;

  constructor(
    private blendService: BlendsService,
    private projectService: ProjectService,public dialog: MatDialog,
    private bhModalService: BhModalService,
    private authConfigService: AuthConfigService,
    private _bhAlertService: BhAlertService,
    private spinnerService : SpinnerService,
    private appConfigService: AppConfigService
  ) {
    this.spinnerService.spinnerActive.subscribe(active => 
      this.toggleSpinner(active)); 
    this.isSMEflag  = this.authConfigService.checkIfSME();
  }

  ngOnInit(): void {
    this.fromProjectCompSelection = false;
    this.binaryCompChanged = false;
    this.isOutOfCalcPopUpRequired = true;
    this.isSMEflag  = this.authConfigService.checkIfSME();
    this.editingCell = '';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      return data['Blend Description'].toLowerCase().includes(filter.toLowerCase());
    };
    this.getComponentsHeaders(false);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  toggleSpinner(active){
    this.activeSpinner = active
  }

  getProjectBlends(fromComponents = false) {
    this.projectService.getProjectById(this.projectId).subscribe((data: any) => {
      this.projectService.getBlendsForProject(this.projectId).subscribe((result: any) => {
        if (result) {          
          this.projectType = data.projectType;
          this.fuelOil_LSCI_Factor_Multiplier = data.fuelOil_LSCI_Factor_Multiplier && data.fuelOil_LSCI_Factor_Multiplier !=null ? data.fuelOil_LSCI_Factor_Multiplier : this.appConfigService.getConfig().FUELOIL_LSCI_FACTOR_MULTITPLIER
          if(data.projectType === "B" ) {
            if(!this.fromProjectCompSelection){
              this.switchToGraphConfiguration(true);
              this.isnewBlendAdded = false;
            }
          } else {
            this.clearGraphConfiguration();
            let blendsData = [];
            let resultBlends = result.blends || [];
            this.originalData = resultBlends;
            for (let blend of resultBlends) {
              let blendObject = {};
              if(this.isSMEflag) {
                blendObject = {
                  "total": 0,
                  "Blend Description": blend['description'],
                  "LSCI": blend['outputs']['LSCI'],
                  "ASI": blend['outputs']['blendASI'],
                  "Blend Asphaltene%": blend['outputs']['blendAsphaltenePercentage'],
                  "minNFIT":blend['outputs']['minNFIT'],
                  "maxNFIT":blend['outputs']['maxNFIT'],
                  "blendSBn":blend['blendSolubilityParams']['blendSBn'],
                  "blendIn":blend['blendSolubilityParams']['blendIn'],
                  "fuelOil_LSCI_Factor":blend['blendSolubilityParams']['fuelOil_LSCI_Factor'],
                  "labASI_Measure":blend['labASI_Measure'],
                  "nFactor": blend['outputs']['nFactor']
                };
              } else {
                blendObject = {
                  "total": 0,
                  "Blend Description": blend['description'],
                  "LSCI": blend['outputs']['LSCI'],
                  "ASI": blend['outputs']['blendASI'],
                  "Blend Asphaltene%": blend['outputs']['blendAsphaltenePercentage'],
                  "minNFIT":blend['outputs']['minNFIT'],
                  "maxNFIT":blend['outputs']['maxNFIT'],
                  "labASI_Measure":blend['labASI_Measure'],
                  "nFactor": blend['outputs']['nFactor']
                };
              }
  
              this.componentHeaders.forEach(res => {
                if (blend.composition.hasOwnProperty(res.id)) {
                  let listValue = blend.composition[res.id] * 100;
                  if(listValue.toString().indexOf('.') > -1){
                    listValue = parseFloat(listValue.toFixed(2))
                  }
                  blendObject[res.listName] = +listValue;
                  blendObject['total'] += (blendObject[res.listName]);
                }
                else {
                  blendObject[res.listName] = 0;
                }
              });
              blendsData.push(blendObject);
            }
            this.isnewBlendAdded = false;
            this.dataModel = blendsData;
            this.blendsData = this.dataModel;
            this.dataSource.data = this.dataModel;
            this.setColumns();
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            if(this.enableNgOnChange &&  this.isLoggedUserasCreatedUser && this.isOutOfCalcPopUpRequired && result['blends'].length > 0 && ((data && data['areCalculationsOutOfDate'] === true  ) ||  (fromComponents && this.componentsChanged))){
              this.calculateDisableFlag = false;
              this.outOfDateFlag = true;
              this.dialog.open(ConfirmModalComponent, {
                data: {
                  title: 'Blends have changed',
                  content: 'Calculations must be updated',
                  showOk: true
                }
              });

              let savedOutOfCalcFlag = data ? (data['areCalculationsOutOfDate'] ? data['areCalculationsOutOfDate'] : false) : false ;
              if (!savedOutOfCalcFlag  ) {
                let patchArr = [                
                  { "op": "replace", "path": "/areCalculationsOutOfDate", "value": true },
                ]
                this.projectService.updateBaseComponent(this.projectId, patchArr).subscribe((result: any) => {                
              });
            }
              
            }
              if(!this.enableNgOnChange){
                this.enableNgOnChange = true;
              }
            
          }
        }
        this.fromProjectCompSelection = false;        
      })
    });
  }

  getTotal(total){
    if(total.toString().indexOf('.') > -1){
      return parseFloat(total.toFixed(2));
    } else {
      return total;
    }
  }
  
  ngOnChanges() 
  {    
    this.getComponentsHeaders(true);
  } 

  clearGraphConfiguration() {
    this.dataSource.data = [];
    this.binaryGraphCleared = true;
    this.dataModel = [];
    this.showGraphConfiguration = false;
    this.setColumns();
    this.onBlendsChange.emit({isDirty: true, data: this.dataModel, headers: this.componentHeaders, areCalculationsOutOfDate: this.calculateClicked});
  }

  switchToGraphConfiguration(setInitial?) {
    console.log(this.dataSource.data);
    if(this.dataSource.data.length && !setInitial && this.projectType !== 'B') {
      let dialogRef = this.dialog.open(SharedModalComponent, {
        data: {
          title : '',
          content: 'Already added blends will be lost. Do you want to continue?',
          cancel: 'Cancel',
          save: 'Continue',
          showSaveCancel: true
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if(res === 'agree') {
          let blendsGraphData = [];
          if (this.componentHeaders.length === 2) {
            let componentName1 = this.componentHeaders[0].listName;
            let componentName2 = this.componentHeaders[1].listName;
            for (let p = 0, i = 0; p <= 100; p += 5, i++) {
              let blendObject = {
                "total": 100,
                [componentName1]: 100 - p,
                [componentName2]: p,
                "Blend Description": 'Blend' + (i + 1),
                "LSCI": 0,
                "ASI": 0,
                "Blend Asphaltene%": 0,
                "minNFIT": 0,
                "maxNFIT": 0,
                "blendSBn": 0,
                "blendIn": 0,
                "fuelOil_LSCI_Factor": 0,
                "labASI_Measure": null,
                "nFactor" : ""
              };
              blendsGraphData.push(blendObject);
            }
          }
  
          this.showGraphConfiguration = true;
          this.setColumns();
          this.dataModel = blendsGraphData;
          this.dataSource.data = this.dataModel;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isOutOfCalcPopUpRequired = true;
          if(setInitial) {
            let patchArr = [
              { "op": "replace", "path": "/projectType", "value": "B" },
              { "op": "replace", "path": "/areCalculationsOutOfDate", "value": false }
            ]
            this.projectService.updateBaseComponent(this.projectId, patchArr).subscribe((result: any) => {
              this.calculate(setInitial);
            });
          } else {
            this.calculate();
          }
      } else {
    }
    });
    } else {
      let blendsGraphData = [];
      if (this.componentHeaders.length === 2) {
        let componentName1 = this.componentHeaders[0].listName;
        let componentName2 = this.componentHeaders[1].listName;
        for (let p = 0, i = 0; p <= 100; p += 5, i++) {
          let blendObject = {
            "total": 100,
            [componentName1]: 100 - p,
            [componentName2]: p,
            "Blend Description": 'Blend' + (i + 1),
            "LSCI": 0,
            "ASI": 0,
            "Blend Asphaltene%": 0,
            "minNFIT": 0,
            "maxNFIT": 0,
            "blendSBn": 0,
            "blendIn": 0,
            "fuelOil_LSCI_Factor": 0,
            "labASI_Measure": null,
            "nFactor" : ""
          };
          blendsGraphData.push(blendObject);
        }
      }

      this.showGraphConfiguration = true;
      this.setColumns();
      this.dataModel = blendsGraphData;
      this.dataSource.data = this.dataModel;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      if(setInitial) {
        let patchArr = [
          { "op": "replace", "path": "/projectType", "value": "B" },
          { "op": "replace", "path": "/areCalculationsOutOfDate", "value": false }
        ]
        this.projectService.updateBaseComponent(this.projectId, patchArr).subscribe((result: any) => {
          this.calculate(setInitial);
        });
      } else {
        this.calculate();
      }
    }

    
   
  }

  isStickyEndColumn(column: string) {
    return this.endingColumns.includes(column);
  }

  toggleOutput() {
    this.editingCell = '';
    this.showOutput = !this.showOutput;
    this.setColumns();
  }

  toggleComponents() {
    this.editingCell = '';
    this.showComponents = !this.showComponents;
    this.setColumns();
  }

  setColumns() {
    let columnList = [...this.startingColumns];
    if (this.showComponents) {
      columnList = [...columnList, ...this.componentNames];
    }
    if (this.showOutput) {
      columnList = [...columnList, ...this.endingColumns];
    }
    if(this.showOutput && this.isSMEflag) {
      columnList = [...columnList, ...this.extraColumns];
    }
    if (!this.showGraphConfiguration) {
      // action column
      columnList = [...columnList, ...this.actionColumn];
    }
    this.displayedColumns = columnList;
  }

  getComponentsHeaders(getBlends = false) {
    if (this.selectedComponents) {
      this.componentHeaders = this.selectedComponents;
      this.componentNames = this.componentHeaders.map(res => res.listName);
      this.setColumns();
      this.dataSource.data = this.dataModel;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;      
    }
    if(getBlends && this.selectedComponents.length > 0){
      this.getProjectBlends(true);
    }
  }

  onFileChange(event: any) {
    this.spinnerService.activate();
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      this.validateBlend.emit({ success: false, message: 'Cannot use multiple files' });
      this.fileInputVariable.nativeElement.value = "";   
      this.spinnerService.deactivate();   
    }else{      
      this.projectService.uploadBlendSchedules(this.projectId,target.files[0] ).subscribe((result: any) => {        
        this.loadBlendScheduleData(result);
        this.blendChangeFlag = true;
      },
      err => {
        console.log(err);
        let errorMsg = "Could not upload blend schedule" ;
        if(err.error != null && err.error.code != null && err.error.code === "INVALID_VALUES"){
          errorMsg = err.error.description ;
        }
        this.validateBlend.emit({ success: false, message: errorMsg });
        this.fileInputVariable.nativeElement.value = "";
        this.spinnerService.deactivate();   
      });    
  }
 }

 loadBlendScheduleData(data){
    let arr = [];  
    this.showGraphConfiguration = false;
    this.setColumns();          
    const compNames:any =  data.componentNames;
    const blends:any =  data.blends;
    if(blends && blends.length > 150){
        this.validateBlend.emit({ success: false, message: 'More than 150 blends not allowed to import' });
        this.fileInputVariable.nativeElement.value = "";
    }else if (blends && blends.length == 0){
      this.validateBlend.emit({ success: false, message: 'Blends data is not available to import' });
      this.fileInputVariable.nativeElement.value = "";
    }else{
      let calculationTotal = false;
      blends.forEach((blend) => {
      let json;
        if(this.isSMEflag) {
          json = {
                "total": 0,
                "Blend Description": "",
                "LSCI": "",
                "ASI": "",
                "Blend Asphaltene%": "",
                "minNFIT":"",
                "maxNFIT":"",
                "blendSBn":"",
                "blendIn":"",
                "fuelOil_LSCI_Factor":"",
                "labASI_Measure":"",
                "nFactor": ""
              };
        } else {
          json = {
                "total": 0,
                "Blend Description":"",
                "LSCI":"",
                "ASI": "",
                "Blend Asphaltene%": "",
                "minNFIT":"",
                "maxNFIT":"",
                "labASI_Measure":"",
                "nFactor": ""
          };
        }
        compNames.forEach(header => {
          json[header] = 0;
        });
          
        json['Blend Description'] = blend.name;
        const compositions:any = blend.composition;
        let sum = 0;
        compositions.forEach((row,index) => {      
          if(compNames[index]) {
            row = row.toString().indexOf('.') > - 1 ? parseFloat(row.toFixed(2)) : row ;
            json[compNames[index]] = +row;
            sum += row;
          }      
        })
        json.total = sum.toString().indexOf('.') > - 1 ? parseFloat(sum.toFixed(2)) : sum;
        arr.push(json);
        if((json.total < 90 ) || (json.total > 110)) {
          calculationTotal = true;
        }
      })
    

      this.dataModel = arr;
      this.dataSource.data = this.dataModel;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.fileInputVariable.nativeElement.value = "";
      if(this.dataModel.length > 0){
       this.isnewBlendAdded = true;
       this.blendChangeFlag = true;
       this.calculateDisableFlag = calculationTotal;
       this.onBlendsChange.emit({isDirty: true, data: this.dataModel, headers: this.componentHeaders, areCalculationsOutOfDate: this.calculateClicked});
      }
    }
    this.spinnerService.deactivate();   

 }

 
 getLSCI(val){
   if(val === ''){ return; }
   if(val <= 0){
     return 'severe';
   } else if(val > 0 && val < 1){
     return 'high';
   } else if(val >= 1 && val < 2){
     return 'moderate';
   } else if(val >= 2){
     return 'low';
   }
 }
 getTotalColor(val){
  if(val === ''){ return; }
  if((val >= 96 && val < 98 ) || (val > 102 && val <= 104)){
    return 'cautious';
   } else if((val >= 90 && val < 96) || (val > 104 && val <= 110)){
    return 'warning';
   } else if(val < 90 || val > 110){
    return 'not-acceptable';
   }
   else if(val >= 98 && val <= 102){
     return 'acceptable';
   }
}
 saveBlendsContinue(data?,tabs?, tab ?, header ?, index?) {
  if (this.dataModel.length === 0){
    const blendsData = {
      "blends": [],
    };

    this.projectService.saveBlendsForProject(this.projectId, blendsData).subscribe((result: any) => {
      this.isnewBlendAdded = false;
      this.isSaveClicked = true;
      this.editingCell = '';
      this.blendChangeFlag = false;
      this.onBlendsSaved.emit();
      this.validateBlend.emit({ success: true, message: 'Saved Blends Successfully.' });
      this.isOutOfCalcPopUpRequired = true;
      let patchArr = [
        { "op": "replace", "path": "/projectType", "value": !this.showGraphConfiguration ? "N" : "B" },
        { "op": "replace", "path": "/areCalculationsOutOfDate", "value": !this.calculateClicked }
      ]
      this.projectService.updateBaseComponent(this.projectId, patchArr).subscribe((result: any) => {
        this.getProjectBlends();
        this.redirecttoOtherTabs(tabs, tab, header, index);
      });
    });
    return;
  }
  const validData = this.validateBlendData();
  if (validData.invalid) {
    this.validateBlend.emit({ success: false, message: validData.invalidMessage });
  } else {
    const blendsData = {
      "blends": [],
    };
      this.dataModel.forEach(element => {
        if (element['Blend Description'].toString().trim().length > 0) {
          const blendItem = {
            "blendSolubilityParams" : {
              "blendIn": element['blendIn'],
              "blendSBn": element['blendSBn'],
              "fuelOil_LSCI_Factor": element['fuelOil_LSCI_Factor'],
            },
            "composition": {
            },
            "description": element['Blend Description'],
            "labASI_Measure": null,
            "outputs": {
              "LSCI": +element['LSCI'],
              "blendASI": +element['ASI'],
              "blendAsphaltenePercentage": +element['Blend Asphaltene%'],
              "definedIncompatibility": "",
              "maxNFIT": element['maxNFIT'],
              "minNFIT": element['minNFIT'],
              "nFactor": element['nFactor']
            }
          };
          this.componentHeaders.forEach(item => {
            blendItem['composition'][item.id] = +element[item.listName] / 100;
          });
          blendsData.blends.push(blendItem);
        }
      });
    if(this.binaryGraphCleared) {
      this.binaryGraphCleared = false;
    }


    this.projectService.saveBlendsForProject(this.projectId, blendsData).subscribe((result: any) => {
      this.isnewBlendAdded = false;
      this.isSaveClicked = true;
      this.editingCell = '';
      this.blendChangeFlag = false;
      this.onBlendsSaved.emit();
      this.validateBlend.emit({ success: true, message: 'Saved Blends Successfully.' });
      this.isOutOfCalcPopUpRequired = true;
      let patchArr ;
      if(this.showGraphConfiguration)  {
        patchArr = [
          { "op": "replace", "path": "/projectType", "value": "B" },
          { "op": "replace", "path": "/areCalculationsOutOfDate", "value": false },
        ]
      } else {
        patchArr = [
          { "op": "replace", "path": "/projectType", "value": "N" },
          { "op": "replace", "path": "/areCalculationsOutOfDate", "value": !this.calculateClicked },
        ]
      }
      this.projectService.updateBaseComponent(this.projectId, patchArr).subscribe((result: any) => {
        this.getProjectBlends();
        this.redirecttoOtherTabs(tabs, tab, header, index);
      });
    });
  }
 }

 redirecttoOtherTabs(tabs, tab, header, index) {
  if(tabs) {
    const handleTabClick = tabs._handleClick;
    handleTabClick.apply(tabs, [tab, header, index]);
  }
 }

 saveBlends(data?,tabs?, tab?, header? ,index ?) {
   this.checkIfBinaryGraphConfigMatches(data, tabs, tab, header, index);
 }

  addNewBlend() {
    this.calculateClicked = false;
    this.isnewBlendAdded = true;
    this.blendChangeFlag = true;
    this.calculateDisableFlag = false;
    this.editingCell = '';
    let blendObject = {};
    if(this.isSMEflag) {
      blendObject = {
        "total": 0,
        "Blend Description": "",
        "LSCI": "",
        "ASI": "",
        "Blend Asphaltene%": "",
        "maxNFIT":"",
        "minNFIT":"",
        "blendIn":"",
        "blendSBn":"",
        "fuelOil_LSCI_Factor":"",
        "labASI_Measure":"",
        "nFactor": ""
      };
    }else {
      blendObject = {
        "total": 0,
        "Blend Description": "",
        "LSCI": "",
        "ASI": "",
        "Blend Asphaltene%": "",
        "maxNFIT":"",
        "minNFIT":"",
        "labASI_Measure":"",
        "nFactor": ""
      };
    }

    let components = this.componentHeaders.map(res => res.listName);
    components.forEach(res => blendObject[res] = 0);
    this.dataModel = [...this.dataModel, blendObject];
    this.dataSource.data = this.dataModel;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  checkFieldModify(event){
    if(!this.isnewBlendAdded){
      this.isnewBlendAdded = true;
      this.blendChangeFlag = true;
    }
    this.onBlendsChange.emit({isDirty: true, data: this.dataModel, headers: this.componentHeaders, areCalculationsOutOfDate: this.calculateClicked});
  }

  editCell(cellName, i) {
    this.editingCell = cellName + i;
  }

  deleteBlend(element) {
    this.dataModel = this.dataModel.filter(item => item !== element);
    this.dataSource.data = this.dataModel;
    this.isnewBlendAdded = true;
    this.blendChangeFlag = true;
    this.onBlendsChange.emit({isDirty: true, data: this.dataModel, headers: this.componentHeaders, areCalculationsOutOfDate: this.calculateClicked});
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this._bhAlertService.openSnackBar({
      type: "success",
      message: CONSTANTMESSAGE.DELETE_BLEND,
      subtitle: "",
      autodismiss: true,
      duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
    });
  }

  createCrude() {

  }
  previousValue = 0;
  onElementFocus(event) {
    this.previousValue = event.target.value;    
  }
  checkMaxLength(event,element,name,index) {
      if(event.target.value > 999 ) {
        element[name] = this.previousValue;
        event.preventDefault();
      }else if(event.target.value < 0) {
        element[name] = 0;
      } else if (event.target.value && event.target.value.toString().indexOf('.') > -1  
                && this.countDecimals(event.target.value) > 2){       
        element[name] = this.previousValue;
        event.preventDefault();    
      }else if (event.target.value && event.target.value == 0 && event.target.value.length > 3){
        element[name] = this.previousValue;
        event.preventDefault();
      }else {       
        this.previousValue = event.target.value;       
        
        let sum = 0;
        this.componentHeaders.forEach(item => {
          sum += +element[item.listName];
        });
        element.total = sum.toString().indexOf('.') > - 1 ? parseFloat(sum.toFixed(2)) : sum;
        // element.total = (sum);
        if( !this.isnewBlendAdded) { // element.total === 100 &&
          this.isnewBlendAdded = true;
          this.blendChangeFlag = true;
        } 
        if (element.total >= 90 && element.total <= 110) {
          this.calculateDisableFlag = false;
        }
        // else if(element.total > 100) {
        //   this.isnewBlendAdded = false;
        // }
        this.calculateClicked = false;
        this.onBlendsChange.emit({isDirty: true, data: this.dataModel, headers: this.componentHeaders, areCalculationsOutOfDate: this.calculateClicked});
      }
  }

  countDecimals(value) { 
   return value.toString().split(".")[1].length;    
    }

  checkEmpty(event, element, name) {
    if (event.target.value === '' || event.target.value === null) {
      element[name] = 0;
    }
  }

  checkComponentsVal() {
    if(this.showGraphConfiguration) {
      return true;
    }
     else {
      if (this.dataSource.data.length === 0) {
        return true;
      } else if (this.calculateDisableFlag) {
        return true;
      } 
      else {
        let invalid = false;
        this.dataModel.forEach(element => {
          let sum = 0;
          this.componentHeaders.forEach(item => {
            sum += +element[item.listName];
            if (+element[item.listName] < 0) {
              invalid = true;
            }
          });
          if (!invalid) {
            if (sum < 90 || sum > 110) {
              invalid = true;
            }
          }
        });
        if (invalid) {
          return invalid
        } else {
          return false;
        }
      } 
    }
  }

  importComponents() {
    if (this.dataModel.length > 0) {
      const title = 'Import Components';
      const message = 'All blends in table will be removed and replaced by the content in excel. Agree to import?';
      this.bhModalService.openModal(
        message,
        () => {
          this.dataModel = [];
          this.dataSource.data = this.dataModel;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.bhModalService.closeModal();
          this.importFromExcel();
        },
        title
      );
    }
    else {
      this.importFromExcel();
    }
  }

  importFromExcel() {
    this.fileInputVariable.nativeElement.click();
  }

  downloadComponents(){
    this.blendService.downloadTemplate(this.projectId, this.projectName);
  }

  searchTable(event) {
    this.dataSource.filter = event.target.value;
  }

  calculate(setInitial?) {
    this.spinnerService.activate();
    const validData = this.validateBlendData();
    if (validData.invalid) {
      this.validateBlend.emit({ success: false, message: validData.invalidMessage });
      this.spinnerService.deactivate();
    } else {
      const blendsData =
      {
        "blends": [],
        "projectId": 0
      };
      this.dataModel.forEach(element => {
        if (element['Blend Description'].toString().trim().length > 0) {
          const blendItem = {
            "composition": {
              // "11": 0.4,
              // "12": 0.6
            },
            "description": "",
            "labASI_Measure": null
          };
          blendItem['description'] = element['Blend Description'];
          this.componentHeaders.forEach(item => {
            blendItem['composition'][item.id] = +element[item.listName] / 100;
          });
          blendsData.blends.push(blendItem);
        }
      });

      blendsData.projectId = this.projectId;
      this.blendService.GetCalculation(blendsData).subscribe((output: any) => {
        this.outOfDateFlag = false;
        output.blends.forEach((item, i) => {
          this.calculateDisableFlag = true;
          const outputValues = item.outputs;
          this.dataModel[i]['Blend Asphaltene%'] = outputValues['blendAsphaltenePercentage'];
          this.dataModel[i]['LSCI'] = outputValues['LSCI'];
          this.dataModel[i]['ASI'] = outputValues['blendASI'];
          this.dataModel[i]['maxNFIT'] = outputValues['maxNFIT'];
          this.dataModel[i]['minNFIT'] = outputValues['minNFIT'];
          this.dataModel[i]['nFactor'] = outputValues['nFactor'];
          const blendSolubilityParams = item.blendSolubilityParams;
          if(blendSolubilityParams) {
            this.dataModel[i]['blendIn'] = blendSolubilityParams['blendIn'];
            this.dataModel[i]['blendSBn'] = blendSolubilityParams['blendSBn'];
            this.dataModel[i]['fuelOil_LSCI_Factor'] = blendSolubilityParams['fuelOil_LSCI_Factor'];
          }
          if(!setInitial) {
            this.validateBlend.emit({ success: true, message: 'Calculated Blends Successfully.' });
          }
        });        
        if(setInitial) {
          if(this.binaryCompChanged){
            const blendsData1 = {
              "blends": [],
            };
            this.dataModel.forEach(element => {
              if (element['Blend Description'].toString().trim().length > 0) {
                const blendItem1 = {
                  "blendSolubilityParams" : {
                    "blendIn": element['blendIn'],
                    "blendSBn": element['blendSBn'],
                    "fuelOil_LSCI_Factor": element['fuelOil_LSCI_Factor'],
                  },
                  "composition": {
                  },
                  "description": element['Blend Description'],
                  "labASI_Measure": null,
                  "outputs": {
                    "LSCI": +element['LSCI'],
                    "blendASI": +element['ASI'],
                    "blendAsphaltenePercentage": +element['Blend Asphaltene%'],
                    "definedIncompatibility": "",
                    "maxNFIT": element['maxNFIT'],
                    "minNFIT": element['minNFIT'],
                    "nFactor": element['nFactor']
                  }
                };
                this.componentHeaders.forEach(item => {
                  blendItem1['composition'][item.id] = +element[item.listName] / 100;
                });
                blendsData1.blends.push(blendItem1);
              }
            }); 

            this.projectService.saveBlendsForProject(this.projectId, blendsData1).subscribe((result: any) => {
              this.isnewBlendAdded = false;
              this.isSaveClicked = true;
              this.editingCell = '';
              this.blendChangeFlag = false;
              this.onBlendsSaved.emit();             
                          
            });
          this.binaryCompChanged = false;
            }
        }
        this.spinnerService.deactivate();
      });
      
      if(!setInitial) {
        this.calculateClicked = true;
        this.isnewBlendAdded = true;
        this.blendChangeFlag = true;
        this.onBlendsChange.emit({isDirty: true, data: this.dataModel, headers: this.componentHeaders, areCalculationsOutOfDate: this.calculateClicked});
      }
      
    }
  }

  validateBlendData() {
    let invalid = false;
    let invalidMessage = '';
    let sum = 0;
    this.dataModel.forEach(element => {
      sum = 0;
      if (element['Blend Description'].toString().trim().length === 0) {
        invalid = true;
        invalidMessage = this.messageDescription;
      }
      this.componentHeaders.forEach(item => {
        sum += +element[item.listName];
      });
    });


    return { invalid, invalidMessage }
  }

  checkSaveEnable () {
    let disableSave;      
    if(!this.blendChangeFlag) {
      disableSave = true;
    } else {
      disableSave = false;
    }
    return disableSave;
  }

  checkIfBinaryGraphConfigMatches(data?,tabs?, tab?, header? , index ?) {
    if(!this.showGraphConfiguration && this.componentHeaders && this.componentHeaders.length == 2 && this.dataModel && this.dataModel.length === 21) {
      let resultArrayComp1 = this.dataModel.map(res => res[this.componentHeaders[0].listName]);
      resultArrayComp1 = resultArrayComp1.map(res => parseInt(res));
      if(CONST_BINARY_ARRAY == JSON.stringify(resultArrayComp1) ) {
        let resultArrayComp2 = this.dataModel.map(res => res[this.componentHeaders[1].listName]);
        resultArrayComp2 = resultArrayComp2.map(res => parseInt(res));
        if(CONST_BINARY_ARRAY_REVERSE == JSON.stringify(resultArrayComp2)) {
          this.showBinaryGraphModal(data);
        }  else {
          this.saveBlendsContinue(data, tabs, tab, header, index);
        }
      } else {
        this.saveBlendsContinue(data,tabs, tab, header, index);
      }
    } else {
      this.saveBlendsContinue(data,tabs, tab, header, index);
    }
  }

  showBinaryGraphModal(data?) {
    let dialogRef = this.dialog.open(BinaryGraphModalComponent, {
      width: "800px",
      data: null,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if(res === 'agree') {
        this.switchToGraphConfiguration();
      } else {
        this.saveBlendsContinue(data);
      }
    });
  }

    
  //Blend as Component
  checkBlendPercentage(element) {
    let invalid = false;
     let sum = 0;
    
       sum += +element.total;
    
     if ((sum < 90 || sum > 110) || element.LSCI == '' || element.ASI == '' || element['Blend Asphaltene%'] == '') {
       invalid = true;
     }
     //console.log("Blend Total ::: "+element.total);
     return invalid;
   }


  createBlendAsComponent(element) {
    // alert("inside create crude method"+ element)
     console.log("tot == "+element.total)
     console.log("asi == "+element.ASI)
     console.log("cust name == "+this.projectCustomerName)
 
 
    if(element.ASI == '' || element['Blend Description']== '' || element['nFactor'] == '' || element['nFactor'] === undefined){
     this.dialog.open(ConfirmModalComponent, {
       data: {
         title: 'Input Fields',
         content: 'Input Field values are missing for Save as a Component',
         showOk: true
       }
     });
    }
 
   else if((element.ASI < -20 || element.ASI > 600) || (element['Blend Asphaltene%'] < 0 || element['Blend Asphaltene%'] > 25) || (element['nFactor'] < 1.385 || element['nFactor'] > 1.65)){
     this.dialog.open(ConfirmModalComponent, {
       data: {
         title: 'Invalid Blend',
         content: 'Invalid Blend Values for Save as a Component',
         showOk: true
       }
     });
   }
   else{
 
   let data = {
     componentName: element['Blend Description'],
     ASI : element.ASI,
     nFactor : element['nFactor'],
     asphaltenePercentage :  element['Blend Asphaltene%'],
     nTemp : 20,
     dataType : "SAVED BLEND",
     dataSource : this.projectCustomerName,
     componentType : ""
   };
   data['componentName'] = element['Blend Description'];
   data['ASI'] = element.ASI;
   data["nFactor"] = element['nFactor'];
   data["asphaltenePercentage"] = element['Blend Asphaltene%']
   data['nTemp']= 20;
   data['dataType']= "SAVED BLEND";
   data['dataSource']= this.projectCustomerName;
 
   console.log("json data == "+ data);
 
     this.selectComponentType(element['Blend Description'], data, element);
   }
 
 
   
 }

  selectComponentType(blendDescription, json, element) {
    let savedBlends = {
      blendComposition: {},
      fuelOil_LSCI_Factor_Multiplier: this.fuelOil_LSCI_Factor_Multiplier
    }
    this.componentHeaders.forEach(res => {
      savedBlends.blendComposition[res['id']] =  element[res['listName']] !== 0 ? element[res['listName']] / 100 : 0;
    });
    console.log(savedBlends);
    let dialogRef = this.dialog.open(BlendAsComponentComponent, {
      width: "400px",
      data: {blendName: blendDescription,json: json, projectId: this.projectId, savedBlends:savedBlends},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {

  
    });
  }

  deleteAllBlends(){

    if (this.dataModel.length > 0){
      let dialogRef = this.dialog.open(DeleteBlendsComponent, {
        width: "530px",
        data: {
          delete: false          
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => { 
        
        if (result['delete']) {

          this.dataModel = [];
          this.dataSource.data = this.dataModel;
          this.isnewBlendAdded = true;
          this.blendChangeFlag = true;
          this.onBlendsChange.emit({isDirty: true, data: this.dataModel, headers: this.componentHeaders, areCalculationsOutOfDate: this.calculateClicked});
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          this._bhAlertService.openSnackBar({
          type: "success",
          message: CONSTANTMESSAGE.DELETE_BLENDS,
          subtitle: "",
          autodismiss: true,
          duration: CONSTANTMESSAGE.TOASTER_MESSAGE_DURATION,
          });        
        } 
      });      
    }else{
      this.validateBlend.emit({ success: false, message: 'Blends are not available to delete' });
    }
  }

}

