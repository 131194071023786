// import {DeviceInfoModel} from './../models/deviceInfo.model';
// import { TagInfoModel } from './../models/tagInfo.model';

export function getCurrentUTCDate() {
  let today = new Date();
  return (
    formatTwoDigitString(today.getUTCDate()) +
    " " +
    formatMonthString(today.getUTCMonth()) +
    " " +
    today.getUTCFullYear() +
    " " +
    formatTwoDigitString(today.getUTCHours()) +
    ":" +
    formatTwoDigitString(today.getUTCMinutes()) +
    ":" +
    formatTwoDigitString(today.getUTCSeconds()) +
    " UTC"
  );
}

export function formatTwoDigitString(num): string {
  return num.toString().length > 1 ? num : "0" + num.toString();
}

export function formatMonthString(month): string {
  let monthString = "";
  switch (month + 1) {
    case 1:
      monthString = "Jan";
      break;
    case 2:
      monthString = "Feb";
      break;
    case 3:
      monthString = "Mar";
      break;
    case 4:
      monthString = "Apr";
      break;
    case 5:
      monthString = "May";
      break;
    case 6:
      monthString = "June";
      break;
    case 7:
      monthString = "July";
      break;
    case 8:
      monthString = "Aug";
      break;
    case 9:
      monthString = "Sept";
      break;
    case 10:
      monthString = "Oct";
      break;
    case 11:
      monthString = "Nov";
      break;
    case 12:
      monthString = "Dec";
      break;
  }
  return monthString;
}

export function formatDate(dateReceived) {
  let currentDate = new Date(dateReceived.split("+")[0]);
  return (
    formatTwoDigitString(currentDate.getDate()) +
    " " +
    formatMonthString(currentDate.getMonth()) +
    " " +
    currentDate.getFullYear() +
    " " +
    formatTwoDigitString(currentDate.getHours()) +
    ":" +
    formatTwoDigitString(currentDate.getMinutes()) +
    ":" +
    formatTwoDigitString(currentDate.getSeconds()) +
    " UTC"
  );
}

export function removedDuplicates(inputArray, mainArray) {
  let finalArray = [];
  if(mainArray.length === 0) {
    finalArray = inputArray;
  } else {
    inputArray.map(res => {
      let receivedObject = mainArray.filter(element => res.id === element.id);
      if(receivedObject && receivedObject.length) {
  
      } else {
        finalArray.push(res);
      }
    });
  }

  return finalArray;
}