import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { ComponentsService } from "src/app/services/components/components.service";
import { ProjectService } from "src/app/services/project/project.service";
import { AddComponentComponent } from "src/app/modules/components/add-component/add-component.component";
import { AuthConfigService } from "src/app/modules/auth/auth-config.service";
import { AddAssayComponent } from "src/app/modules/components/add-assay-component/add-assay-component.component";
import { SelectComponentTypeComponent } from "src/app/modules/components/select-component-type/select-component-type.component";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { SharedModalComponent } from 'src/app/components/shared-modal/shared-modal.component';
import {removedDuplicates} from "./../../../../shared/helpers/helpers";


@Component({
  selector: "ple-select-project",
  templateUrl: "./select-projects.component.html",
  styleUrls: ["./select-projects.component.scss"],
})


export class SelectProjectsComponent implements OnInit {

  displayedColumns: string[] = ['tag','select', 'componentName', 'createdOn',  'dataType', 'ASI', 'asphaltenePercentage', 'componentType'];
  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(true, []);
  selectedRowIndex: number;
  initialSelectedIds: number[] = [];
  selectionChanged = false;
  projectId: number;
  isCreateCompClicked: boolean = false;
  deletedComponents;
  deletedComponentsArray;
  searchText = '';
  dataModel = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedIds = [];
  isSMERole = false;
  activeSpinner: boolean;
  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectProjectsComponent>,
    private componentsService: ComponentsService,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private authConfigService: AuthConfigService,
    private spinnerService : SpinnerService
  ) {
    this.spinnerService.spinnerActive.subscribe(active => 
      this.toggleSpinner(active)); 
    this.isSMERole  = this.authConfigService.checkIfSME();
    if (this.isSMERole) {
      this.displayedColumns = ['tag','select', 'componentName', 'createdOn', 'dataType', 'ASI', 'asphaltenePercentage', 'componentType','nFactor', 'nTemp','correctedNFactor', 'SBn', 'In', 'fuelOilLSCIFactor','ASI_Method','notes', 'createdBy','approvedBy'];
    }
  }

  toggleSpinner(active){
    this.activeSpinner = active
  }

  ngOnInit(): void {
    this.spinnerService.activate();
    this.projectId = this.data?.projectId;
    this.selectedIds = this.data?.selectedIds || [];
    this.initialSelectedIds = this.selectedIds;
    this.deletedComponents = this.data?.selectedComponentList;       
    
    this.componentsService.GetComponents().subscribe((componentData: any) => {
      console.log('GetComponents', componentData);
      let localOrgComponents = removedDuplicates(componentData['localCompoToOrgUsers'], componentData['createdComponents']);
      if (componentData && componentData.data) {
        this.deletedComponentsArray = [];
        if (this.deletedComponents && this.deletedComponents.length) {
          let deletedIdArray = this.deletedComponents.map(res => res.id);
          let totolDataArray = [...componentData['data'],...componentData['createdComponents'], ...localOrgComponents].map(res => res.id);
          deletedIdArray = deletedIdArray.filter(x => !totolDataArray.includes(x));
          deletedIdArray.map ( item => {
            this.deletedComponentsArray.push(this.deletedComponents.find(res=> res.id === item ));
          });
          this.dataSource.data = [...componentData['data'],...componentData['createdComponents'], ...localOrgComponents, ...this.deletedComponentsArray];
          this.dataModel =  [...componentData['data'],...componentData['createdComponents'], ...localOrgComponents, ...this.deletedComponentsArray];
        } else {
          this.dataSource.data = [...componentData['data'],...componentData['createdComponents'],  ...localOrgComponents];
          this.dataModel = [...componentData['data'],...componentData['createdComponents'],  ...localOrgComponents];
        }        
        const rowsToSelect = this.dataSource.data.filter(item => this.selectedIds.includes(item.id));
        rowsToSelect.forEach(row => this.selection.select(row));
        rowsToSelect.forEach(row => {
          this.dataSource.data = this.dataSource.data.filter(r => r.id !== row.id);
          this.dataSource.data = [row].concat(this.dataSource.data);         
        });
        // this.dataSource.sort = this.sort;
        // this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
        this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
          if (typeof data[sortHeaderId] === 'string') {
            return data[sortHeaderId].toLocaleLowerCase();
          }
          return data[sortHeaderId];
        };
      }
      this.spinnerService.deactivate();
    });
  }  

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator; 
    this.dataSource.sort = this.sort;
  }


  createComponent(item) {
    let dialogRef = this.dialog.open(AddComponentComponent, {
      width: "800px",
      data: item,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      console.log('result', res);
      if(res != null){
        this.saveItem();
      }
    });
  }

  createAssayComponent(item) {
    let dialogRef = this.dialog.open(AddAssayComponent, {
      width: "800px",
      data: item,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      console.log('result', res);
      if(res != null){
        this.saveItem();
      }
    });
  }

 selectComponentType() {
    let dialogRef = this.dialog.open(SelectComponentTypeComponent, {
      width: "300px",
      data: {},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res) => {
      console.log('result', res);
      if(res === 'Sample') {
        this.createComponent({'dataType' : 'SAMPLE' });
      } else if (res === 'Assay') {
        this.createAssayComponent({'dataType' : 'ASSAY' });
      } else {
        
      }
    });
  }

  


  saveItem() {
    //['name', 'createdOn', 'createdBy', 'dataSource', 'ASI', 'asphaltenePercentage', 'isFuelOilOrDiluent', 'origin', 'ASI_Method', 'notes', 'id'];
    this.componentsService.GetComponents().subscribe((componentData: any) => {
      console.log('GetComponents', componentData);
      let localOrgComponents = removedDuplicates(componentData['localCompoToOrgUsers'], componentData['createdComponents']);
      if (componentData && componentData.data) {
        if (this.deletedComponentsArray && this.deletedComponentsArray.length) {
          this.dataSource.data = [...componentData['data'],...componentData['createdComponents'], ...localOrgComponents, ...this.deletedComponentsArray];
          this.dataModel =  [...componentData['data'],...componentData['createdComponents'], ...localOrgComponents, ...this.deletedComponentsArray];
        } else {
          this.dataSource.data =  [...componentData['data'],...componentData['createdComponents'],  ...localOrgComponents];
          this.dataModel =  [...componentData['data'],...componentData['createdComponents'],  ...localOrgComponents];
        }
        const rowsToSelect = this.dataSource.data.filter(item => this.selectedIds.includes(item.id));        
        this.selection.clear();        
        rowsToSelect.forEach(row => this.selection.select(row));
        rowsToSelect.forEach(row => {
          this.dataSource.data = this.dataSource.data.filter(r => r.id !== row.id);
          this.dataSource.data = [row].concat(this.dataSource.data);         
        });
        this.dataSource.paginator.firstPage();
        
        // this.dataSource.sort = this.sort;
        // this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
        this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
          if (typeof data[sortHeaderId] === 'string') {
            return data[sortHeaderId].toLocaleLowerCase();
          }
          return data[sortHeaderId];
        };
      }
      this.searchText ='';
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  toggleRowSelection($event, row) {
    if ($event) {
      this.selection.toggle(row);
    }
    const selectedIds = this.selection.selected.map(row => row.id);
    this.selectedIds = selectedIds;
    if (selectedIds.length !== this.initialSelectedIds.length) {
      this.selectionChanged = true;
    }
    else if (selectedIds.some(id => !this.initialSelectedIds.includes(id))) {
      this.selectionChanged = true;
    }
    else {
      this.selectionChanged = false;
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  searchTable(event) {    
    if (this.searchText.trim().length > 0) {
      this.dataSource.data = this.dataModel.filter((elem) => {
        return elem['componentName'].toUpperCase().includes(this.searchText.toUpperCase());
      });
    } else {
      this.dataSource.data = this.dataModel;
    }


  }

  save() {    
    const selectedIds = this.selection.selected.map(row => row.id);
    this.projectService.addComponentsToProject(this.projectId, selectedIds).subscribe((res) => {
      this.dialogRef.close(true);
    });
  }

  saveConfirm(){
    const selectedIds = this.selection.selected.map(row => row.id);
    this.projectService.getProjectById(this.projectId).subscribe((data: any) => {
        if(data.projectType === 'B' && selectedIds.length > 2) {        
          const message = 'Binary configuration saved will be lost.';
          const dialogRef = this.dialog.open(SharedModalComponent, {
            disableClose: true,
            data: {
              title: '',
              content: message,
              cancel: 'Cancel',
              save: 'Continue',
              showSaveCancel: true
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if(result === 'agree'){              
              let patchArr = [
                { "op": "replace", "path": "/projectType", "value": "N" }            
              ]
              this.projectService.updateBaseComponent(this.projectId, patchArr).subscribe((result: any) => {                
                this.save();         
              });    
              
            } 
            else {
              console.log('Not saving newly selected components')  ;      
            }
          });

        }else{          
          this.save();
        }
    });
  }

}
