

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";

@Component({
  selector: 'lbm-approval-modal',
  templateUrl: './approval-modal.component.html',
  styleUrls: ['./approval-modal.component.scss']
})
export class ApprovalModalComponent {
  components=[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ApprovalModalComponent>,
    private dialog: MatDialog
    
  ) {
     this.components = data.components; 
    }

  agree(){
    this.dialogRef.close('agree');
  }

  cancel () {
    this.dialogRef.close('close');
  }


}
