import { BrowserModule } from "@angular/platform-browser";
import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { ObserversModule } from "@angular/cdk/observers";

import { SharedModule } from "./shared/shared.module";
/* BH Design System */
import {
  ThemeLibModule,
  BhHeaderLinks,
  NavService,
  BhModalService,
} from "bh-theme";
import { MatNativeDateModule } from "@angular/material/core";
import { MatCardModule } from "@angular/material/card";
import { MatTabsModule } from "@angular/material/tabs";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MAT_RIPPLE_GLOBAL_OPTIONS } from "@angular/material/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppConfigModule } from "./app-config/app-config.module";
import { ApiInterceptor } from "./shared/services/api-interceptor";
import { AppConfigService } from "./app-config/app-config.service";
import { AuthConfigModule } from './modules/auth/auth.config.module';

import { ProjectModule } from "./modules/projects/projects.module";
import { ComponentsModule } from "./modules/components/components.module";
import { HeaderProfileMenuComponent} from "./components/header-profile-menu/header-profile-menu.component";
import {AdminModule} from "./modules/admin/admin.module";
import { SpinnerService } from "./shared/services/spinner.service";
import { AuthErrorComponent } from "./modules/auth-error/auth-error.component";
import { SharedModalComponent } from "./components/shared-modal/shared-modal.component";


const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};
@NgModule({
  declarations: [AppComponent,HeaderProfileMenuComponent, AuthErrorComponent,SharedModalComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    ObserversModule,
    ThemeLibModule,
    BrowserAnimationsModule,
    AppConfigModule,
    AuthConfigModule,
    MatCardModule,
    MatTabsModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    ProjectModule,
    ComponentsModule,
    AdminModule
    
  ],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    NavService,
    BhModalService,
    AppConfigService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    SpinnerService
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializerFn,
    //   multi: true,
    //   deps: [AppConfigService],
    // },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
