import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";

@Component({
  selector: "ple-edit-project",
  templateUrl: "./edit-project.component.html",
  styleUrls: ["./edit-project.component.scss"],
})
export class EditProjectComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {},
    public dialogRef: MatDialogRef<EditProjectComponent>
  ) {
    console.log("editModal", data);
    if((data['itemIndex'] + 1) % 6 === 0) {
      data['targetX'] = data['targetX'] - 100;
    }
  }

  ngOnInit(): void {}

  onSelect(option) {
    console.log("option", option);
    this.data["option"] = option;
    this.dialogRef.close(this.data);
  }
}
